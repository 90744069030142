import ProfileForm from "../../../components/ProfileComponents/ProfileForm";
import { useEffect, useState } from "react";
import { UserEdit } from "../../../models/UsersInfo";
import { useSessionData } from "../../../Contexts/SessionDataContext";
import { fetchUserDataEdition } from "../../../api/repositoryEP";
import { LoadingOutlined } from "@ant-design/icons";
import './ProfilePage.scss';

const ProfilePage = (): JSX.Element => {
    const userId = useSessionData().userData.id;
    const [userData, setUserData] = useState<UserEdit | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setLoading(true);
        fetchUserDataEdition(userId).then((data) => {
            setUserData(data?.data);
            setLoading(false);
        });
    }, [userId]);

    return (
        <div className="profile-page">
            {loading ? (
                <div className="loading-container">
                    <LoadingOutlined style={{ fontSize: 48 }} />
                </div>
            ) : (
                <ProfileForm userData={userData} setUserData={setUserData} />
            )}
        </div>
    );
};

export default ProfilePage;
