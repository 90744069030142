import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Switch, Table } from 'antd';
import type { TableProps } from 'antd';
import { HotelsConfigData, HotelsConfigEditData, HotelsConfigEditStatusData } from '../../../../../../models/MappingData';
import { DeleteOutlined, EditOutlined, ImportOutlined, KeyOutlined, StarFilled, StarTwoTone } from '@ant-design/icons';
import { Row, Col, Button, Drawer } from 'antd';
import { editHotelsStatusConfig, fetchHotelsConfigMapping } from '../../../../../../api/repositoryEP';
import { notification } from 'antd';
import './HotelsConfig.scss';
import ModalGeneric from '../Modals/ModalGeneric';
import HotelForm from '../Forms/HotelsForm/HotelsForm';
import Search from 'antd/es/input/Search';
import { GetKeyIcon } from '../../../../../../utils/icons';
import { GetStarIcon } from '../../../../../../utils/icons';


interface HotelsConfigProps {
    language: string;
}

const HotelsConfig = (props: HotelsConfigProps): JSX.Element => {

    const { t } = useTranslation();
    const [data, setData] = useState<HotelsConfigData[]>([]);
    const drawerWidth = window.innerWidth <= 768 ? '100%' : '40%';
    const [newHotelDrawer, setNewHotelDrawer] = useState<boolean>(false);
    const [editHotelDrawer, setEditHotelDrawer] = useState(false);
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);
    const [hotelData, setHotelData] = useState<HotelsConfigEditData | null>(null);
    const [loading, setLoading] = useState<boolean>(true);


    const columns: TableProps<HotelsConfigData>['columns'] = [
        {
            title: t("components.tab-mapping.hotels-config.table.c1"),
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: t("components.tab-mapping.hotels-config.table.c2"),
            dataIndex: 'code',
            key: 'code',
        },
        {
            title: t("components.tab-mapping.hotels-config.table.c3"),
            key: 'category',
            render: (obj) => (
                <div className='icon-hotelTable-container'>
                    {obj.category.number} {obj.category.type === 'star' ? GetStarIcon("app-icon width-18px") : GetKeyIcon("app-icon width-18px")}
                </div>
            ),
        },
        {
            title: t("components.tab-mapping.hotels-config.table.c4"),
            dataIndex: 'destination',
            key: 'destination',
        },
        {
            title: t("components.tab-mapping.hotels-config.table.c5"),
            dataIndex: 'numberRooms',
            key: 'numberRooms',
        },
        {
            title: t("components.tab-mapping.hotels-config.table.c6"),
            key: 'action',
            render: (obj) => (
                <div className='app-icon-table-container'>
                    <EditOutlined className='app-icon-table' onClick={() => editHotel(obj)} />
                    <DeleteOutlined className='app-icon-table' onClick={() => deleteHotel(obj)} />

                    <Switch
                        size='small'
                        checked={obj.active}
                        style={{ marginRight: 5 }}
                        onChange={(checked) => changeStatus(obj, checked)}
                    />
                </div>
            ),
        },
    ];


    useEffect(() => { // para cuando se cierra el drawer de editar o crear o el modal, recargamos los datos

        if (!newHotelDrawer && !editHotelDrawer && !deleteModalVisible) {
            setLoading(true);
            fetchHotelsConfigMapping(props.language, '').then((response) => {
                if (response?.type === 1) {
                    setData(response.data);
                }
                setLoading(false)
            });
        }

    }, [newHotelDrawer, editHotelDrawer, deleteModalVisible]);


    const editHotel = (hotel: HotelsConfigData) => {
        const h: HotelsConfigEditData = {
            id: hotel.id,
            name: hotel.name,
            category: hotel.category,
            code: hotel.code,
            integrationCode: hotel.integrationCode,
            destination: hotel.destination,
            countryCode : hotel.countryCode,
            countryName : hotel.countryName,
            numberRooms: hotel.numberRooms,
            locationCode: hotel.locationCode,
            locationIntegrationCode: hotel.locationIntegrationCode,
            longitude: hotel.longitude,
            latitude: hotel.latitude,
            active: hotel.active,
            regimen: [],
            rooms: [],
            syncReward: false,
            hotelPaxConfiguration: {
                minAgeChild: hotel.hotelPaxConfiguration.minAgeChild,
                maxAgeChild: hotel.hotelPaxConfiguration.maxAgeChild,
                minAgeInfant: hotel.hotelPaxConfiguration.minAgeInfant,
                maxAgeInfant: hotel.hotelPaxConfiguration.maxAgeInfant,
                minAgeTeenager: hotel.hotelPaxConfiguration.minAgeTeenager,
                maxAgeTeenager: hotel.hotelPaxConfiguration.maxAgeTeenager
            }
        }
        console.log("vamos a editar" , h)
        setHotelData(h);
        setEditHotelDrawer(true)
    }

    const deleteHotel = (hotel: HotelsConfigData) => {
        const r: HotelsConfigEditData = {
            id: hotel.id,
            name: hotel.name,
            category: hotel.category,
            code: hotel.code,
            integrationCode: hotel.integrationCode,
            countryCode : hotel.countryCode,
            countryName : hotel.countryName,
            destination: hotel.destination,
            longitude: hotel.longitude,
            latitude: hotel.latitude,
            locationCode: hotel.locationCode,
            locationIntegrationCode: hotel.locationIntegrationCode,
            numberRooms: hotel.numberRooms,
            active: hotel.active,
            regimen: [],
            rooms: [],
            syncReward: false,
            hotelPaxConfiguration: {
                minAgeChild: 0,
                maxAgeChild: 0,
                minAgeInfant: 0,
                maxAgeInfant: 0,
                minAgeTeenager: 0,
                maxAgeTeenager: 0
            }
        }
        setHotelData(r);
        setDeleteModalVisible(true);

    }
    const changeStatus = (hotel: HotelsConfigData, newActiveStatus: boolean) => {
        const h: HotelsConfigEditStatusData = {
            id: hotel.id,
            active: newActiveStatus
        };

        editHotelsStatusConfig(h).then((response) => {
            // Actualizar el estado global para reflejar el cambio en la UI
            setData(prevData => prevData.map(h =>
                h.id === hotel.id ? { ...h, active: newActiveStatus } : h
            ));
        });
    };



    const onSearch = (value: string) => {
        setLoading(true);
        fetchHotelsConfigMapping(props.language, value).then((response) => {
            if (response?.type === 1) {
                setData(response.data);
            } 
            setLoading(false);
        });
    }

    const handleAddButton = () => {
        setHotelData(null)
        setNewHotelDrawer(true)
    }


    return (
        <div className="hotels-configuration-component">
            {deleteModalVisible && hotelData?.id &&
                <ModalGeneric
                    modalVisible={deleteModalVisible}
                    setModalVisible={setDeleteModalVisible}
                    id={hotelData.id}
                    action='deleteHotel'
                    data={hotelData}
                    setData={setHotelData}
                />
            }

            <Drawer
                open={editHotelDrawer}
                title={t("components.tab-mapping.hotels-config.drawer.title-edit")}
                onClose={() => {
                    setEditHotelDrawer(false);
                    setHotelData(null)
                }}
                width={drawerWidth}
                closable>
                <HotelForm
                    setDrawerVisible={setEditHotelDrawer}
                    drawerVisible={editHotelDrawer}
                    hotelData={hotelData}
                    setHotelData={setHotelData}
                    language={props.language}
                />
            </Drawer>

            <Drawer
                open={newHotelDrawer}
                title={t("components.tab-mapping.hotels-config.drawer.title-new")}
                onClose={() => {
                    setNewHotelDrawer(false);
                    setHotelData(null)
                }}
                width={drawerWidth}
                closable>
                <HotelForm
                    setDrawerVisible={setNewHotelDrawer}
                    drawerVisible={newHotelDrawer}
                    hotelData={null}
                    setHotelData={setHotelData}
                    language={props.language}
                />

            </Drawer>

            <Row gutter={[10, 10]} style={{ flexDirection: 'column' }}>
                <Col xs={24} md={24}>
                    <Row gutter={[16, 16]}>
                        <Col xs={24} md={24} style={{ fontFamily: 'Montserrat' }}>
                            {t("components.tab-mapping.hotels-config.msg-info")}
                        </Col>
                        <Col xs={24} md={24} lg={16}>
                            <Search
                                placeholder={t("components.tab-mapping.hotels-config.search") || ''}
                                allowClear
                                onSearch={onSearch}
                            />
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={4} className='button-container'>
                            <Button
                                className='app-button'
                                onClick={() => handleAddButton()}>
                                {t("components.tab-mapping.hotels-config.button-add")}
                            </Button>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={4} className='button-container'>
                            <Button disabled className='app-button'><ImportOutlined /> {t("components.tab-mapping.room-config.button-import")}</Button>
                        </Col>
                    </Row>
                </Col>
                <Col xs={24} md={24}>
                    <Table
                        className='table'
                        columns={columns}
                        dataSource={data}
                        loading={loading}
                        scroll={{ x: 'max-content' }} />
                </Col>

            </Row>
        </div>
    );
};



export default HotelsConfig;