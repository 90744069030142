import EndPointConfig from '../../models/api/EndPointConfig'

const controller = 'Configuration'

export const ConfigurationEP : EndPointConfig[] =[
    new EndPointConfig()
        .setMethod("GET")
        .setController(controller)
        .setAlias("GetPROPersonalitationData")
        .setActionName("Customization/GetPROPersonalitationData"),

        new EndPointConfig()
        .setMethod("POST")
        .setController(controller)
        .setAlias("EditPROPersonalitationData")
        .setActionName("Customization/EditPROPersonalitationData"),

        new EndPointConfig()
        .setMethod("GET")
        .setController(controller)
        .setAlias("GetLandingPageCode")
        .setActionName("LandingPage/GetLandingPageCode"),

        new EndPointConfig()
        .setMethod("POST")
        .setController(controller)
        .setAlias("EditLandingPageCode")
        .setActionName("LandingPage/EditLandingPageCode"),

        new EndPointConfig()
        .setMethod("GET")
        .setController(controller)
        .setAlias("FilterFieldsRegisterForm")
        .setActionName("GroupForm/FilterFieldsRegisterForm"),

        new EndPointConfig()
        .setMethod("POST")
        .setController(controller)
        .setAlias("ChangeFieldFormStatus")
        .setActionName("RegisterForm/ChangeFieldFormStatus"),

        new EndPointConfig()
        .setMethod("POST")
        .setController(controller)
        .setAlias("CreateFieldRegisterForm")
        .setActionName("RegisterForm/CreateFieldRegisterForm"),

        new EndPointConfig()
        .setMethod("POST")
        .setController(controller)
        .setAlias("EditFieldRegisterForm")
        .setActionName("RegisterForm/EditFieldRegisterForm"),

        new EndPointConfig()
        .setMethod("DELETE")
        .setController(controller)
        .setAlias("DeleteField")
        .setActionName("RegisterForm/DeleteField"),

        new EndPointConfig()
        .setMethod("GET")
        .setController(controller)
        .setAlias("GetFormGroupData")
        .setActionName("GroupForm/GetFormGroupData"),

        new EndPointConfig()
        .setMethod("GET")
        .setController(controller)
        .setAlias("GetServicesChosenFormGroup")
        .setActionName("GroupForm/GetServicesChosenFormGroup"),

        new EndPointConfig()
        .setMethod("GET")
        .setController(controller)
        .setAlias("GetServicesFormGroup")
        .setActionName("GroupForm/GetServicesFormGroup"),

        new EndPointConfig()
        .setMethod("POST")
        .setController(controller)
        .setAlias("EditGroupForm")
        .setActionName("GroupForm/EditGroupForm"),

        new EndPointConfig()
        .setMethod("POST")
        .setController(controller)
        .setAlias("CreateBudgetGroupForm")
        .setActionName("GroupForm/CreateBudgetGroupForm"),

        new EndPointConfig()
        .setMethod("DELETE")
        .setController(controller)
        .setAlias("DeleteBudgetGroupForm")
        .setActionName("GroupForm/DeleteBudgetGroupForm"),

        new EndPointConfig()
        .setMethod("GET")
        .setController(controller)
        .setAlias("GetBudgetsGroupForm")
        .setActionName("GroupForm/GetBudgetsGroupForm"),   

        new EndPointConfig()
        .setMethod("GET")
        .setController(controller)
        .setAlias("GetBudgetsGroupFormById")
        .setActionName("GroupForm/GetBudgetsGroupFormById"),   

        new EndPointConfig()
        .setMethod("POST")
        .setController(controller)
        .setAlias("EditBudgetGroupForm")
        .setActionName("GroupForm/EditBudgetGroupForm"),  
]