
import { Button, Col, Form, Input, Row, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Drawer } from 'antd';
import {RegisterFormFilterData} from '../../../../../models/RegisterFormData';
import FieldForm from '../fieldForm/FieldForm';
import './FilterRegisterForm.scss'
import { LoadingOutlined } from '@ant-design/icons';
import { PlusOutlined } from '@ant-design/icons';


interface FilterRegisterFormProps {
    onSearch: (filterData: RegisterFormFilterData) => void;
    searching: boolean;
    languages: string[];

}

const FilterRegisterForm = (props: FilterRegisterFormProps): JSX.Element => {

    const { t } = useTranslation();

    const [drawerNewFieldVisible, setdrawerNewFieldVisible] = useState<boolean>(false);
    const screenWidth = window.innerWidth;
    const drawerWidth = screenWidth <= 768 ? '100%' : '30%';

    const onFinish = (values: any) => {
        const filterData: RegisterFormFilterData = ({
            status: values.isActive,
            fieldType: values.fieldType,
            label: values.label,
            nameField: values.nameField,
            language: values.language,
            page: 0, 
            pageSize: 10
        });
        props.onSearch(filterData); 
    }

    useEffect(() => {
        if (!drawerNewFieldVisible) {
            const filterData: RegisterFormFilterData = ({
                status: "",
                fieldType: "",
                label: "",
                nameField: "",
                language: "",
                page: 0, 
                pageSize: 10
            });
            props.onSearch(filterData);
    
        }
    }, [drawerNewFieldVisible]);


    const openDrawer = () => {
        setdrawerNewFieldVisible(true);
    }



    return (
        <div className='register-form-filter'>
            <Drawer open={drawerNewFieldVisible} title={<span>{t("components.tab-configuration.register-form.drawer-new.title")}</span>} onClose={() => { setdrawerNewFieldVisible(false) }} width={drawerWidth} closable>
                <FieldForm 
                    drawerVisible={drawerNewFieldVisible}
                    setDrawerVisible={setdrawerNewFieldVisible} 
                    languages={props.languages}
                    fieldData= {null}
                    />
            </Drawer>
            <Form onFinish={onFinish} layout='vertical'>
                <Row gutter={16}>
                    <Col xs={24} md={8}>
                        <Form.Item
                            name="fieldType"
                            label={t("components.tab-configuration.register-form.filter.field-type")}
                            initialValue={""}
                        >
                            <Select className="app-input" defaultValue={""}>
                                <Select.Option value="">{t("components.tab-configuration.register-form.filter.field-anything")}</Select.Option>
                                <Select.Option value="input">{t("components.tab-configuration.register-form.filter.field-input")}</Select.Option>
                                <Select.Option value="text_area">{t("components.tab-configuration.register-form.filter.field-textarea")}</Select.Option>
                                <Select.Option value="select">{t("components.tab-configuration.register-form.filter.field-select")}</Select.Option>
                                <Select.Option value="checkbox">{t("components.tab-configuration.register-form.filter.field-checkbox")}</Select.Option>
                                <Select.Option value="radio">{t("components.tab-configuration.register-form.filter.field-radio")}</Select.Option>
                            </Select>

                        </Form.Item>
                    </Col>
                    <Col xs={24} md={8}>
                        <Form.Item
                            name="nameField"
                            label={t("components.tab-configuration.register-form.filter.name-field")}
                            initialValue={""}
                        >
                            <Input className="app-input" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={8}>
                        <Form.Item
                            name="label"
                            label={t("components.tab-configuration.register-form.filter.label")}
                            initialValue={""}
                        >
                            <Input className="app-input" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={8}>
                        <Form.Item
                            name="language"
                            label={t("components.tab-configuration.register-form.filter.language")}
                            initialValue={""}
                        >

                            <Select className="app-input" defaultValue={"any"}>
                                <Select.Option value="">{t("components.tab-configuration.register-form.filter.field-anything")}</Select.Option>
                                {props.languages.map(language => (
                                    <Select.Option key={language} value={language}>
                                        {t(`languages.${language}`)}
                                    </Select.Option>
                                ))}
                            </Select>

                        </Form.Item>
                    </Col>

                    <Col xs={24} md={8}>
                        <Form.Item
                            name="isActive"
                            label={t("components.tab-configuration.register-form.filter.state")}
                            initialValue={""}
                        >
                            <Select className="app-input" defaultValue={""}>
                                <Select.Option value="">{t("components.tab-configuration.register-form.filter.field-anything")}</Select.Option>
                                <Select.Option value="active">{t("components.tab-configuration.register-form.filter.state-active")}</Select.Option>
                                <Select.Option value="inactive">{t("components.tab-configuration.register-form.filter.state-inactive")}</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>


                    <Col xs={24} md={8}>
                        <Button className="app-button" style={{marginTop: "2.2em", maxWidth: "100%"}} htmlType='submit' disabled= {props.searching}>
                           {props.searching ? <LoadingOutlined /> : ''}
                            {t("components.tab-configuration.register-form.filter.button-search")}
                        </Button>
                    </Col>
                    <Col xs={24} md={16}>
                    </Col>
                    <Col xs={24} md={8}>
                        <Button className="app-button" style={{marginBottom: 20}} onClick={openDrawer} icon={<PlusOutlined />}>
                            {t("components.tab-configuration.register-form.filter.button-add")}
                        </Button>
                    </Col>


                </Row>
            </Form>
        </div>
    );

}

export default FilterRegisterForm;