import { Card, Col, Row, Switch, Spin, Alert } from 'antd';
import { AccountUserData } from '../../../models/AccountUserData';
import { useTranslation } from 'react-i18next';
import { LoadingOutlined } from '@ant-design/icons';  // Importa LoadingOutlined
import './AccountUsersListComponent.scss';
import { GetUserIcon } from '../../../utils/icons';
import moment from 'moment';
import { useAppConfig } from '../../../Contexts/AppConfigContext';
import { LoadingData } from '../../common';

interface AccountUsersListComponentProps {
    list: AccountUserData[];
    onChangeUserStatus: (userId: string, status: boolean) => void;
    loading: boolean;
}

const AccountUsersListComponent = (props: AccountUsersListComponentProps): JSX.Element => {

    const { list, onChangeUserStatus, loading } = props;
    const appConfig = useAppConfig();
    const { t } = useTranslation();

    const changeUserStatus = (userId: string, status: boolean) => {
        onChangeUserStatus(userId, status);
    };

    // Icono de carga
    const loadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

    // Condición para mostrar el icono de carga o el contenido
    if (loading) {
        return (
            <div className="loading-container" style={{ textAlign: 'center', padding: '20px' }}>
                <LoadingData />
            </div>
        );
    }

    return (
        <div className="account-users-list-component">
            {
                list.map((item: AccountUserData, index: number) => {
                    const cardHeader: JSX.Element = (
                        <div key={index} className="header-container">
                            <div className="resume-header">
                                &nbsp;
                            </div>
                            <div className="actions-container">
                                <Switch
                                    className="app-switch"
                                    defaultChecked={item.status === 'active'}
                                    onChange={(isEnabled: any) => changeUserStatus(item.id, isEnabled)}
                                />
                            </div>
                        </div>
                    );

                    return (
                        <Card className="app-card" title={cardHeader} bordered={true} key={item.id}>
                            <Row>
                                <Col className="info" xs={24}>
                                    <span>
                                        {GetUserIcon('app-icon width-18px')} <span>{item.name} {item.surname} {item.secondSurname}</span>
                                        <small>
                                            {t('components.account-users.list.creation-date', [moment(item.createdAt).format(appConfig.config.dateFormat)])}
                                        </small>
                                    </span>
                                    <span className="with-padding"> {item.email}</span>
                                    <span className="with-padding">{item.phone}</span>
                                </Col>
                            </Row>
                        </Card>
                    );
                })
            }

            {
                list.length === 0 && (
                    <Alert className="alert" message={t('components.account-users.list.empty')} type="info" />
                )
            }
        </div>
    );
};

export default AccountUsersListComponent;
