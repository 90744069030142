import { ErrorInfo } from '../models/ErrorInfo';
import ApiResponse from '../models/api/ApiResponse';
import ParamsApi from '../models/api/ParamsApi';
import RestApiClient from './restApiClient';

//Mocks
import { configClientApplication } from '../mocks/applicationConfig';
import { templateInfo } from '../mocks/templates';
import { AccountFilter, AccountUsersAdministrationFilter } from '../models/AccountsFilter';
import { BookingFilter } from '../models/BookingsFilter';
import { UsersFilterData, UsersFilterData2 } from '../models/UsersFilterData';
import { CreateFieldRegisterFormDto, EditFieldRegisterFormDto } from '../models/RegisterFormData';
import { CreateBudgetDto, EditBudgetDto, EditGroupFormDto, EditPROPersonalitationDto, LandingPageCodeData } from '../models/ConfigurationData';
import { userDataList } from '../mocks/users';
import NewUserData from '../models/NewUserData';
import EditUserData from '../models/EditUserData';
import { clientLanguagesMock } from '../mocks/configuration';
import { RegisterFormFilterData } from '../models/RegisterFormData';
import RoomConfigData, { HotelsConfigCreateData, HotelsConfigEditData, HotelsConfigEditStatusData, RegimesConfigCreateData, RegimesConfigData, RoomConfigCreateData, ServicesConfigCreateData, ServicesConfigData } from '../models/MappingData';
import { UserProfile } from '../models/UsersInfo';
import { EditAccountStatusData } from '../models/UserData';
import { AccountChangeStatusDto } from '../models/AccountUserData';

const sleep = (ms: number): Promise<void> => new Promise(resolve => setTimeout(resolve, ms));

var restApiClient: RestApiClient = new RestApiClient();;

// Si esta variable está a TRUE cuando se produce un error en una llamada API se redirige al usuario a la página de error
const REDIRECT_TO_ERROR_PAGE: boolean = true;


export async function pushErrorNotificationData(errorInfo: ErrorInfo): Promise<ApiResponse | null> {
    console.warn("Calling to: pushErrorNotificationData", errorInfo);

    try {
        return {
            additionalMessages: [],
            code: 200,
            data: true,
            message: "",
            type: 200,
            typeText: "Success"
        };

        const response = await restApiClient.fetch("PushErrorNotification", {
            body: errorInfo
        }, REDIRECT_TO_ERROR_PAGE);
        return response!;

    } catch (error) {
        console.error('Error push notification data:', error);
        return null;
    }
}

export async function fetchClientAppCustomData(): Promise<ApiResponse | null> {
    try {
        console.warn("Calling to: fetchClientAppCustomData");

        return {
            additionalMessages: [],
            code : 200,
            data: configClientApplication,
            message: "",
            type: 200,
            typeText: "Success"
        };

        const response = await restApiClient.fetch("GetCustomAppData", {}, REDIRECT_TO_ERROR_PAGE);
        return response!;

    } catch (error) {
        console.error('Error fetching colors:', error);
        return null;
    }
}


export async function fetchClientPROPersonalitationData(): Promise<ApiResponse | null> {
    try {
        console.warn("Calling to: fetchClientPROPersonalitationData");

        const response = await restApiClient.fetch("GetPROPersonalitationData", {}, false);
        return response!;

    } catch (error) {
        console.error('Error fetching client PRO Personalitation Data:', error);
        return null;
    }
}


export async function editPROConfiguration(dto: EditPROPersonalitationDto): Promise<ApiResponse | null> {
    try {
        console.warn("Calling to: editPROConfiguration");

        var params: ParamsApi = {
            body: dto  // Enviar el objeto DTO directamente
        };

        const response = await restApiClient.fetch("EditPROPersonalitationData", params);
        return response!;

    } catch (error) {
        console.error('Error editing PRO Configuration:', error);
        return null;
    }
}





export async function createFieldRegisterForm(createFieldRegisterFormDto: CreateFieldRegisterFormDto): Promise<ApiResponse | null> {
    console.log(createFieldRegisterFormDto)
    console.warn("Calling to: createFieldRegisterForm", createFieldRegisterFormDto);

    try {


        const response = await restApiClient.fetch("CreateFieldRegisterForm", {
            body: createFieldRegisterFormDto
        }, REDIRECT_TO_ERROR_PAGE);
        return response!;

    } catch (error) {
        console.error('Error post field register form :', error);
        return null;
    }
}


export async function editFieldRegisterForm(editFieldRegisterFormDto: EditFieldRegisterFormDto): Promise<ApiResponse | null> {
    console.warn("Calling to: editFieldRegisterForm", editFieldRegisterFormDto);

    try {
        console.log("El objeto que tenmoes es ", editFieldRegisterFormDto)
        const response = await restApiClient.fetch("EditFieldRegisterForm", {
            body: editFieldRegisterFormDto
        }, REDIRECT_TO_ERROR_PAGE);

        return response!;

    } catch (error) {
        console.error('Error edit field register form :', error);
        return null;
    }
}




export async function fetchFilterFieldsRegisterForm(filterData: RegisterFormFilterData): Promise<ApiResponse | null> {
    try {
        console.log("Los datos", filterData)
        console.warn("Calling to: fetchFieldsRegisterForm");
        var params: ParamsApi = {
            query: {
                status: filterData.status,
                fieldType: filterData.fieldType,
                label: filterData.label,
                language: filterData.language,
                nameField: filterData.nameField,
                page: filterData.page,
                pageSize: filterData.pageSize
            }
        };


        const response = await restApiClient.fetch("FilterFieldsRegisterForm", params, REDIRECT_TO_ERROR_PAGE);
        return response!;

    } catch (error) {
        console.error('Error fetching filter fields register form:', error);
        return null;
    }
}




export async function editStatusFieldForm(id: string, isActive: boolean): Promise<ApiResponse | null> {
    try {
        console.warn("Calling to: editStateFieldForm");

        var params: ParamsApi = {
            body: {
                id: id,
                isActive: isActive
            }
        }

        const response = await restApiClient.fetch("ChangeFieldFormStatus", params, REDIRECT_TO_ERROR_PAGE);
        return response!;

    } catch (error) {
        console.error('Error editing field form status:', error);
        return null;
    }
}



export async function deleteFieldRegisterForm(id: string): Promise<ApiResponse | null> {
    try {
        console.warn("Calling to: deleteFieldRegisterForm");

        var params: ParamsApi = {
            query: {
                id: id
            }
        }

        const response = await restApiClient.fetch("DeleteField", params, REDIRECT_TO_ERROR_PAGE);
        return response!;

    } catch (error) {
        console.error('Error deleting field form:', error);
        return null;
    }
}




export async function editLandingPageCode(editLandingPageDto: LandingPageCodeData): Promise<ApiResponse | null> {
    console.warn("Calling to: editLandingPageCode", editLandingPageDto);

    try {


        const response = await restApiClient.fetch("EditLandingPageCode", {
            body: editLandingPageDto
        }, REDIRECT_TO_ERROR_PAGE);
        return response!;

    } catch (error) {
        console.error('Error editing langing page code:', error);
        return null;
    }
}



export async function fetchLandingPageCode(language: string): Promise<ApiResponse | null> {
    try {
        console.warn("Calling to: fetchLandingPageCode");


        var params: ParamsApi = {
            query: {
                languageId: language
            }
        }

        const response = await restApiClient.fetch("GetLandingPageCode", params, REDIRECT_TO_ERROR_PAGE);
        return response!;

    } catch (error) {
        console.error('Error fetching landing page code:', error);
        return null;
    }
}


export async function fetchGroupForm(language: string): Promise<ApiResponse | null> {
    try {
        console.warn("Calling to: fetchGroupForm");


        var params: ParamsApi = {
            query: {
                languageId: language
            }
        }

        const response = await restApiClient.fetch("GetFormGroupData", params, REDIRECT_TO_ERROR_PAGE);
        return response!;

    } catch (error) {
        console.error('Error fetching group form data :', error);
        return null;
    }
}




export async function editGroupForm(data: EditGroupFormDto): Promise<ApiResponse | null> {
    console.warn("Calling to: editGroupForm", data);
    console.log(data)
    try {
        const response = await restApiClient.fetch("EditGroupForm", {
            body: data
        });
        return response!;

    } catch (error) {
        console.error('Error editing group form :', error);
        return null;
    }
}



export async function fetchServicesChosen(language: string): Promise<ApiResponse | null> {
    try {
        console.warn("Calling to: fetchServicesChosen");

        var params: ParamsApi = {
            query: {
                languageId: language
            }
        }


        const response = await restApiClient.fetch("GetServicesChosenFormGroup", params, REDIRECT_TO_ERROR_PAGE);
        return response!;

    } catch (error) {
        console.error('Error fetching services chosen:', error);
        return null;
    }
}


export async function fetchOptionsServices(language: string): Promise<ApiResponse | null> {
    try {
        console.warn("Calling to: fetchOptionsServices");



        var params: ParamsApi = {
            query: {
                languageId: language
            }
        }

        const response = await restApiClient.fetch("GetServicesFormGroup", params, REDIRECT_TO_ERROR_PAGE);
        return response!;

    } catch (error) {
        console.error('Error fetching options services:', error);
        return null;
    }
}




export async function fetchLanguagesAvailables(origin: string): Promise<ApiResponse | null> {
    try {
        console.warn("Calling to: fetchLanguagesAvailables");

        const params: ParamsApi = {
            query: {
                origin: origin
            }
        }

        const response = await restApiClient.fetch("GetAvailableLanguages", params);
        return response!;

    } catch (error) {
        console.error('Error fetching languages:', error);
        return null;
    }
}


// ------------ REGIMEN--------------------



export async function editRegimesConfig(data: RegimesConfigData): Promise<ApiResponse | null> {
    console.warn("Calling to: editRoomConfig", data);

    try {
        const response = await restApiClient.fetch("EditRegimeConfigMapping", {
            body: data
        });
        return response!;

    } catch (error) {
        console.error('Error editing regimes config :', error);
        return null;
    }
}

export async function createRegimesConfig(data: RegimesConfigCreateData): Promise<ApiResponse | null> {
    console.warn("Calling to: createRegimesConfig", data);

    try {

        const response = await restApiClient.fetch("CreateRegimeConfigMapping", {
            body: data
        }, REDIRECT_TO_ERROR_PAGE);
        return response!;

    } catch (error) {
        console.error('Error creating regimes config :', error);
        return null;
    }
}


export async function deleteRegimesConfigMapping(id: string): Promise<ApiResponse | null> {
    try {
        console.warn("Calling to: deleteRegimesConfigMapping");



        var params: ParamsApi = {
            query: {
                id: id
            }
        }

        const response = await restApiClient.fetch("DeleteRegimenConfigMapping", params,);
        return response!;

    } catch (error) {
        console.error('Error editing field form:', error);
        return null;
    }
}



export async function fetchRegimesConfigMapping(name: string, language: string): Promise<ApiResponse | null> {
    try {
        console.warn("Calling to: fetchRegimesConfigMapping");

        var params: ParamsApi = {
            query: {
                filter: name,
                language: language
            }
        }

        const response = await restApiClient.fetch("GetRegimenConfigMapping", params, REDIRECT_TO_ERROR_PAGE);
        return response!;

    } catch (error) {
        console.error('Error fetching regimes config mapping:', error);
        return null;
    }
}

// ----------------ROOM----------------------

export async function fetchRoomConfigMapping(name: string, language: string): Promise<ApiResponse | null> {
    try {
        console.warn("Calling to: fetchRoomConfigMapping");


        var params: ParamsApi = {
            query: {
                filter: name,
                language: language
            }
        }


        const response = await restApiClient.fetch("GetRoomConfigMapping", params);
        return response!;

    } catch (error) {
        console.error('Error fetching room config mapping:', error);
        return null;
    }
}






export async function editRoomConfig(data: RoomConfigData): Promise<ApiResponse | null> {
    console.warn("Calling to: editRoomConfig", data);

    try {


        const response = await restApiClient.fetch("EditRoomConfigMapping", {
            body: data
        });
        return response!;

    } catch (error) {
        console.error('Error editing room config :', error);
        return null;
    }
}

export async function createRoomConfig(data: RoomConfigCreateData): Promise<ApiResponse | null> {
    console.warn("Calling to: createRoomConfig", data);

    try {

        const response = await restApiClient.fetch("CreateRoomConfigMapping", {
            body: data
        }, REDIRECT_TO_ERROR_PAGE);
        return response!;

    } catch (error) {
        console.error('Error creating room config :', error);
        return null;
    }
}


export async function deleteRoomConfigMapping(id: string): Promise<ApiResponse | null> {
    try {
        console.warn("Calling to: deleteRoomConfigMapping");

        var params: ParamsApi = {
            query: {
                id: id
            }
        }

        const response = await restApiClient.fetch("DeleteRoomConfigMapping", params, REDIRECT_TO_ERROR_PAGE);
        return response!;

    } catch (error) {
        console.error('Error deleting field form:', error);
        return null;
    }
}





// ------ HOTELS--------



export async function editHotelsConfig(data: HotelsConfigEditData): Promise<ApiResponse | null> {
    console.warn("Calling to: editHotelsConfig", data);

    try {
        const response = await restApiClient.fetch("EditHotelConfigMapping", {
            body: data
        });
        return response!;

    } catch (error) {
        console.error('Error editing hotels config :', error);
        return null;
    }
}

export async function createHotelsConfig(data: HotelsConfigCreateData): Promise<ApiResponse | null> {
    console.warn("Calling to: createHotelsConfig", data);

    try {

        const response = await restApiClient.fetch("CreateHotelConfigMapping", {
            body: data
        }, REDIRECT_TO_ERROR_PAGE);
        return response!;

    } catch (error) {
        console.error('Error creating regimes config :', error);
        return null;
    }
}


export async function deleteHotelsConfigMapping(id: string): Promise<ApiResponse | null> {
    try {
        console.warn("Calling to: deleteHotelsConfigMapping");



        var params: ParamsApi = {
            query: {
                id: id
            }
        }

        const response = await restApiClient.fetch("DeleteHotelConfigMapping", params);
        return response!;

    } catch (error) {
        console.error('Error deleting hotel config form:', error);
        return null;
    }
}



export async function fetchHotelsConfigMapping(language: string, name: string): Promise<ApiResponse | null> {
    try {
        console.warn("Calling to: fetchHotelsConfigMapping");

        var params: ParamsApi = {
            query: {
                filter: name,
                language: language
            }
        }

        const response = await restApiClient.fetch("GetHotelConfigMapping", params);
        return response!;

    } catch (error) {
        console.error('Error fetching hotels config mapping:', error);
        return null;
    }
}


export async function editHotelsStatusConfig(data: HotelsConfigEditStatusData): Promise<ApiResponse | null> {
    console.warn("Calling to: editHotelsStatusConfig", data);

    try {


        const response = await restApiClient.fetch("UpdateStateHotelConfigMapping", {
            body: data
        });
        return response!;

    } catch (error) {
        console.error('Error editing hotels state config :', error);
        return null;
    }
}



// si le paso que idHotel es nulo, es porque es nuevo
export async function fetchHotelsRooms(language: string, idHotel: string): Promise<ApiResponse | null> {
    try {
        console.warn("Calling to: fetchHotelsRooms");


        var params: ParamsApi = {
            query: {
                idHotel: idHotel,
                language: language,
            }
        }

        const response = await restApiClient.fetch("GetHotelRoomsConfigMapping", params);
        return response!;

    } catch (error) {
        console.error('Error fetching hotels rooms config mapping:', error);
        return null;
    }
}



// si le paso que idHotel es nulo, es porque es nuevo
export async function fetchHotelsRegimens(language: string, idHotel: string): Promise<ApiResponse | null> {
    try {
        console.warn("Calling to: fetchHotelsRegimens");
        console.log("El id del hotel es ", idHotel)

        var params: ParamsApi = {
            query: {
                idHotel: idHotel,
                language: language,
            }
        }

        const response = await restApiClient.fetch("GetHotelRegimesConfigMapping", params);
        return response!;

    } catch (error) {
        console.error('Error fetching hotels regimes config mapping:', error);
        return null;
    }
}


// -----------------------------------------


export async function fetchAvailableClientLanguages(): Promise<ApiResponse | null> {
    try {
        console.warn("Calling to: fetchAvailableClientLanguages");

        return {
            additionalMessages: [],
            code : 200,
            data: clientLanguagesMock,
            message: "",
            type: 200,
            typeText: "Success"
        };

        const response = await restApiClient.fetch("GetClientLanguages", {}, REDIRECT_TO_ERROR_PAGE);
        return response!;

    } catch (error) {
        console.error('Error fetching available client languages:', error);
        return null;
    }
}

// -.----------------------SERVICE-----------------
// si name tiene un valor, entonces es para filtar
export async function fetchServicesConfigMapping(name: string, language: string): Promise<ApiResponse | null> {
    try {
        console.warn("Calling to: fetchServicesConfigMapping");

        var params: ParamsApi = {
            query: {
                filter: name,
                language: language,
            }
        }

        const response = await restApiClient.fetch("GetServiceConfigMapping", params, REDIRECT_TO_ERROR_PAGE);
        return response!;

    } catch (error) {
        console.error('Error fetching services config mapping:', error);
        return null;
    }
}



export async function deleteServiceConfigMapping(id: string): Promise<ApiResponse | null> {
    try {
        console.warn("Calling to: deleteServiceConfigMapping");

        var params: ParamsApi = {
            query: {
                id: id
            }
        }

        const response = await restApiClient.fetch("DeleteServiceConfigMapping", params);
        return response!;

    } catch (error) {
        console.error('Error deleting service config :', error);
        return null;
    }
}



export async function createServicesConfig(data: ServicesConfigCreateData): Promise<ApiResponse | null> {
    console.warn("Calling to: createServicesConfig", data);

    try {

        const response = await restApiClient.fetch("CreateServiceConfigMapping", {
            body: data
        });
        return response!;

    } catch (error) {
        console.error('Error creating services config :', error);
        return null;
    }
}



export async function editServicesConfig(data: ServicesConfigData): Promise<ApiResponse | null> {
    console.warn("Calling to: editServicesConfig", data);

    try {

        const response = await restApiClient.fetch("EditServiceConfigMapping", {
            body: data
        });
        return response!;

    } catch (error) {
        console.error('Error editing services config :', error);
        return null;
    }
}



export async function editBudgetGroupForm(data: EditBudgetDto): Promise<ApiResponse | null> {
    console.warn("Calling to: editBudgetGroupForm", data);

    try {

        const response = await restApiClient.fetch("EditBudgetGroupForm", {
            body: data
        });
        return response!;



    } catch (error) {
        console.error('Error editing budget :', error);
        return null;
    }
}

export async function createBudgetGroupForm(data: CreateBudgetDto): Promise<ApiResponse | null> {
    console.warn("Calling to: createBudgetGroupForm", data);

    try {
        const response = await restApiClient.fetch("CreateBudgetGroupForm", {
            body: data
        });
        return response!;

    } catch (error) {
        console.error('Error creating budget :', error);
        return null;
    }
}


export async function deleteBudgetGroupForm(budgetId: string): Promise<ApiResponse | null> {
    try {
        console.warn("Calling to: deleteBudgetGroupForm");



        var params: ParamsApi = {
            query: {
                budgetId: budgetId
            }
        }

        const response = await restApiClient.fetch("DeleteBudgetGroupForm", params);
        return response!;

    } catch (error) {
        console.error('Error deleting budget:', error);
        return null;
    }
}






// si le paso que idHotel es nulo, es porque es nuevo
export async function fetchBudgetsGroupForm(languageId: string): Promise<ApiResponse | null> {
    try {
        console.warn("Calling to: fetchBudgetsGroupForm");


        var params: ParamsApi = {
            query: {
                languageId: languageId
            }
        }


        const response = await restApiClient.fetch("GetBudgetsGroupForm", params);
        return response!;

    } catch (error) {
        console.error('Error fetching bugdets group form:', error);
        return null;
    }
}



// si le paso que idHotel es nulo, es porque es nuevo
export async function fetchBudgetsGroupFormById(id: string): Promise<ApiResponse | null> {
    try {
        console.warn("Calling to: fetchBudgetsGroupForm");


        var params: ParamsApi = {
            query: {
                id: id
            }
        }


        const response = await restApiClient.fetch("GetBudgetsGroupFormById", params);
        return response!;

    } catch (error) {
        console.error('Error fetching bugdets group form:', error);
        return null;
    }
}



export async function useDoLogin(username: string, password: string): Promise<ApiResponse | null> {
    try {
        console.warn("Calling to: useDoLogin", username, password);

        var params: ParamsApi = {
            body: {
                userData: username,
                password: password
            }
        }

        const response = await restApiClient.fetch("LoginFromBackOffice", params);
        return response!;

    } catch (error) {
        console.error('Error doing the login :', error);
        return null;
    }
}

export async function useCloseSession(idUser: string): Promise<ApiResponse | null> {
    try {
        console.warn("Calling to: useCloseSession", idUser);

        var params: ParamsApi = {
            query: {
                userId: idUser
            }
        }

        const response = await restApiClient.fetch("Logout", params);
        return response!;

    } catch (error) {
        console.error('Error doing log out :', error);
        return null;
    }
}

export async function useRecoverPassword(email: string): Promise<ApiResponse | null> {
    try {
        console.warn("Calling to: useRecoverPassword", email);

        return {
            additionalMessages: [],
            code : 200,
            data: true,
            message: "",
            type: 200,
            typeText: "Success"
        };

        var params: ParamsApi = {
            body: {
                email: email
            }
        }

        const response = await restApiClient.fetch("DoRecoverPassword", params, REDIRECT_TO_ERROR_PAGE);
        return response!;

    } catch (error) {
        console.error('Error fetching colors:', error);
        return null;
    }
}

export async function fetchTemplateInfo(): Promise<ApiResponse | null> {
    console.warn("Calling to: getTemplateInfo");
    try {
        return {
            additionalMessages: [],
            code : 200,
            data: templateInfo,
            message: "",
            type: 200,
            typeText: "Success"
        };

        const response = await restApiClient.fetch("getClientTemplate", {}, REDIRECT_TO_ERROR_PAGE);

        return response;

    } catch (error) {
        console.error('Error push notification data:', error);
        return null;
    }
}


export async function isValidUsername(username: string): Promise<ApiResponse | null> {
    console.warn("Calling to: checkUsername");
    try {
        var params: ParamsApi = {
            query: {
                username: username
            }
        }

        const response = await restApiClient.fetch("CheckIsValidNewUsername", params);

        return response;

    } catch (error) {
        console.error('Error push notification data:', error);
        return null;
    }
}


export async function fetchAccounts(filterData: AccountFilter): Promise<ApiResponse | null> {
    console.warn("Calling to: fetchAccounts", filterData);

    try {
        var params: ParamsApi = {
            query: {
                name: filterData.name,
                email: filterData.email,
                status: filterData.status,
                location: filterData.location,
                accountType: filterData.accountType,
                page: filterData.page,
                pageSize: filterData.pageSize
            }
        }


        const response = await restApiClient.fetch("GetAccounts", params);
        return response;

    } catch (error) {
        console.error('Error push notification data:', error);
        return null;
    }
}



export async function fetchUserAccounts(filterData: AccountUsersAdministrationFilter): Promise<ApiResponse | null> {
    console.warn("Calling to: fetchUserAccounts", filterData);
    try {
        var params: ParamsApi = {
            query: {
                name: filterData.name,
                email: filterData.email,
                status: filterData.status,
                location: filterData.location,
                accountType: filterData.accountType,
                id : filterData.id
            }
        }
        const response = await restApiClient.fetch("GetUserAccounts", params);
        return response;

    } catch (error) {
        console.error('Error push notification data:', error);
        return null;
    }
}



export async function fetchBookings(filterData: BookingFilter): Promise<ApiResponse | null> {
    console.warn("Calling to: fetchBookings", filterData);

    try {

        var params: ParamsApi = {
            query: {
                locator: filterData?.locator ?? "",
                status: filterData?.status ?? "",
                agency: filterData?.agency ?? "",
                location: filterData?.location ?? "",
                name: filterData?.name ?? "",
                ownLocator: filterData?.ownLocator ?? "",
                userEmail: filterData?.userEmail ?? "",
                page: filterData?.page ?? 0,
                pageSize: filterData?.pageSize ?? 10
            }
        };

        // Solo agrega checkIn y checkOut si no son null
        if (filterData?.checkIn) {
            params.query.checkIn = filterData.checkIn;
        }
        if (filterData?.checkOut) {
            params.query.checkOut = filterData.checkOut;
        }

        // Si hotel es una lista, asegúrate de enviarlo como parámetros separados
        if (filterData?.hotel && Array.isArray(filterData.hotel)) {
            params.query.hotelList = JSON.stringify(filterData.hotel); // Convertir lista a JSON
        }


        const response = await restApiClient.fetch("getBookings", params);

        return response;

    } catch (error) {
        console.error('Error push notification data:', error);
        return null;
    }
}




export async function fetchBookingsByUserId(id: string, typeAccount : string): Promise<ApiResponse | null> {
    console.warn("Calling to: fetchBookings", id);

    try {

        var params: ParamsApi = {
            query: {
                id: id,
                type : typeAccount
            }
        };

        const response = await restApiClient.fetch("GetBookingsByAccountId", params);

        return response;

    } catch (error) {
        console.error('Error push notification data:', error);
        return null;
    }
}


export async function fetchHotels(): Promise<ApiResponse | null> {
    console.warn("Calling to: fetchHotels");

    try {
        const response = await restApiClient.fetch("GetHotels", {});

        return response;

    } catch (error) {
        console.error('Error push notification data:', error);
        return null;
    }
}

export async function fetchBookingData(bookingId: string): Promise<ApiResponse | null> {
    console.warn("Calling to: fetchBookingData");
    try {
        var params: ParamsApi = {
            query: {
                id: bookingId
            }
        }

        const response = await restApiClient.fetch("GetBookingData", params);

        return response;

    } catch (error) {
        console.error('Error push notification data:', error);
        return null;
    }
}


export async function changeAccountUserStatus(userId: string, isActive: boolean): Promise<ApiResponse | null> {
    console.warn("Calling to: changeAccountUserStatus");
    try {
        var data: EditAccountStatusData = {
            idUser: userId,
            status: isActive
        }

        var params: ParamsApi = {
            body: data
        }

        const response = await restApiClient.fetch("EditAccountStatus", params);

        return response;

    } catch (error) {
        console.error('Error push notification data:', error);
        return null;
    }
}


// el id puede ser el id del usuario (agente independiente o organizador de ventos) o el id de la compañia/agencia
export async function changeAccountStatus(id: string, isActive: boolean, typeAccount : string): Promise<ApiResponse | null> {
    console.warn("Calling to: changeAccountStatus");

    try {
        var data: AccountChangeStatusDto = {
            id: id,
            status: isActive ? "active" : "inactive",
            typeAccount : typeAccount
        }

        var params: ParamsApi = {
            body: data
        }

        const response = await restApiClient.fetch("ChangeAccountStatus", params);

        return response;

    } catch (error) {
        console.error('Error push notification data:', error);
        return null;
    }
}




export async function createUser(data: NewUserData): Promise<ApiResponse | null> {
    try {
        console.warn("Calling to: createUser", data);



        var params: ParamsApi = {
            body: data
        }

        const response = await restApiClient.fetch("create/BackOfficeUser", params, REDIRECT_TO_ERROR_PAGE);
        return response!;

    } catch (error) {
        console.error('Error fetching colors:', error);
        return null;
    }
}





export async function editUser(data: EditUserData): Promise<ApiResponse | null> {
    try {
        console.warn("Calling to: editUser", data);

        var params: ParamsApi = {
            body: data
        }

        const response = await restApiClient.fetch("edit/BackOfficeUser", params);
        return response!;

    } catch (error) {
        console.error('Error fetching colors:', error);
        return null;
    }
}


export async function filterUsers(filterData: UsersFilterData2): Promise<ApiResponse | null> {
    console.warn("Calling to: fetchUsers", filterData);

    try {

        var params: ParamsApi = {
            query: {
                name: filterData.name,
                email: filterData.email,
                username: filterData.username,
                state: filterData.state,
                role: filterData.role,
                page: filterData.page,
                pageSize: filterData.pageSize
            }
        }

        const response = await restApiClient.fetch("filter/BackOfficeUser", params, REDIRECT_TO_ERROR_PAGE);

        return response;

    } catch (error) {
        console.error('Error push notification data:', error);
        return null;
    }
}



export async function fetchUsers(filterData: UsersFilterData): Promise<ApiResponse | null> {
    console.warn("Calling to: fetchUsers", filterData);


    try {
        return {
            additionalMessages: [],
            code: 200,
            data: {
                list: userDataList.slice(filterData.page, filterData.pageSize),
                totalElements: userDataList.length
            },
            message: "",
            type: 200,
            typeText: "Success"
        };

        var params: ParamsApi = {
            body: {
                filter: filterData
            }
        }

        const response = await restApiClient.fetch("getUsers", params, REDIRECT_TO_ERROR_PAGE);

        return response;

    } catch (error) {
        console.error('Error push notification data:', error);
        return null;
    }
}


export async function fetchUserDataEdition(id: string): Promise<ApiResponse | null> {
    try {
        console.warn("Calling to: fetchUserDataEdition");

        var params: ParamsApi = {
            query: {
                id: id
            }
        }

        const response = await restApiClient.fetch("GetUserDataProfile", params);
        return response!;

    } catch (error) {
        console.error('Error fetching User profile data:', error);
        return null;
    }

}


export async function editUserProfileData(data: UserProfile): Promise<ApiResponse | null> {
    try {
        console.warn("Calling to: editUserProfileData");

        var params: ParamsApi = {
            body: data
        }

        const response = await restApiClient.fetch("EditUserDataProfile", params);
        return response!;

    } catch (error) {
        console.error('Error fetching User profile data:', error);
        return null;
    }

}





