
import React from "react";

import './LoadingDataComponent.scss';
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { useTranslation } from "react-i18next";

const LoadingDataComponent = (): JSX.Element => {

    const { t } = useTranslation();

    return (
        <div className="loading-data-component">
                <Spin indicator={<LoadingOutlined className="app-loading" style={{ fontSize: 80 }} spin />} />
                <p className="app-label">
                    {t('components.loading-data.loading-please-wait')}
                </p>
        </div>
    );
};

export default LoadingDataComponent;