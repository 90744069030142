import EndPointConfig from '../../models/api/EndPointConfig'

const controller = 'Accounts'

export const AdministrationAccountEP : EndPointConfig[] =[

        new EndPointConfig()
        .setMethod("GET")
        .setController(controller)
        .setActionName("GetAccounts"),  

        new EndPointConfig()
        .setMethod("GET")
        .setController(controller)
        .setActionName("GetUserAccounts"),  
        
        new EndPointConfig()
        .setMethod("POST")
        .setController(controller)
        .setActionName("ChangeAccountStatus"),            
]