import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import './MappingConfig.scss';
import ButtonTabMapping from './TabMapping/ButtonTabMapping';
import { GetDefaultLanguage } from '../../../../utils/urls';


const MappingConfig = (): JSX.Element => {

    const { t } = useTranslation();
    const [language] = useState(GetDefaultLanguage());

    return (
        <div className="mapping-configuration-component">
            <ButtonTabMapping language={language} />
        </div>
    );
};



export default MappingConfig;