import { createRoomConfig, editRoomConfig, fetchLanguagesAvailables } from "../../../../../../../api/repositoryEP";
import RoomConfigData, { RoomConfigCreateData, TranslationData } from "../../../../../../../models/MappingData";
import { useEffect, useState } from "react";
import { Form, Row, Col, Button, Divider, Tabs, TabsProps } from "antd";
import { Input, Checkbox } from "antd";
import { notification } from 'antd';
import { useTranslation } from "react-i18next";
import { LoadingOutlined } from "@ant-design/icons";
import './RoomForm.scss'
import { LoadingData } from "../../../../../../common";


interface RoomFormProps {
    drawerVisible: boolean;
    setDrawerVisible: (visible: boolean) => void;
    roomData: RoomConfigData | null;
    setRoomData: (roomData: RoomConfigData | null) => void;
}


const RoomForm = (props: RoomFormProps): JSX.Element => {
    const [roomNames, setRoomNames] = useState<Array<TranslationData>>([])
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [loadingLanguages, setLoadingLanguages] = useState<boolean>(false); 
    const [loading, setLoading] = useState<boolean>(false); 

    useEffect(() => {
        if (props.roomData) {
            form.setFieldsValue({
                code: props.roomData.code,
                integrationCode: props.roomData.integrationCode,
                internalNote: props.roomData.internNote,
                sync: props.roomData.syncReward,
            });
        }
    }, [props.roomData]);


    const closeDrawer = () => {
        props.setRoomData(null)
        form.resetFields();
        props.setDrawerVisible(false)
    }




    const modifyRoomName = (index: number, value: string) => {
        const newRoomNames = [...roomNames];
        newRoomNames[index].value = value;
        setRoomNames(newRoomNames);
    }



    const handleBtnCancel = () => {
        closeDrawer();
    }





    useEffect(() => {

        if (props.drawerVisible) {

            if (props.roomData) {
                console.log("el room data es", props.roomData)
                setRoomNames(props.roomData.name);
            } else {
                setLoading(true); 
                setLoadingLanguages(true)  
                fetchLanguagesAvailables("wa").then((r) => {
                    if (r?.type === 1) {
                        const languages = r.data.map((lang: string) => {
                            return { language: lang, value: '' }
                        })
                        setRoomNames(languages)
                    } else {
                        props.setDrawerVisible(false)
                    }
                    setLoading(false); 
                    setLoadingLanguages(false);
                })

            }
        }
    }, [props.drawerVisible]);




    const onFinish = (values: any) => {
        setLoading(true); 
        if (props.roomData === null) { // CREATE
            const roomData: RoomConfigCreateData = {
                name: roomNames,
                code: values.code,
                integrationCode: values.integrationCode,
                internNote: values.internalNote,
                syncReward: values.sync
            }

            createRoomConfig(roomData).then((response) => {
                if (response?.type === 1) {
                    closeDrawer();
                } 
                setLoading(false);
            })

        } else { // EDIT
            const roomData: RoomConfigData = {
                id: props.roomData?.id,
                name: roomNames,
                code: values.code,
                integrationCode: values.integrationCode,
                internNote: values.internalNote,
                syncReward: values.sync
            }

            editRoomConfig(roomData).then((response) => {
                if (response?.type === 1) {
                    closeDrawer();
                } 
                setLoading(false);
            })
        }

    }

    const mappingItems: TabsProps['items'] = [
        {
            key: '1',
            label:  `${t("components.tab-mapping.title-pms")}`,
            children: <Row gutter={16}>
                        <Col xs={24} md={24}>
                            <Form.Item
                                name="code"
                                label={t("components.tab-mapping.room-config.drawer.code-in-pms")}
                                rules={[{ required: true, message: t("components.tab-mapping.room-config.drawer.code-required") ?? "Code is required" }]}
                                initialValue={props.roomData?.code}
                            >
                                <Input className="app-input" />
                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                            <small>
                                {t("components.tab-mapping.pms-codes-explanation")}
                            </small>
                        </Col>
                    </Row>,
        },
        {
            key: '2',
            label:  `${t("components.tab-mapping.title-integrations")}`,
            children: <Row gutter={16}>
                        <Col xs={24} md={24}>
                            <Form.Item
                                name="integrationCode"
                                label={t("components.tab-mapping.room-config.drawer.code-in-integrations")}
                                initialValue={props.roomData?.integrationCode}
                            >
                                <Input className="app-input" />
                            </Form.Item>
                        </Col>
                        
                        <Col xs={24}>
                            <small>
                                {t("components.tab-mapping.integration-codes-explanation")}
                            </small>
                        </Col>
                    </Row>,
        }
    ];


    return (
        <div   className='room-form-component'>
              {loadingLanguages ? (
                <div style={{ textAlign: 'center', padding: '20px' }}>
                    <LoadingData />
                </div>
            ) : (
                <Form form={form}
                    onFinish={onFinish}
                    layout="vertical"
                >
                    <Row gutter={16}>
                        <Col xs={24} md={24}>
                            <Form.Item
                                label={t("components.tab-mapping.room-config.drawer.name")}
                                required
                                rules={[
                                    {
                                        validator: (_, value) =>
                                            roomNames.length === 0 || roomNames.some(option => option.value === '')
                                                ? Promise.reject((t("components.tab-mapping.room-config.drawer.name-required") ?? "Names are required"))
                                                : Promise.resolve(),
                                    },
                                ]}
                            >
                                {roomNames.map((item, optIndex) => (
                                    <div key={optIndex} style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}>
                                        <span>{item.language.toUpperCase().substring(item.language.length-2)}</span>
                                        <Input
                                            value={item.value}
                                            onChange={(e) => modifyRoomName(optIndex, e.target.value)}
                                            style={{ marginLeft: 8 }}
                                        />

                                    </div>
                                ))}
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={24}>
                            <Form.Item
                                name="internalNote"
                                label={t("components.tab-mapping.room-config.drawer.internal-note")}
                                initialValue={props.roomData?.internNote}
                            >
                                <Input className="app-input" />
                            </Form.Item>
                            
                        </Col>

                        
                        <Divider>
                            {t("components.tab-mapping.title-mapping-code")}
                        </Divider>
                        <Col xs={24} md={24}>
                            <Tabs defaultActiveKey="1" items={mappingItems} />
                        </Col>

                        <Col xs={24} md={24} style={{ marginTop: 40 }}>
                            <Form.Item
                                name="sync"
                                valuePropName="checked"
                                initialValue={props.roomData?.syncReward ?? false}
                            >
                                <Checkbox className="app-input" >{t("components.tab-mapping.room-config.drawer.checkbox")}</Checkbox>
                            </Form.Item>
                        </Col>


                        <Col xs={24} md={12}>
                            <Form.Item style={{ textAlign: 'center' }}>
                                <Button className="app-button" style={{ width: "70%" }} onClick={handleBtnCancel}>
                                    {t("components.button.cancel")}
                                </Button>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item style={{ textAlign: 'center' }}>
                            <Button className="app-button" style={{ width: "70%" }} htmlType="submit" disabled={loading}>
                                {loading ? <LoadingOutlined /> : t("components.button.save")}
                            </Button>
                            </Form.Item>
                        </Col>

                    </Row>
                </Form>
            )}
        </div>
    );
};

export default RoomForm