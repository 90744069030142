import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Table, Row, Col, Button, Drawer, notification } from 'antd';
import type { TableProps } from 'antd';
import { RegimesConfigData, TranslationData } from '../../../../../../models/MappingData';
import { DeleteOutlined, EditOutlined, ImportOutlined } from '@ant-design/icons';
import { fetchRegimesConfigMapping } from '../../../../../../api/repositoryEP';
import './RegimesConfig.scss';
import Search from 'antd/es/input/Search';
import RegimesForm from '../Forms/RegimesForm/RegimesForm';
import ModalGeneric from '../Modals/ModalGeneric';

interface RegimesConfigProps {
    language: string;
}

const RegimesConfig = (props: RegimesConfigProps): JSX.Element => {
    const { t } = useTranslation();
    const [data, setData] = useState<RegimesConfigData[]>([]);
    const [loading, setLoading] = useState<boolean>(true);  // Estado para el indicador de carga
    const drawerWidth = window.innerWidth <= 768 ? '100%' : '40%';
    const [newRegimeDrawer, setNewRegimeDrawer] = useState<boolean>(false);
    const [editRegimeDrawer, setEditRegimeDrawer] = useState(false);
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);
    const [regimenData, setRegimenData] = useState<RegimesConfigData | null>(null);

    useEffect(() => {
        if (!newRegimeDrawer && !editRegimeDrawer && !deleteModalVisible) {
            setLoading(true);  
            fetchRegimesConfigMapping('', props.language).then((response) => {
                if (response?.type === 1) {
                    setData(response.data);
                } 
                setLoading(false); 
            });
        }
    }, [newRegimeDrawer, editRegimeDrawer, deleteModalVisible, props.language]);

    const columns: TableProps<RegimesConfigData>['columns'] = [
        {
            title: t("components.tab-mapping.regimes-config.table.c1"),
            dataIndex: 'name',
            key: 'name',
            render: (names: TranslationData[]) => {
                const normalizedLanguage = props.language.slice(-2).toLowerCase();
                const nameInLanguage = names.find((name: TranslationData) => name.language.slice(-2).toLowerCase() === normalizedLanguage);
                return nameInLanguage?.value || 'No name available';
            },
        },
        {
            title: t("components.tab-mapping.regimes-config.table.c2"),
            dataIndex: 'code',
            key: 'code',
        },
        {
            title: t("components.tab-mapping.regimes-config.table.c3"),
            dataIndex: 'internNote',
            key: 'interNote',
        },
        {
            title: t("components.tab-mapping.regimes-config.table.c4"),
            key: 'action',
            render: (obj) => (
                <div className='app-icon-table-container'>
                    <EditOutlined className='app-icon-table' onClick={() => editRegimen(obj)} />
                    <DeleteOutlined className='app-icon-table' onClick={() => deleteRegimen(obj)} />
                </div>
            ),
        },
    ];

    const editRegimen = (regimen: RegimesConfigData) => {
        setRegimenData(regimen);
        setEditRegimeDrawer(true);
    };

    const deleteRegimen = (regimen: RegimesConfigData) => {
        setRegimenData(regimen);
        setDeleteModalVisible(true);
    };

    const onSearch = (value: string) => {
        setLoading(true);  // Inicia la carga
        fetchRegimesConfigMapping(value, props.language).then((response) => {
            if (response?.type === 1) {
                setData(response.data);
            } 
            setLoading(false);  // Finaliza la carga
        });
    };

    const handleAddButton = () => {
        setRegimenData(null);
        setNewRegimeDrawer(true);
    };

    return (
        <div className="regimen-config-component">
            {deleteModalVisible && regimenData?.id && (
                <ModalGeneric
                    modalVisible={deleteModalVisible}
                    setModalVisible={setDeleteModalVisible}
                    id={regimenData.id}
                    action="deleteRegimen"
                    data={regimenData}
                    setData={setRegimenData}
                />
            )}

            <Drawer
                open={editRegimeDrawer}
                title={t("components.tab-mapping.regimes-config.drawer.title-edit")}
                onClose={() => {
                    setEditRegimeDrawer(false);
                    setRegimenData(null);
                }}
                width={drawerWidth}
                closable
            >
                <RegimesForm
                    setDrawerVisible={setEditRegimeDrawer}
                    drawerVisible={editRegimeDrawer}
                    regimesData={regimenData}
                    setRegimesData={setRegimenData}
                />
            </Drawer>

            <Drawer
                open={newRegimeDrawer}
                title={t("components.tab-mapping.regimes-config.drawer.title-new")}
                onClose={() => {
                    setNewRegimeDrawer(false);
                    setRegimenData(null);
                }}
                width={drawerWidth}
                closable
            >
                <RegimesForm
                    setDrawerVisible={setNewRegimeDrawer}
                    drawerVisible={newRegimeDrawer}
                    regimesData={null}
                    setRegimesData={setRegimenData}
                />
            </Drawer>

            <Row gutter={[10, 10]} style={{ flexDirection: 'column' }}>
                <Col xs={24} md={24}>
                    <Row gutter={[16, 16]}>
                        <Col xs={24} md={24} style={{ fontFamily: 'Montserrat' }}>
                            {t("components.tab-mapping.regimes-config.msg-info")}
                        </Col>
                        <Col xs={24} md={24} lg={16}>
                            <Search
                                className="app-select"
                                placeholder={t("components.tab-mapping.regimes-config.search") || ''}
                                allowClear
                                onSearch={onSearch}
                            />
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={4}>
                            <Button className='app-button' onClick={handleAddButton}>
                                {t("components.tab-mapping.regimes-config.button-add")}
                            </Button>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={4}>
                            <Button disabled className='app-button'><ImportOutlined /> {t("components.tab-mapping.room-config.button-import")}</Button>
                        </Col>
                    </Row>
                </Col>

                <Col xs={24} md={24}>
                    <Table
                        className='table'
                        columns={columns}
                        dataSource={data}
                        loading={loading}  // Indicador de carga
                        scroll={{ x: 'max-content' }}
                    />
                </Col>
            </Row>
        </div>
    );
};

export default RegimesConfig;
