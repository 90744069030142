import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { availableAppLanguages } from "../../../../Contexts/AppConfigContext";
import './RegisterForm.scss';
import FilterRegisterForm from "./filter/FilterRegisterForm";
import ListRegisterForm from "./list/ListRegisterForm";
import { RegisterFormFieldsData } from "../../../../models/RegisterFormData";
import { RegisterFormFilterData } from '../../../../models/RegisterFormData';
import { fetchFilterFieldsRegisterForm } from "../../../../api/repositoryEP";
import { CustomPagination } from "../../..";
import { NoData } from "../../../common";

interface RegisterFormInterface {
    languages: string[];
}

const RegisterForm = (props: RegisterFormInterface): JSX.Element => {
    const defaultPageSize = 10;

    const languages = props.languages;
    const [fieldsList, setFieldList] = useState<RegisterFormFieldsData[]>([])
    const [searching, setSearching] = useState<boolean>(true);

    const [actualPage, setActualPage] = useState<number>(1);
    const [actualPageSize, setActualPageSize] = useState<number>(defaultPageSize);
    const [totalRecords, setTotalRecords] = useState<number>(0);
    const [actualFilter, setActualFilter] = useState<RegisterFormFilterData>({
        status: '',
        fieldType : '',
        label :'',
        language: '',
        nameField : '',
        page: 0,
        pageSize: defaultPageSize,
    });

    const onChangePagination = (newPage: number, newPageSize?: number | undefined) => {
        setActualPage(newPage);
        setActualPageSize(newPageSize ?? defaultPageSize);
        setActualFilter({
            ...actualFilter,
            page: newPage - 1,
            pageSize: newPageSize ?? defaultPageSize
        });
    }

    const filterRegiterFormFields = async () => {
        setSearching(true);
        fetchFilterFieldsRegisterForm(actualFilter)
            .then((r) => {
                if (r?.type === 1) {
                    setFieldList(r?.data.elements as RegisterFormFieldsData[]);
                    setTotalRecords(r?.data.totalRecords as number);
                }
                setSearching(false);
            })
    };

    useEffect(() => {
        filterRegiterFormFields();
    }, [actualFilter])


    return (
        <div className="register-form-component ">
            <div className="filter-container">
                <FilterRegisterForm
                    onSearch={setActualFilter}
                    searching={searching}
                    languages={languages}
                />

                <ListRegisterForm
                    list={fieldsList}
                    loading={searching}
                    languages={languages}
                    onSearch={setActualFilter}
                />

            </div>
            {
                !searching && fieldsList.length === 0 ?
                    <NoData />
                    :
                    <div className="pagination-container">
                        <CustomPagination actualPage={actualPage} actualPageSize={actualPageSize} totalElements={totalRecords} onChange={onChangePagination}/>
                    </div>
            }
        </div>
    );
};


export default RegisterForm;