import Card from "antd/es/card/Card";
import { Switch, Drawer, Checkbox, Select } from "antd";
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { RegisterFormFieldsData, RegisterFormFilterData } from "../../../../../models/RegisterFormData";
import FieldForm from "../fieldForm/FieldForm";
import { EditOutlined } from "@ant-design/icons";
import { editStatusFieldForm } from "../../../../../api/repositoryEP";
import Col from "antd/es/grid/col";
import Radio from "antd/es/radio";
import { DeleteOutlined } from "@ant-design/icons";
import './ListRegisterForm.scss'
import { LoadingData } from "../../../../common";
import ModalGeneric from "../../MappingConfig/TabMapping/Modals/ModalGeneric";

interface ListRegisterFormProps {
    list: RegisterFormFieldsData[];
    loading: boolean;
    languages: string[];
    onSearch: (filterData: RegisterFormFilterData) => void;
}


const ListRegisterForm = (props: ListRegisterFormProps): JSX.Element => {
    const { t } = useTranslation();
    const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
    const [selected, setSelected] = useState<RegisterFormFieldsData>();
    const [modalDelete, setModalDelete] = useState<boolean>(false);
    const [id, setId] = useState<string>("");

    useEffect(() => {
        if (!drawerVisible && !modalDelete) {
            const filterData: RegisterFormFilterData = ({
                status: "",
                fieldType: "",
                label: "",
                nameField: "",
                language: "",
                page: 0, 
                pageSize: 10
            });
            props.onSearch(filterData);

        }
    }, [drawerVisible, modalDelete]);

    const showEditDrawer = (item: RegisterFormFieldsData) => {
        setSelected(item);
        setDrawerVisible(true);
    }


    const showDeleteModal = (id: string) => {
        setId(id);
        setModalDelete(true);
    }



    const changeItemStatus = (id: string, isActive: boolean) => {
        editStatusFieldForm(id, isActive);
    }

    const { list, loading } = props;

    return (
        <div className="accounts-list">
            {modalDelete && (
                <ModalGeneric
                    modalVisible={modalDelete}
                    setModalVisible={setModalDelete}
                    id={id}
                    action='deleteFieldForm'
                    data={selected}
                    setData={setSelected}
                />
            )
            }

            {
                loading && <LoadingData />
            }

            {
                !loading && list.map((item: RegisterFormFieldsData, index: number) => {

                    var cardHeader: JSX.Element = <div className="header-container" key={index}>
                        <div className="account-type">
                            <span>
                                {item.label}
                                <EditOutlined style={{ fontSize: '20px' }} onClick={() => showEditDrawer(item)} />
                            </span>
                        </div>
                        <div>
                            <span>{t('components.tab-configuration.register-form.filter.state')}  </span>
                            <Switch className="app-switch" defaultChecked={item.isActive} onChange={(checked) => changeItemStatus(item.id, checked)} />
                            <DeleteOutlined style={{ fontSize: '20px', marginLeft: 20 }} onClick={() => showDeleteModal(item.id)} />

                        </div>
                    </div>

                    return (
                        <Card className="app-card" title={cardHeader} bordered={false}>
                            <Drawer open={drawerVisible} title={t('components.tab-configuration.register-form.drawer-edit.title')} onClose={() => { setDrawerVisible(false) }} width={"50%"} closable>
                                <FieldForm
                                    drawerVisible={drawerVisible}
                                    setDrawerVisible={setDrawerVisible}
                                    languages={props.languages}
                                    fieldData={selected}
                                />

                            </Drawer>
                            <div className="body-container">
                                <div className="additional-info">
                                    <div className="names">
                                        <span>
                                            <strong>{t('components.tab-configuration.register-form.filter.field-type')}: </strong>
                                            {item.fieldType === 'text_area'
                                                ? 'TextArea'
                                                : item.fieldType.charAt(0).toUpperCase() + item.fieldType.slice(1)}
                                        </span>

                                        <span><strong>{t('components.tab-configuration.register-form.filter.language')}: </strong>  {t(`languages.${item.language}`)}</span>
                                        <span> <strong>{t('components.tab-configuration.register-form.filter.name-field')}</strong>: {item.nameField}</span>

                                    </div>
                                    <div className="contact">
                                        <span><strong>{t('components.tab-configuration.register-form.filter.label')}</strong>: {item.label}</span>
                                        {(item.fieldType === 'input' || item.fieldType === 'text_area' || item.fieldType === 'select') && (
                                            <>
                                                <span><strong>{t('components.tab-configuration.register-form.filter.default-value')}</strong>: {item.defaultValue}</span>
                                                <span><strong>{t('components.tab-configuration.register-form.filter.placeholder')}</strong>: {item.placeholder}</span>
                                            </>
                                        )}

                                    </div>
                                    <div className="resume">
                                        <span><strong>{t('components.tab-configuration.register-form.filter.required')}</strong>: <Checkbox disabled checked={item.required}></Checkbox></span>
                                        <span><strong>{t('components.tab-configuration.register-form.filter.alone')}</strong>: <Checkbox disabled checked={item.alone}></Checkbox></span>
                                        {item.fieldType === 'select' && (
                                            <>
                                                <span><strong>{t('components.tab-configuration.register-form.filter.searchable')}</strong>: <Checkbox disabled checked={item.searchable}></Checkbox></span>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {(item.fieldType == 'select' && item.options) && (
                                <Col xs={24} md={8}>
                                    <Select className="list-register-form" defaultValue={JSON.parse(item.options)[0]} >
                                        {JSON.parse(item.options).map((option: string, index: number) => (
                                            <Select.Option key={index} value={option}>
                                                {option}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Col>
                            )}

                            {item.fieldType === 'radio' && item.options && (
                                <Col xs={24} md={8}>
                                    <Radio.Group defaultValue={JSON.parse(item.options)[0]} className="list-register-form">
                                        {JSON.parse(item.options).map((option: string, index: number) => (
                                            <Radio key={index} value={option}>
                                                {option}
                                            </Radio>
                                        ))}
                                    </Radio.Group>
                                </Col>
                            )}
                        </Card>
                    )
                })
            }
        </div>
    )
}

export default ListRegisterForm;