import {ConfigurationData} from "../models/ConfigurationData"
export const configurationDataPRO: ConfigurationData = {
    mainColorWeb: '#3DBAB3',
    secondaryColorWeb: '#414745',
    mainColorWidget: '#3DBAB3',
    secondaryColorWidget: '#414745',
    design: 1
}

export const clientLanguagesMock: Array<string> = 
   [
    "en","es","fr"
   ]
