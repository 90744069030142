import './TabConfiguration.scss';
import React, { useEffect, useState } from "react";
import { Tabs } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from 'react-router-dom';
import GroupForm from "./components/GroupForm/GroupForm";
import LandingPage from "./components/LandingPage/LandingPage";
import MappingConfig from "./components/MappingConfig/MappingConfig";
import ProConfiguration from "./components/ProConfiguration/ProConfiguration";
import RegisterForm from "./components/RegisterForm/RegisterForm";
import { GetDefaultLanguage } from '../../utils/urls';
import { fetchLanguagesAvailables } from '../../api/repositoryEP';

const TabConfiguration = (): JSX.Element => {
    const { t } = useTranslation();
    const [clientLanguages, setClientLanguages] = useState<string[]>([]);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        fetchLanguagesAvailables("wa").then((response) => {
            if (response?.code === 200) {
                setClientLanguages(response.data);
            }
        });
    }, []);

    // Determine the active tab based on the current location
    const determineActiveTab = () => {
        const path = location.pathname.split('/').pop();
        switch (path) {
            case 'pro-configuration':
                return '1';
            case 'landing-page':
                return '2';
            case 'register-form':
                return '3';
            case 'group-form':
                return '4';
            case 'mapping-config':
                return '5';
            default:
                return '1';
        }
    };

    const handleTabChange = (key: string) => {
        console.log("Handle tab change key", key);  
        switch (key) {
            case '1':
                navigate(`pro-configuration`);
                break;
            case '2':
                navigate(`landing-page`);
                break;
            case '3':
                navigate(`register-form`);
                break;
            case '4':
                navigate(`group-form`);
                break;
            case '5':
                navigate(`mapping-config`);
                break;
            default:
                navigate(`pro-configuration`);
        }
    };

    const items = [
        {
            key: '1',
            label: t("components.tab-configuration.pro-configuration.title"),
        },
        {
            key: '2',
            label: t("components.tab-configuration.landing-page.title"),
        },
        {
            key: '3',
            label: t("components.tab-configuration.register-form.title"),
        },
        {
            key: '4',
            label: t("components.tab-configuration.group-form.title"),
        },
        {
            key: '5',
            label: t("components.tab-configuration.mapping-config.title"),
        },
    ];

    return (
        <div className="tab-configuration-component ant-tabs-nav">
            <Tabs activeKey={determineActiveTab()} onChange={handleTabChange} className='tabs'>
                {items.map(item => (
                    <Tabs.TabPane tab={item.label} key={item.key} />
                ))}
            </Tabs>
            {/* Render the corresponding component based on the current path */}
            <div className="tab-content">
                {location.pathname.endsWith("pro-configuration") && <ProConfiguration languages={clientLanguages} />}
                {location.pathname.endsWith("landing-page") && <LandingPage languages={clientLanguages} />}
                {location.pathname.endsWith("register-form") && <RegisterForm languages={clientLanguages} />}
                {location.pathname.endsWith("group-form") && <GroupForm defaultLanguage={GetDefaultLanguage()} languages={clientLanguages} />}
                {location.pathname.endsWith("mapping-config") && <MappingConfig />}
            </div>
        </div>
    );
}

export default TabConfiguration;
