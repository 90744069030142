
import { useTranslation } from 'react-i18next';
import { BookingsFilter, BookingsList, CustomPagination } from '../../../components';
import './BookingsPage.scss';
import { fetchBookingData, fetchBookings, fetchBookingsByUserId, fetchHotels } from '../../../api/repositoryEP';
import { BookingFilter } from '../../../models/BookingsFilter';
import { useEffect, useState } from 'react';
import BookingInfo from '../../../models/BookingInfo';
import HotelInfo from '../../../models/HotelInfo';

import BookingData from '../../../models/BookingData';
import { useLocation } from 'react-router-dom';
import NoDataComponent from '../../../components/common/NoData/NoDataComponent';

const BookingsPage = () : JSX.Element => {
    const { t } = useTranslation();
    const location = useLocation();

    const defaultPageSize = 10;


    const [actualPage, setActualPage] = useState<number>(1);
    const [actualPageSize, setActualPageSize] = useState<number>(defaultPageSize);
    const [totalRecords, setTotalRecords] = useState<number>(0);
    const [actualFilter, setActualFilter] = useState<BookingFilter>({
        locator: '',
        status: '',
        agency: '',
        checkIn: '',
        checkOut: '',
        location: '',
        name: '',
        hotel: [],
        ownLocator: '',
        userEmail: '',
        page: 0,
        pageSize: defaultPageSize,
    });

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const account = params.get('account');
        const typeAccount = params.get('type');
    
        if (account && account.trim() !== '' && typeAccount && typeAccount.trim() !== '') {
            getBookingsByAccountId(account,typeAccount);
        } else {
            getBookings();
        }
    
        // Obtener hoteles
        getHotels();
    }, [location.search]); 


    const [bookingList, setBookingList] = useState<BookingInfo[]>([]);
    const [searching, setSearching] = useState<boolean>(false);

    const getBookings = () => {
        setSearching(true);
        fetchBookings(actualFilter).then((r)=>{
            if (r?.code === 200 && r){
                setBookingList(r?.data.elements as BookingInfo[]);
                setTotalRecords(r?.data?.totalRecords as number);
            }else{
                setBookingList([]);
                setTotalRecords(0);
            }
        }).finally(()=>{
            setSearching(false);
        })
    }


    const getBookingsByAccountId = (id: string, typeAccount : string ) => {
        setSearching(true);
        fetchBookingsByUserId(id,typeAccount).then((r)=>{
            if (r?.code === 200 && r){
                setBookingList(r?.data as BookingInfo[]);
                setTotalRecords(r?.data?.length as number);
            }else{
                setBookingList([]);
                setTotalRecords(0);
            }
        }).finally(()=>{
            setSearching(false);
        })
    }


    const [hotels, setHotels] = useState<HotelInfo[]>([]);
    const getHotels = () => {

        fetchHotels().then((r)=>{
            if (r?.type === 1 && r){
               
                setHotels(r?.data as HotelInfo[]);
            }else{
                setHotels([]);
            }
        })
    }

    const [isGettingBookingData, setIsGettingBookingData] = useState<boolean>(false);
    const [bookingData, setBookingData] = useState<BookingData>();
    const getBookingData = (bookingId: string) => {
        setIsGettingBookingData(true);
        fetchBookingData(bookingId).then((r)=>{
            if (r?.code === 200 && r?.data){
                console.log(r?.data);
                setBookingData(r?.data as BookingData);
            }else{
                setBookingData(undefined);
            }
        }).finally(()=>{
            setIsGettingBookingData(false);
        })
    }

    const onChangePagination = (newPage: number, newPageSize?: number | undefined) => {
        setActualPage(newPage);
        setActualPageSize(newPageSize ?? defaultPageSize);
        setActualFilter({
            ...actualFilter,
            page: newPage - 1,
            pageSize: newPageSize ?? defaultPageSize
        });
    }

    useEffect(() => {
        getBookings()
    }, [actualFilter])

    return(
        <div className="bookings-page">
            <div className="filter-container">
                <BookingsFilter searching={searching} onSearch={setActualFilter} hotelList={hotels}/>
            </div>
            {
                bookingList.length === 0 && !searching ?  <NoDataComponent />
                : 
                <>
                    <div className="list-container">
                        <BookingsList loading={searching} list={bookingList} onGetBookingData={getBookingData} bookingData={bookingData} isGettingBookingData={isGettingBookingData}/>
                    </div>
                    {
                        !searching && <div className="pagination-container">
                                        <CustomPagination actualPage={actualPage} actualPageSize={actualPageSize} totalElements={totalRecords} onChange={onChangePagination}/>
                                    </div>
                    }
                </>
            }
            
        </div>
    )
}

export default BookingsPage;