import { Button, Form, Input } from "antd";
import { useForm } from "antd/es/form/Form";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";


import './LoginForm.scss';
import { LoadingOutlined } from "@ant-design/icons";
import { GetFormattedUrl } from "../../utils/urls";
import { useState } from "react";
import { InputLabeled } from "../common";

interface LoginFormComponentProps{
    onLogin: (username: string, password: string)=>void,
    doingLogin: boolean,
    onForgetPassword: () => void
}



const LoginFormComponent = (props: LoginFormComponentProps) : JSX.Element => {
    
    const { t } = useTranslation();
    
    const [form] = useForm();

    const { onLogin, onForgetPassword, doingLogin } = props;

    const onFinish = () : void => {
        onLogin(form.getFieldValue("username"), form.getFieldValue("password"));
    }

    return(
        <div className="login-form">
            <Form form={form} layout="vertical" onFinish={onFinish}>

                <InputLabeled inputName="username" type="text" placeHolder={t("components.login-form.username")}/>

                <InputLabeled inputName="password" type="password" placeHolder={t("components.login-form.password")}/>

                <div className="reminder-password-link">
                    <span className="app-colored-main-font app-link" onClick={onForgetPassword}>{t("components.login-form.reminder-password-link")}</span>
                </div>
            
                <Button size="large" className="app-button btn-submit" htmlType="submit" disabled = {doingLogin}>
                    {doingLogin ? <LoadingOutlined /> : ''}{t("components.login-form.access")}
                </Button>

            </Form>
            <div className="not-register">
                {/*href={`${GetFormattedUrl('register')}`}*/}
                <a className="app-colored-main-font app-link"  onClick={()=>{window.location.href="https://beds2bpro.com/contacto/"}}>{t("components.login-form.not-register")}</a>
            </div>
        </div>
    )
}

export default LoginFormComponent;