import { ServicesConfigCreateData, ServicesConfigData } from "../../../../../../../models/MappingData";
import { createServicesConfig, editServicesConfig, fetchLanguagesAvailables } from "../../../../../../../api/repositoryEP";
import  { TranslationData } from "../../../../../../../models/MappingData";
import { useEffect, useState } from "react";
import { Form, Row, Col, Button } from "antd";
import { Input, Checkbox } from "antd";
import { notification } from 'antd';
import { useTranslation } from "react-i18next";
import './ServicesForm.scss'
import { LoadingOutlined } from "@ant-design/icons";


interface ServicesFormProps {
    setDrawerVisible: (visible: boolean) => void;
    drawerVisible: boolean;
    serviceData: ServicesConfigData | null;
    setServiceData: (servicesData: ServicesConfigData | null) => void;
}


const ServicesForm = (props: ServicesFormProps): JSX.Element => {

    const [servicesNames, setServicesNames] = useState<TranslationData[]>([]);
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [loadingLanguages, setLoadingLanguages] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false); 

    
    useEffect(() => {
        if (props.serviceData) {
            console.log(props.serviceData)
            form.setFieldsValue({
                code: props.serviceData.code,
                internalNote: props.serviceData.internCode,
                sync: props.serviceData.syncReward,
            });
        } else {
            form.resetFields();
        }
    }, [props.serviceData]);


    const closeDrawer = () => {
        props.setServiceData(null)
        form.resetFields();
        props.setDrawerVisible(false)
    }


    const modifyServicesName = (index: number, value: string) => {
        const newRoomNames = [...servicesNames];
        newRoomNames[index].value = value;
        setServicesNames(newRoomNames);
    }


    const handleBtnCancel = () => {
        closeDrawer();
    }

    useEffect(() => {

        if (props.drawerVisible) {

            if (props.serviceData) { // estamos editando
                setServicesNames(props.serviceData.name);
                

            } else { // estamos creando
                setLoadingLanguages(true)    
                setLoading(true); // Inicia la carga
                fetchLanguagesAvailables("wa").then((r) => {                   
                    if (r?.type === 1) {
                        const languages = r.data.map((lang: string) => {
                            return { language: lang, value: '' }
                        })
                        setServicesNames(languages)
                    } else {
                        props.setServiceData(null)
                        form.resetFields();
                        props.setDrawerVisible(false)
                    }
                    setLoading(false); // Finaliza la carga
                    setLoadingLanguages(false);
                })
            }
        }

    }, [props.drawerVisible]);



    const onFinish = (values: any) => {
        setLoading(true); 
        //EIDTAR
        if (props.serviceData) {
            const sData: ServicesConfigData = {
                id: props.serviceData.id,
                code: values.code,
                internCode: values.internalNote,
                syncReward: values.sync,
                name: servicesNames
            }
            editServicesConfig(sData).then((response) => {
                if (response?.type === 1) {
                    closeDrawer();
                } 
                setLoading(false);
            });

        } else {// CREAR
          
            const sData: ServicesConfigCreateData = {
                code: values.code,
                internNote: values.internalNote,
                syncReward: values.sync,
                name: servicesNames
            }

            createServicesConfig(sData).then((response) => {
                if (response?.type === 1) {
                    closeDrawer();
                } 
                setLoading(false);
            });
        }
    }




    return (
        <div className='services-form-component'>
               {loadingLanguages ? (
                <div style={{ textAlign: 'center', padding: '20px' }}>
                    <LoadingOutlined style={{ fontSize: 24 }} spin />
                    <p>{t("components.loading-text")}</p>
                </div>
            ) : (
            <Form form={form}
                onFinish={onFinish}
                layout="vertical"
            >
                <Row gutter={16}>
                    <Col xs={24} md={24}>
                        <Form.Item                          
                            label={t("components.tab-mapping.services-config.drawer.name")}
                            required
                            rules={[
                                {
                                    validator: (_, value) =>
                                        servicesNames.length === 0 || servicesNames.some(option => option.value === '')
                                            ? Promise.reject(t("components.tab-mapping.services-config.drawer.name-required") ?? "Names are required")
                                            : Promise.resolve(),
                                },
                            ]}
                        >
                            {servicesNames.map((item, optIndex) => (
                                <div key={optIndex} style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}>
                                  <span>{item.language.substring(item.language.length - 2).toUpperCase()}</span>
                                    <Input
                                        value={item.value}
                                        onChange={(e) => modifyServicesName(optIndex, e.target.value)}
                                        style={{ marginLeft: 8 }}
                                    />
                                </div>
                            ))}
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={24}>
                        <Form.Item
                            name="code"
                            label={t("components.tab-mapping.services-config.drawer.code")}
                            rules={[{ required: true, message: t("components.tab-mapping.services-config.drawer.code-required") ?? "Code is required" }]}
                            initialValue={props.serviceData?.code}
                        >
                            <Input className="app-input" />
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={24}>
                        <Form.Item
                            name="internalNote"
                            label={t("components.tab-mapping.services-config.drawer.internal-note")}
                            rules={[{ required: true, message: t("components.tab-mapping.services-config.drawer.internal-note-required") ?? "Internal note is required" }]}
                            initialValue={props.serviceData?.internCode}
                        >
                            <Input className="app-input" />
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={24} style={{ marginTop: 40 }}>
                        <Form.Item
                            name="sync"
                            valuePropName="checked"
                            initialValue={props.serviceData?.syncReward ?? false}
                        >
                            <Checkbox className="app-input" >{t("components.tab-mapping.services-config.drawer.checkbox")}</Checkbox>
                        </Form.Item>
                    </Col>


                    <Col xs={24} md={12}>
                        <Form.Item style={{ textAlign: 'center' }}>
                            <Button className="app-button" style={{ width: "70%" }} onClick={handleBtnCancel}>
                                {t("components.button.cancel")}
                            </Button>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item style={{ textAlign: 'center' }}>
                            <Button className="app-button" style={{ width: "70%" }} htmlType="submit" disabled={loading}>
                                {loading ? <LoadingOutlined /> : t("components.button.save")}
                            </Button>
                        </Form.Item>
                    </Col>

                </Row>

            </Form>
            )}
        </div>
    )
}

export default ServicesForm;