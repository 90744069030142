import EndPointConfig from '../../models/api/EndPointConfig'

const controller = 'Services'

export const ServicesEP : EndPointConfig[] =[
    new EndPointConfig()
    .setMethod("POST")
    .setController(controller)
    .setActionName("CreateServiceConfigMapping"),    
    new EndPointConfig()
    .setMethod("POST")
    .setController(controller)
    .setActionName("EditServiceConfigMapping"),    
    new EndPointConfig()
    .setMethod("DELETE")
    .setController(controller)
    .setActionName("DeleteServiceConfigMapping"),   
    new EndPointConfig()
    .setMethod("GET")
    .setController(controller)
    .setActionName("GetServiceConfigMapping"),
]