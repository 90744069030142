import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Table } from 'antd';
import RoomConfigData, { TranslationData } from '../../../../../../models/MappingData';
import { DeleteOutlined, EditOutlined, ImportOutlined } from '@ant-design/icons';
import { Row, Col, Button, Drawer } from 'antd';
import {  fetchRoomConfigMapping } from '../../../../../../api/repositoryEP';
import { notification } from 'antd';
import RoomForm from '../Forms/RoomForm/RoomForm';
import Search from 'antd/es/input/Search';
import ModalGeneric from '../Modals/ModalGeneric';


interface RoomConfigProps {
    language: string;
}

const RoomConfig = (props: RoomConfigProps): JSX.Element => {

    const { t } = useTranslation();
    const [data, setData] = useState<RoomConfigData[]>([]);
    const drawerWidth = window.innerWidth <= 768 ? '100%' : '40%';
    const [newRoomDrawer, setNewRoomDrawer] = useState<boolean>(false);
    const [editRoomDrawer, setEditRoomDrawer] = useState(false);
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);
    const [roomData, setRoomData] = useState<RoomConfigData | null>(null);
    const [loading, setLoading] = useState<boolean>(true);  // Estado para el indicador de carga


    useEffect(() => {
        if (!newRoomDrawer && !editRoomDrawer && !deleteModalVisible) {
            setLoading(true);  
            fetchRoomConfigMapping('',props.language).then((response) => {
                if (response?.type === 1) {             
                    setData(response.data);   
                } 
                setLoading(false);  
            });
        }

    }, [newRoomDrawer, editRoomDrawer, deleteModalVisible]);

    const columns = [
        {
            title: t("components.tab-mapping.room-config.table.c1"),
            dataIndex: 'name',
            key: 'name',
            render: (names: TranslationData[]) => {
                // Extrae los dos últimos caracteres del idioma en minúsculas
                const normalizedLanguage = props.language.slice(-2).toLowerCase();
    
                // Encuentra el nombre que coincida con el idioma normalizado
                const nameInLanguage = names.find((name: TranslationData) => name.language.slice(-2).toLowerCase() === normalizedLanguage);
    
                return nameInLanguage?.value || 'No name available';
            }
        },
        {
            title: t("components.tab-mapping.room-config.table.c2"),
            dataIndex: 'code',
            key: 'code',
        },
        {
            title: t("components.tab-mapping.room-config.table.c3"),
            dataIndex: 'internNote',
            key: 'internNote',
        },
        {
            title: t("components.tab-mapping.room-config.table.c4"),
            key: 'action',
            render: (obj: RoomConfigData) => (
                <div className='app-icon-table-container'>
                    <EditOutlined className='app-icon-table' onClick={() => editRoom(obj)} />
                    <DeleteOutlined className='app-icon-table' onClick={() => deleteRoom(obj)} />
                </div>
            ),
        }
    ];
    

    const editRoom = (room: RoomConfigData) => {
        setRoomData(room);
        setEditRoomDrawer(true)
    };



    const deleteRoom = (room: RoomConfigData) => {
        setRoomData(room);
        setDeleteModalVisible(true);
    }


    const onSearch = (value: string) => {
        setLoading(true);
        fetchRoomConfigMapping(value,props.language).then((response) => {
            if (response?.type === 1) {
                setData(response.data);
            } 
            setLoading(false);
        });
    }

    const handleAddButton = () => {
        setRoomData(null);
        setNewRoomDrawer(true);

    }


    return (
        <div className="room-config-component">
            {deleteModalVisible && roomData?.id &&
                <ModalGeneric
                    modalVisible={deleteModalVisible}
                    setModalVisible={setDeleteModalVisible}
                    id={roomData.id}
                    action='deleteRoom'
                    data={roomData}
                    setData={setRoomData}
                />}

            <Drawer
                open={editRoomDrawer}
                title={t("components.tab-mapping.room-config.drawer.title-edit")}
                onClose={() => {
                    setRoomData(null)
                    setEditRoomDrawer(false)
                }}
                width={drawerWidth}
                closable>
                <RoomForm
                    setDrawerVisible={setEditRoomDrawer}
                    drawerVisible={editRoomDrawer}
                    roomData={roomData}
                    setRoomData={setRoomData}
                />
            </Drawer>

            <Drawer
                open={newRoomDrawer}
                title={t("components.tab-mapping.room-config.drawer.title-new")}
                onClose={() => {
                    setNewRoomDrawer(false);
                    setRoomData(null)
                }}
                width={drawerWidth}
                closable>
                <RoomForm
                    setDrawerVisible={setNewRoomDrawer}
                    drawerVisible={newRoomDrawer}
                    roomData={null}
                    setRoomData={setRoomData}
                />
            </Drawer>


            <Row gutter={[10, 10]} style={{ flexDirection: 'column' }}>
                <Col xs={24} md={24}>
                    <Row gutter={[16, 16]}>
                        <Col xs={24} md={24} style={{ fontFamily: 'Montserrat' }}>
                            {t("components.tab-mapping.room-config.msg-info")}
                        </Col>
                        <Col xs={24} md={24} lg={16}>
                            <Search
                                placeholder={t("components.tab-mapping.room-config.search") || ''}
                                allowClear
                                onSearch={onSearch}
                            />
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={4}>
                            <Button
                                className='app-button'
                                onClick={() => handleAddButton()}>
                                {t("components.tab-mapping.room-config.button-add")}
                            </Button>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={4}>
                            <Button disabled className='app-button'><ImportOutlined /> {t("components.tab-mapping.room-config.button-import")}</Button>
                        </Col>
                    </Row>
                </Col>

                <Col xs={24} md={24}>
                    <Table
                        className='table'
                        columns={columns}
                        dataSource={data}
                        loading={loading}  
                        scroll={{ x: 'max-content' }} />
                </Col>
            </Row>

        </div>
    );
};



export default RoomConfig;