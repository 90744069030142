import React, { useState } from 'react';
import moment from 'moment';
import './CancellationPoliciesComponent.scss';
import { useTranslation } from 'react-i18next';
import HTMLReactParser from 'html-react-parser';
import { useAppConfig } from '../../Contexts/AppConfigContext';
import { GetShowMoreIcon } from '../../utils/icons';
import { CancellationPolicy, PaymentTypeEnum } from '../../models/BookingData';

interface CancellationPoliciesComponentProps {
  cancellationPolicies: CancellationPolicy[];
  currencyCode: string;
}

const CancellationPoliciesComponent = (props: CancellationPoliciesComponentProps): JSX.Element => {
  const appConfig = useAppConfig();
  const { t } = useTranslation();
  const { cancellationPolicies, currencyCode } = props;
  const [visible, setVisible] = useState<boolean>(false);

  const toggleVisibility = () => {
    setVisible(!visible);
  };

  const getCancellationPolicyInfo = (
    cancellationPolicy: CancellationPolicy,
    index: number,
    totalPolicies: number
  ) => {
    const to = index === totalPolicies - 1
      ? null // Última política, sin límite "to" (es indefinido o hasta el check-in)
      : moment(cancellationPolicy.deadline).subtract(1, 'day').endOf('day');

    // Agregar un segundo al "to" de la política anterior para obtener el "from"
    const from = index === 0
      ? null // No hay política anterior, el "from" es indefinido
      : moment(cancellationPolicies[index - 1].deadline).endOf('day').add(1, 'second'); // +1 segundo

    // Formateo de fechas
    const fromFormatted = from ? moment(from).format(`${appConfig.config.dateFormat} HH:mm:ss`) : t('pages.booking-detail.cancellation-policy.from-checkin');
    const toFormatted = to ? moment(to).format(`${appConfig.config.dateFormat} HH:mm:ss`) : t('pages.booking-detail.cancellation-policy.to-checkin');

    // Mostrar las dos partes por separado, "desde" (from) y "hasta" (to)
    return (
      <span className="cancellation-policy-line" key={index}>
        {from && HTMLReactParser(
          t('pages.booking-detail.cancellation-policy.from', [
            fromFormatted
          ])
        )}

        {from && to && ' '} {/* Agrega un espacio si existen tanto "from" como "to" */}

        {to && HTMLReactParser(
          t('pages.booking-detail.cancellation-policy.to', [
            toFormatted
          ])
        )}

        {/* Mostrar la penalización */}
        {cancellationPolicy.penaltyType === PaymentTypeEnum.percent
          ? ` - ${cancellationPolicy.penalty}%`
          : ` - ${cancellationPolicy.penalty} ${currencyCode}`}
      </span>
    );
  };

  return (
    <div className="cancellation-policies-component">
      <div className="cancellation-policies" onClick={toggleVisibility}>
        {GetShowMoreIcon('plus-icon')} {t('pages.booking-detail.cancellation-policies')}
      </div>
      <ul className={`cancellation-policies-list ${visible ? 'visible' : 'hidden'}`}>
        {cancellationPolicies.map((cancellationPolicy, index) => (
          <li key={index}>
            {getCancellationPolicyInfo(cancellationPolicy, index, cancellationPolicies.length)}
          </li>
        ))}
      </ul>
    </div>
  );
};
export default CancellationPoliciesComponent;