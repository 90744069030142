import { useTranslation } from "react-i18next";
import { useAppConfig } from "../../../Contexts/AppConfigContext";
import { useEffect, useState } from "react";

import './LoginPage.scss'

import defaultBackgroundImage from '../../../assets/images/default-login-background.png'
import { ForgetPassword, LoginForm } from "../../../components";
import { useDoLogin, useRecoverPassword } from "../../../api/repositoryEP";
import ApiResponse from "../../../models/api/ApiResponse";
import UserSessionData from "../../../models/UserSessionData";
import { GetFormattedUrl } from "../../../utils/urls";
import { Modal, notification } from "antd";
import HTMLReactParser from "html-react-parser";
import { LanguageSelectorInLine } from "../../../components/common";
import moment from "moment";

const LoginPage = (): JSX.Element => {
    const appConfig = useAppConfig();

    const doLogin = useDoLogin;
    const recoverPassword = useRecoverPassword;

    const [backgroundPage, setBackgroundPage] = useState<string>(defaultBackgroundImage);

    const { t } = useTranslation();

    const [doingLogin, setDoingLogin] = useState<boolean>(false);
    const onLogin = (username: string, password: string): void => {

        setDoingLogin(true);
        doLogin(username, password).then((r: ApiResponse | null) => {
            if (r && r.code === 200) {
                var userSessionData = r.data as UserSessionData;
                if (userSessionData && userSessionData.isLogged) {
                    window.sessionStorage.setItem("user-data", JSON.stringify(userSessionData));

                    const url = GetFormattedUrl('private/dashboard');

                    window.location.href = url;
                }
            }
        })
            .finally(() => {
                setDoingLogin(false);
            });
    }

    const [showForgetPassword, setShowForgetPassword] = useState<boolean>(false);
    const onForgetPassword = (): void => {
        setShowForgetPassword(true);
    }

    const closeForgetPassword = (): void => {
        setShowForgetPassword(false);
    }

    const [sendingForgetPasswordEmail, sendSendingForgetPasswordEmail] = useState<boolean>(false);
    const submitForgetPasswordEmail = (email: string): void => {
        sendSendingForgetPasswordEmail(true);
        recoverPassword(email).then((r: ApiResponse | null) => {
            if (r && r.code === 200) {
                notification.success({
                    message: t("pages.login-page.forget-password-email-sent-title"),
                    description: HTMLReactParser(t("pages.login-page.forget-password-email-sent-description", [email]))
                });
                closeForgetPassword();
            }
        })
            .finally(() => {
                sendSendingForgetPasswordEmail(false);
            })
    }

    useEffect(() => {
        if (appConfig && appConfig.config.images && appConfig.config.images.loginBackGround && appConfig.config.images.loginBackGround.length > 0) {
            setBackgroundPage(appConfig.config.images.loginBackGround);
        }
    }, [])

    return (
        <div className="login-page">
            <Modal className="app-modal" title={t("pages.login-page.modal-title")} open={showForgetPassword} footer={null} onCancel={closeForgetPassword}>
                <ForgetPassword onCancel={closeForgetPassword} onSendForm={submitForgetPasswordEmail} loading={sendingForgetPasswordEmail} />
            </Modal>
            <div className="form-content">
                <LanguageSelectorInLine />
                <img className="app-logo" src='/images/beds2b-pro-logo.png' />
                <LoginForm onLogin={onLogin} doingLogin={doingLogin} onForgetPassword={onForgetPassword} />
            </div>
            <div className="footer-content">
                <p>
                    {HTMLReactParser(t("pages.login-page.footer-text", [GetFormattedUrl('privacy-policy'), GetFormattedUrl('terms-of-use')]))}
                </p>
                <small>
                    {t("pages.login-page.footer-text-2", [moment().format("YYYY")])}
                </small>
            </div>
        </div>
    )
}

export default LoginPage;