import { Pagination } from 'antd';
import './PaginationComponent.scss'
import { useTranslation } from 'react-i18next';

interface PaginationComponentProps {
    totalElements: number;
    actualPage: number;
    actualPageSize: number;
    onChange?: (page: number, pageSize?: number | undefined) => void;
}

const PaginationComponent = (props: PaginationComponentProps) : JSX.Element => {

    const {t} = useTranslation();

    const { totalElements, actualPage, actualPageSize } = props;

    const changePaginationData = (page: number, pageSize?: number | undefined) => {
        console.log("page", page);
        console.log("pageSize", pageSize);
        props.onChange!(page, pageSize);
    }

    return(
        <div className="pagination-component">
            <div className="pagination-resume">
                <span>{t("pagination.total-items", [totalElements])} </span> 
            </div>
            <div className="pagination-antd">
                <Pagination 
                    pageSizeOptions={[1, 2, 6,10,20]} 
                    defaultCurrent={actualPage ?? 1} 
                    total={totalElements} 
                    onChange={changePaginationData} 
                    showSizeChanger 
                    defaultPageSize={actualPageSize} 
                    locale={{
                        items_per_page: t("pagination.items-per-page")!,
                        page: t("pagination.page")!,
                        prev_page: t("pagination.prev-page")!,
                        next_page: t("pagination.next-page")!,
                        prev_5: t("pagination.prev-5")!,
                        next_5: t("pagination.next-5")!,
                        prev_3: t("pagination.prev-3")!,
                        next_3: t("pagination.next-3")!,
                }}/> 
            </div>
        </div>
    )
}

export default PaginationComponent;