import './NoDataComponent.scss';
import { Divider, Empty } from 'antd';
import { useTranslation } from 'react-i18next';

interface NoDataComponentProps {}

const NoDataComponent = (props: NoDataComponentProps) : JSX.Element => {
    const { t } = useTranslation();

    return(
        <div className="no-data">
            <Divider />
               <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('components.no-data.title')}/>
           <Divider />
        </div>
    )
}

export default NoDataComponent;