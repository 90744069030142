import { Button} from 'antd';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { Modal } from 'antd';
import { deleteBudgetGroupForm, deleteFieldRegisterForm, deleteHotelsConfigMapping ,deleteRegimesConfigMapping, deleteRoomConfigMapping, deleteServiceConfigMapping } from '../../../../../../api/repositoryEP';
import { notification } from 'antd';

import './ModalGeneric.scss'
import { LoadingOutlined } from '@ant-design/icons';


interface ModalGenericProps {
    modalVisible: boolean;
    setModalVisible: (visible: boolean) => void;
    id: string ,
    action : string,
    data : any,
    setData : (d : any) => void
}

const ModalGeneric = (props: ModalGenericProps): JSX.Element => {
    const { t } = useTranslation();
    const [msg,setMsg] = useState<string | null>("");
    const [loading,setLoading] = useState<boolean>(false);

    useEffect(() => {
        switch (props.action) {
            case "deleteHotel":
                setMsg(t("components.tab-mapping.hotels-config.modal-msg"));
                break;
            case "deleteRegimen":
                setMsg(t("components.tab-mapping.regimes-config.modal-msg"));
                break;
            case "deleteRoom":
                setMsg(t("components.tab-mapping.room-config.modal-msg"));
                break;
            case "deleteService":
                setMsg(t("components.tab-mapping.services-config.modal-msg"));
                break;
            case 'deleteBudget':
                setMsg(t("components.tab-configuration.group-form.delete-budget-msg"));
                break;
            case 'deleteFieldForm' :
                setMsg(t("components.tab-configuration.register-form.modal-delete.description"));
                break;
        }
    },[props.action]);



    const showErrorMessage = () => {
        notification.error({
            message: t('actions.error-message'),
            description: t('actions.error-description')
        });
    }

    const deleteHotel = () => {
        deleteHotelsConfigMapping(props.id).then((r: any) => {
            setLoading(false);
            cleanAndClose();
        })
    }

    const deleteRegime = () => {
        deleteRegimesConfigMapping(props.id).then((r: any) => {
            setLoading(false);
            cleanAndClose();
        })
        
    }

    const deleteRoom = () => {
        deleteRoomConfigMapping(props.id).then((r: any) => {
            setLoading(false);
            cleanAndClose();
        })
        
    }

    const deleteService = () => {
        deleteServiceConfigMapping(props.id).then((r: any) => {
            setLoading(false)
            cleanAndClose();
        });
    }

    const deleteBudget = () => {
        const  id = props.data[0].id 
        deleteBudgetGroupForm(id).then((r: any) => {
            setLoading(false);
            cleanAndClose();

        })    
        
    }

    const deleteFieldForm = () => {
        deleteFieldRegisterForm(props.id).then((r) => {
            setLoading(false);
            cleanAndClose();

        })
    }



    const handleModalDelete = () => {
        if(props.id != undefined){
            setLoading(true)
            switch (props.action ) {
                case "deleteHotel" : 
                    deleteHotel();
                    break;
                case "deleteRegimen" :
                    deleteRegime();
                    break;
                case "deleteRoom" :
                    deleteRoom();
                    break;    
                case "deleteService" :
                    deleteService();
                    break;   
                case "deleteBudget" :
                    deleteBudget();
                    break; 
                case  "deleteFieldForm" :
                    deleteFieldForm();
                    break;  
            }
        }else {
            showErrorMessage();
            cleanAndClose();
        }
    }

    const cleanAndClose = () => 
    {
    
        props.setData(null)
        props.setModalVisible(false);

    }


    return (
        <div className="modal-generic">
            <Modal
                title={t("components.tab-configuration.register-form.modal-delete.title")}
                open={props.modalVisible}
                footer={null}
                onCancel={() => {  props.setModalVisible(false) }}
                className='box-modal'
            >
                <div className='body-modal'>
                    {msg}
                </div>
                <div >
                    <Button
                        id="btn-delete"
                        className="delete-button"
                        type="primary"
                        htmlType="button"
                        disabled={loading}
                        onClick={handleModalDelete}
                        style={{ width: '100%', backgroundColor: "#F78C75", marginTop: "30px" }}
                    >
                           {loading ? <LoadingOutlined /> : ''}
                        {t("components.tab-configuration.register-form.modal-delete.btn-delete")}
                    </Button>
                </div>
            </Modal>
        </div>
    );
}

export default ModalGeneric;