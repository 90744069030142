import { UserData } from "../models/UserData";

export const userDataList: UserData[] = [
    {
      id: "1234",
      username: "user1",
      creationDate: new Date("2023-01-01"),
      languaje: "en",
      role: "admin",
      isActive: false,
      name: 'John',
      surname: 'Doe',
      secondSurname: 'Smith',
  
    
        phone: "(+34) 123 45 96 87",
        email: "user1@email.es",
        confirmNeeded : false
      
    },
    {
      id: "1235",
      username: "user2",
      creationDate: new Date("2023-01-01"),
      languaje: "es",
      role: "admin",
      isActive: true,
      name: 'John',
      surname: 'Doe',
      secondSurname: 'Smith',
     
        phone: "(+34) 123 45 96 87",
        email: "email@email.es",
        confirmNeeded : false
      
    },
    {
      id: "1236",
      username: "user3",
      creationDate: new Date("2023-01-01"),
      languaje: "es",
      role: "admin",
      isActive: true,
  
      name: 'John',
      surname: 'Doe',
      secondSurname: 'Smith',
    
        phone: "(+34) 123 45 96 67",
        email: "user3@email.es",
        confirmNeeded : false
      
    },
    {
      id: "1237",
      username: "user4",
      creationDate: new Date("2023-01-01"),
      languaje: "es",
      role: "admin",
      isActive: true,
      name: 'John',
      surname: 'Doe',
      secondSurname: 'Smith',
  
  
        phone: "(+34) 123 45 96 87",
        email: "user4@email.es",
        confirmNeeded : false
      
    },
  ]