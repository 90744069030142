import EndPointConfig from '../../models/api/EndPointConfig'

const controller = 'Rooms'

export const RoomsEP : EndPointConfig[] =[
    new EndPointConfig()
    .setMethod("GET")
    .setController(controller)
    .setActionName("GetRoomConfigMapping"), 
    new EndPointConfig()
    .setMethod("DELETE")
    .setController(controller)
    .setActionName("DeleteRoomConfigMapping"),
    new EndPointConfig()
    .setMethod("POST")
    .setController(controller)
    .setActionName("CreateRoomConfigMapping"),  
    new EndPointConfig()
    .setMethod("POST")
    .setController(controller)
    .setActionName("EditRoomConfigMapping"),
    new EndPointConfig()
    .setMethod("GET")
    .setController(controller)
    .setActionName("GetHotelRoomsConfigMapping"),   
]