import { AdministrationAccountEP } from './AdministrationAccountEP';
import { AppCustomDataEP } from './AppCustomDataEP'
import { BookingsEP } from './BookingsEP';
import { ConfigurationEP } from './ConfigurationEP';
import { ErrorNotificationsEP } from './ErrorNotificationsEP';
import { HotelsEP } from './HotelsEP';
import { LanguagesEP } from './LanguagesEP';
import { RegimesEP } from './RegimesEP';
import { RoomsEP } from './RoomsEP';
import { ServicesEP } from './ServicesEP';
import { UsersEP } from './UsersEP';

export const RegisterEndPoints = []
    .concat(AdministrationAccountEP)
    .concat(AppCustomDataEP)
    .concat(BookingsEP)
    .concat(ConfigurationEP)
    .concat(ErrorNotificationsEP)
    .concat(HotelsEP)
    .concat(LanguagesEP)
    .concat(RegimesEP)
    .concat(RoomsEP)
    .concat(ServicesEP)
    .concat(UsersEP)
    ;
