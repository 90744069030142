
import { useEffect, useState } from "react";
import { Form, Row, Col, Button } from "antd";
import { Input, Checkbox } from "antd";
import { notification } from 'antd';
import { useTranslation } from "react-i18next";
import { TranslationData } from "../../../../../models/MappingData";
import { BudgetData, CreateBudgetDto, EditBudgetDto } from "../../../../../models/ConfigurationData";
import { createBudgetGroupForm, editBudgetGroupForm, fetchLanguagesAvailables } from "../../../../../api/repositoryEP";
import { LoadingOutlined } from "@ant-design/icons";


interface BudgetFormProps {
    drawerVisible: boolean;
    setDrawerVisible: (visible: boolean) => void;
    budgetData: BudgetData[] | null;
    setBudgetData: (budgetData: BudgetData[] | null) => void;
}


const BudgetForm = (props: BudgetFormProps): JSX.Element => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [budgets, setBudgets] = useState<Array<BudgetData>>([]);
    const isArray = Array.isArray(budgets);
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingButton, setLoadingButton] = useState<boolean>(false);



    const showErrorMessage = () => {
        notification.error({
            message: t('actions.error-message'),
            description: t('actions.error-description')
        });
    }
    const showSuccessNotification = () => {
        notification.success({
            message: t('actions.success-message'),
            description: t('actions.success-description')
        });
    }


    const closeDrawer = () => {
        props.setBudgetData(null)
        setBudgets([]);
        form.resetFields();
        props.setDrawerVisible(false)
    }


    const modifyBudgetName = (index: number, value: string) => {
        const newBudgets = [...budgets];
        newBudgets[index].name = value;
        setBudgets(newBudgets);
    }


    const handleBtnCancel = () => {
        closeDrawer();
    }


    useEffect(() => {
        if (props.drawerVisible) {
           
            if (props.budgetData) {
                setBudgets(props.budgetData);
            } else { // Crear un nuevo presupuesto
                setLoading(true)
                fetchLanguagesAvailables("wa").then((r) => {
                    if (r?.type === 1) {
                        const initialBudgets: BudgetData[] = r.data.map((lang: string) => ({
                            language: lang,
                            name: '',
                            id: '', // Puede que se necesite id vacío aquí para la creación
                        }));
                        setBudgets(initialBudgets); // Establecer el estado inicial de budgets
                    } else {
                        props.setDrawerVisible(false);
                    }
                    setLoading(false)
                });
            }
        }
    }, [props.drawerVisible]);
    



    const onFinish = (values: any) => {
        if (props.budgetData === null) { // CREATE
            // Mapeo de budgets a TranslationData[]
            const translations: TranslationData[] = budgets.map((item) => ({
                language: item.language,
                value: item.name,
            }));
    
            const createBudgetDto: CreateBudgetDto = {
                names: translations,
            };   
        
            setLoadingButton(true);
            createBudgetGroupForm(createBudgetDto).then((response) => {
                if (response?.type === 1) {
                    closeDrawer();
                } 
                setLoadingButton(false);
             
            });
        } else { // EDIT
            setLoadingButton(true);
            const d : EditBudgetDto = {names : budgets}
            editBudgetGroupForm(d).then((response) => {
                if (response?.type === 1) {
                    closeDrawer();
                } 
                setLoadingButton(false);              
            });
        }
    };
    


    return (
        <div className='room-form-component'>
               {loading ? (
                <div style={{ textAlign: 'center', padding: '20px' }}>
                    <LoadingOutlined style={{ fontSize: 24 }} spin />
                    <p>{t("components.loading-text")}</p>
                </div>
            ) : (
            <Form form={form}
                onFinish={onFinish}
                layout="vertical"
            >
                <Row gutter={16}>
                    <Col xs={24} md={24}>
                        <Form.Item
                            label={t("components.tab-configuration.group-form.fields.budget-name")}
                            required
                            rules={[
                                {
                                    validator: (_, value) =>
                                        budgets.length === 0 || budgets.some(option => option.name === '')
                                            ? Promise.reject((t("components.tab-mapping.room-config.drawer.name-required") ?? ""))
                                            : Promise.resolve(),
                                },
                            ]}
                        >
                            {(() => {
                                const elements: JSX.Element[] = [];
                                if (isArray) {
                                    budgets.forEach((item, optIndex) => {
                                        elements.push(
                                            <div key={item.id + item.language} style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}>
                                                <span>{item.language.toUpperCase().substring(0,2)}</span>
                                                <Input
                                                    value={item.name}
                                                    onChange={(e) => modifyBudgetName(optIndex, e.target.value)}
                                                    style={{ marginLeft: 8 }}
                                                />
                                            </div>
                                        );
                                    });
                                }
                                return elements;
                            })()}

                        </Form.Item>
                    </Col>

                    <Col xs={24} md={12}>
                        <Form.Item style={{ textAlign: 'center', marginTop: 20 }}>
                            <Button className="app-button" style={{ width: "70%" }} onClick={handleBtnCancel}>
                                {t("components.button.cancel")}
                            </Button>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item style={{ textAlign: 'center', marginTop: 20 }}>
                        <Button className="app-button" style={{ width: "70%" }} htmlType="submit" disabled={loadingButton}>
                                {loadingButton ? <LoadingOutlined /> : t("components.button.save")}
                            </Button>
                        </Form.Item>
                    </Col>

                </Row>
            </Form>
            )}
        </div>
    );
};

export default BudgetForm