import { useTranslation } from "react-i18next";
import { Checkbox } from "antd";
import { ColorPicker } from 'primereact/colorpicker';
import { Row, Col } from "antd";
import { useEffect, useState } from "react";
import { Radio } from "antd";
import { fetchClientPROPersonalitationData, editPROConfiguration } from "../../../../api/repositoryEP";
import { Button } from "antd";
import { EditPROPersonalitationDto } from "../../../../models/ConfigurationData";
import './ProConfiguration.scss';
import { LoadingOutlined } from "@ant-design/icons";
import { LoadingData } from "../../../common";

interface ProConfigurationInterface {
    languages: string[];
}

const ProConfiguration = (props: ProConfigurationInterface) => {
    const { t } = useTranslation();

    const [mainColorWeb, setMainColorWeb] = useState("");
    const [secondaryColorWeb, setSecondaryColorWeb] = useState("");
    const [mainColorWidget, setMainColorWidget] = useState("");
    const [secondaryColorWidget, setSecondaryColorWidget] = useState("");
    const [useDefaultTemplate, setUseDefaultTemplate] = useState(false);
    const [model, setModel] = useState(0);
    const [loading, setLoading] = useState<boolean>(false)
    const [loadingButton, setLoadingButton] = useState<boolean>(false)



    useEffect(() => {
        setLoading(true)
        const fetchData = () => {
            fetchClientPROPersonalitationData()
                .then(response => {
                    setUseDefaultTemplate(response?.data.useDefaultTemplate);
                    setMainColorWeb(response?.data.webMainColor);
                    setMainColorWidget(response?.data.widgetMainColor);
                    setSecondaryColorWeb(response?.data.webSecondaryColor);
                    setSecondaryColorWidget(response?.data.widgetSecondaryColor);
                    setModel(response?.data.defaultTemplate);
                    setLoading(false)
                })
        };

        fetchData();
    }, []);



    const handleMainColorWeb = (e: any) => {
        setMainColorWeb(e.target.value);
    }


    const handleSecondaryColorWeb = (e: any) => {
        setSecondaryColorWeb(e.target.value);
    }


    const handleMainColorWidget = (e: any) => {
        setMainColorWidget(e.target.value);
    }

    const handleSecondaryColorWidget = (e: any) => {
        setSecondaryColorWidget(e.target.value);
    }

    const handleUseDefaultTemplate = (e: any) => {
        setUseDefaultTemplate(e.target.checked);
    };





    const saveColors = () => {
        setLoadingButton(true)

        const dto: EditPROPersonalitationDto = {
            template: model,
            mainColorWeb: mainColorWeb.startsWith("#") ? mainColorWeb : `#${mainColorWeb}`,  // Asegurarse de que los colores tienen el formato correcto
            SecondaryColorWeb: secondaryColorWeb.startsWith("#") ? secondaryColorWeb : `#${secondaryColorWeb}`,
            MainColorWidget: mainColorWidget.startsWith("#") ? mainColorWidget : `#${mainColorWidget}`,
            SecondaryColorWidget: secondaryColorWidget.startsWith("#") ? secondaryColorWidget : `#${secondaryColorWidget}`,
            useDefaultTemplate: !!useDefaultTemplate // Convertir a booleano si es necesario
        }
        console.log(dto)

        editPROConfiguration(dto)
            .then(response => {
                const scrollTopElement = document.getElementById("content-page")
                if (scrollTopElement) {
                    scrollTopElement.scrollTop = 0
                }
                setLoadingButton(false)
            })
    }


    const onChangeModel = (e: any) => {
        const value = parseInt(e.target.value, 10); // Convierte el valor a un entero
        if (!isNaN(value)) { // Verifica si la conversión fue exitosa
            setModel(value);
        }

    }

    return (
        <div className="pro-configuration-component">
            {loading ? (<LoadingData />) : (
                <>
                    <Row gutter={0} style={{ flexDirection: 'column' }}>

                        <h2 className="app-title medium no-bordered">
                            {t("components.pro-configuration.title-colors")}
                        </h2>
                        
                        <Col xs={24} md={24}>
                            <Row gutter={4} style={{ flexDirection: 'row', marginBottom: "0px" }}>
                                <Col xs={12} md={6}>
                                    <span>
                                        <div className="text">
                                            {t("components.pro-configuration.main-color-web")}
                                            <ColorPicker
                                                value={mainColorWeb}
                                                onChange={handleMainColorWeb}
                                                format="hex"
                                            /> 
                                        </div>
                                    </span>
                                </Col>
                                <Col xs={12} md={6}>
                                    <span>
                                        <div className="text">
                                            {t("components.pro-configuration.secondary-color-web")}
                                            <ColorPicker
                                                value={secondaryColorWeb}
                                                onChange={handleSecondaryColorWeb}
                                                format="hex"
                                            />
                                        </div>
                                    </span>
                                </Col>

                                <Col xs={12} md={6}>
                                    <span>
                                        <div className="text">
                                            {t("components.pro-configuration.main-color-widget")}
                                            <ColorPicker
                                                value={mainColorWidget}
                                                onChange={handleMainColorWidget}
                                                format="hex"
                                            />
                                        </div>
                                    </span>
                                </Col>
                                <Col xs={12} md={6}>
                                    <span>
                                        <div className="text">
                                            {t("components.pro-configuration.secondary-color-widget")}
                                            <ColorPicker
                                                value={secondaryColorWidget}
                                                onChange={handleSecondaryColorWidget}
                                                format="hex"
                                            />
                                        </div>
                                    </span>
                                </Col>
                            </Row>
                        </Col>
                        <h2 className="app-title medium no-bordered">
                            {t("components.pro-configuration.title-designs")}
                        </h2>
                        <Col xs={24} md={24}>
                            <Checkbox
                                checked={useDefaultTemplate} // Asegúrate de utilizar `checked` en lugar de `value`
                                className="checkbox-container"
                                onChange={handleUseDefaultTemplate}
                            >
                                {t("components.pro-configuration.default-template")}
                            </Checkbox>
                        </Col>

                        <Col xs={24} md={24}>
                            <Radio.Group
                                onChange={onChangeModel}
                                value={model}
                                disabled={!useDefaultTemplate}
                            >
                                <Radio value={0}>
                                    <div className="radio-content">
                                        <span className="text">{t("components.pro-configuration.landing-1")}</span>
                                        <div className="image-container">
                                            <img className="image-config" src='/images/landing1.png' alt="Descripción de la imagen" />
                                        </div>
                                    </div>
                                </Radio>
                                {/*<Radio value={1}>
                                    <div className="radio-content">
                                        <span className="text">{t("components.pro-configuration.landing-2")}</span>
                                        <div className="image-container">
                                            <img className="image-config" src='/images/landing2.png' alt="Descripción de la imagen" />
                                        </div>
                                    </div>
                                </Radio>*/}
                            </Radio.Group>
                        </Col>

                    </Row>
                    <Col xs={24} md={24} className="button-container" >
                        <Button className="app-button button-large" onClick={saveColors} disabled={loadingButton}>
                            {loadingButton ? <LoadingOutlined /> : t("components.button.save")}
                        </Button>
                    </Col>
                </>)}
        </div>
    );
};

export default ProConfiguration;
