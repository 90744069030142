import EndPointConfig from '../../models/api/EndPointConfig'

const controller = 'Users'

export const UsersEP : EndPointConfig[] =[
    

        new EndPointConfig()
        .setMethod("GET")
        .setController(controller)
        .setActionName("GetUserDataProfile"),  

        new EndPointConfig()
        .setMethod("GET")
        .setController(controller)
        .setActionName("CheckIsValidNewUsername"),   
      
        new EndPointConfig()
        .setMethod("POST")
        .setController(controller)
        .setActionName("EditUserDataProfile"),  
        
        new EndPointConfig()
        .setMethod("POST")
        .setController(controller)
        .setActionName("EditAccountStatus"),  

        new EndPointConfig()
        .setMethod("GET")
        .setController(controller)
        .setActionName("filter/BackOfficeUser"),  

        new EndPointConfig()
        .setMethod("POST")
        .setController(controller)
        .setActionName("create/BackOfficeUser"),  

        new EndPointConfig()
        .setMethod("POST")
        .setController(controller)
        .setActionName("edit/BackOfficeUser"), 
        
        new EndPointConfig()
        .setMethod("POST")
        .setController(controller)
        .setActionName("LoginFromBackOffice"),  

        new EndPointConfig()
        .setMethod("DELETE")
        .setController(controller)
        .setActionName("Logout"), 
             
]