import { createHotelsConfig, editHotelsConfig, fetchHotelsRegimens, fetchHotelsRooms } from "../../../../../../../api/repositoryEP";
import { Category, HotelRegimesData, HotelRoomsData, HotelsConfigCreateData, HotelsConfigEditData, RoomConfigCreateData, TranslationData } from "../../../../../../../models/MappingData";
import { useEffect, useState } from "react";
import { Form, Row, Col, Button, Switch, Select, InputNumber, Divider, Tabs, TabsProps } from "antd";
import { Input, Checkbox } from "antd";
import { useTranslation } from "react-i18next";
import { LoadingOutlined } from "@ant-design/icons";
import './HotelsForm.scss';
import { GetKeyIcon, GetStarIcon } from "../../../../../../../utils/icons";
import { LoadingData } from "../../../../../../common";

interface HotelFormProps {
    drawerVisible: boolean;
    setDrawerVisible: (visible: boolean) => void;
    hotelData: HotelsConfigEditData | null;
    setHotelData: (hotelData: HotelsConfigEditData | null) => void;
    language: string
}

const HotelForm = (props: HotelFormProps): JSX.Element => {
    const [loading, setLoading] = useState<boolean>(true);
    const [loadingSaveButton, setLoadingSaveButton] = useState<boolean>(false);
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [regimens, setRegimens] = useState<Array<HotelRegimesData>>([]);
    const [rooms, setRooms] = useState<Array<HotelRoomsData>>([]);
    const numberCategory = [1, 2, 3, 4, 5];
    const typeCategory = [
        { key: 'key', icon: GetKeyIcon("app-icon width-12px") },
        { key: 'star', icon: GetStarIcon("app-icon width-12px") },
    ];

    useEffect(() => {
        if (props.hotelData) {
            form.setFieldsValue({
                name: props.hotelData.name,
                code: props.hotelData.code,
                integrationCode: props.hotelData.integrationCode,
                longitude: props.hotelData.longitude,
                latitude: props.hotelData.latitude,
                locationCode: props.hotelData.locationCode,
                locationIntegrationCode: props.hotelData.locationIntegrationCode,
                countryCode: props.hotelData.countryCode,
                countryName: props.hotelData.countryName,
                destination: props.hotelData.destination,
                numRooms: props.hotelData.numberRooms,
                hotelPaxConfiguration: props.hotelData.hotelPaxConfiguration,
                active: props.hotelData.active,
                numberCategory: props.hotelData.category.number,
                typeCategory: props.hotelData.category.type,
                sync: props.hotelData.syncReward,
            });
        }
    }, [props.hotelData, form]);

    useEffect(() => {
        const fetchHotelRoomData = async (id: string) => {
            const roomsResponse = await fetchHotelsRooms(props.language, id);
            if (roomsResponse?.type === 1) {
                setRooms(roomsResponse.data.data as Array<HotelRoomsData>);
            } else {
                props.setDrawerVisible(false);
            }

        };

        const fetchRegimenRoomData = async (id: string) => {

            const regimensResponse = await fetchHotelsRegimens(props.language, id);
            if (regimensResponse?.type === 1) {
                setRegimens(regimensResponse.data.data as Array<HotelRegimesData>);
            } else {
                props.setDrawerVisible(false);
            }

        };

        if (props.drawerVisible) {
            setLoading(true);
            const id = props.hotelData?.id ? props.hotelData.id : '';
            Promise.all([fetchHotelRoomData(id), fetchRegimenRoomData(id)])
                .then(() => {
                    setLoading(false);
                })
                .catch(() => {
                    setLoading(false);
                });
        }
    }, [props.drawerVisible]);

    const handleRoomsCheckboxChange = (roomId: string, active: any) => {
        const updatedRooms = rooms.map(room => {
            if (room.id === roomId) {
                return { ...room, active: active };
            }
            return room;
        });
        setRooms(updatedRooms);
    };

    const handleRegimensCheckboxChange = (regimenId: string, active: any) => {
        const updatedRegimen = regimens.map(regimen => {
            if (regimen.id === regimenId) {
                return { ...regimen, active: active };
            }
            return regimen;
        });
        setRegimens(updatedRegimen);
    };


    const closeDrawer = () => {
        setRegimens([]);
        setRooms([]);
        props.setHotelData(null)
        form.resetFields();
        props.setDrawerVisible(false);
    };

    const handleBtnCancel = () => {
        closeDrawer();
    };

    const onFinish = (values: any) => {
        setLoadingSaveButton(true)

        if (props.hotelData === null) { // CREATE
            const cate: Category = { type: values.typeCategory, number: values.numberCategory };
            const h: HotelsConfigCreateData = {
                name: values.name,
                category: cate,

                code: values.code,
                locationCode: values.locationCode,

                integrationCode: values.integrationCode,
                locationIntegrationCode: values.locationIntegrationCode,
                
                destination: values.destination,
                countryCode: values.countryCode,
                countryName: values.countryName,
                longitude: values.longitude,
                latitude: values.latitude,
                numberRooms: values.numRooms,
                active: values.active,
                regimen: regimens,
                rooms: rooms,
                syncReward: values.sync,
                hotelPaxConfiguration: {
                    minAgeChild: values.minagechild,
                    maxAgeChild: values.maxagechild,
                    minAgeInfant: values.minageinfant,
                    maxAgeInfant: values.maxageinfant,
                    minAgeTeenager: values.minageteenager,
                    maxAgeTeenager: values.maxageteenager
                }
            };
            createHotelsConfig(h).then((response) => {
                if (response?.type == 1) {
                    closeDrawer();
                    setRegimens([]);
                    setRooms([]);
                }
                setLoadingSaveButton(false)
            });
        } else { // EDIT
            const categ: Category = { type: values.typeCategory, number: values.numberCategory };
            const h: HotelsConfigEditData = {
                id: props.hotelData.id,
                name: values.name,
                category: categ,

                code: values.code,
                locationCode: values.locationCode,

                integrationCode: values.integrationCode,
                locationIntegrationCode: values.locationIntegrationCode,

                destination: values.destination,
                countryCode: values.countryCode,
                countryName: values.countryName,
                latitude: values.latitude,
                longitude: values.longitude,
                numberRooms: values.numRooms,
                active: values.active,
                regimen: regimens,
                rooms: rooms,
                syncReward: values.sync,
                hotelPaxConfiguration: {
                    minAgeChild: values.minagechild,
                    maxAgeChild: values.maxagechild,
                    minAgeInfant: values.minageinfant,
                    maxAgeInfant: values.maxageinfant,
                    minAgeTeenager: values.minageteenager,
                    maxAgeTeenager: values.maxageteenager
                }
                
            };


            editHotelsConfig(h).then((response) => {
                console.log(response)
                if (response?.type == 1) {
                    closeDrawer();
                    setRegimens([]);
                    setRooms([]);
                }
                setLoadingSaveButton(false)

            });
        }


    };

    const mappingItems: TabsProps['items'] = [
        {
            key: '1',
            label: `${t("components.tab-mapping.title-pms")}`,
            children: <Row gutter={16}>
                        <Col xs={24} md={12}>
                            <Form.Item
                                name="code"
                                label={t("components.tab-mapping.hotels-config.drawer.code-in-pms")}
                                rules={[{ required: true, message: t("components.tab-mapping.hotels-config.drawer.code-required") ?? "Code is required" }]}
                                initialValue={props.hotelData?.code}
                            >
                                <Input className="app-input" />
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={12}>
                            <Form.Item
                                name="locationCode"
                                label={t("components.tab-mapping.hotels-config.drawer.location-code-in-pms")}
                                rules={[{ required: true, message: t("components.tab-mapping.hotels-config.drawer.locationCode-required") ?? "" }]}
                                initialValue={props.hotelData?.latitude}
                            >

                                <Input className="app-input" />
                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                            <small>
                                {t("components.tab-mapping.pms-codes-explanation")}
                            </small>
                        </Col>
                    </Row>,
        },
        {
            key: '2',
            label:  `${t("components.tab-mapping.title-integrations")}`,
            children: <Row gutter={16}>
                        <Col xs={24} md={12}>
                            <Form.Item
                                name="integrationCode"
                                label={t("components.tab-mapping.hotels-config.drawer.code-in-integrations")}
                                rules={[{ required: true, message: t("components.tab-mapping.hotels-config.drawer.code-required") ?? "Code is required" }]}
                                initialValue={props.hotelData?.code}
                            >
                                <Input className="app-input" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item
                                name="locationIntegrationCode"
                                label={t("components.tab-mapping.hotels-config.drawer.location-code-in-integrations")}
                                rules={[{ required: true, message: t("components.tab-mapping.hotels-config.drawer.locationCode-required") ?? "" }]}
                                initialValue={props.hotelData?.locationCode}
                            >

                                <Input className="app-input" />
                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                            <small>
                                {t("components.tab-mapping.integration-codes-explanation")}
                            </small>
                        </Col>
                    </Row>,
        },
        {
            key: '3',
            label:  `${t("components.tab-mapping.title-pax-configuration")}`,
            children: <Row gutter={16}>
                        <Col xs={24} md={12}>
                            <Form.Item
                                name="minageinfant"
                                label={t("components.tab-mapping.min-age-infant")}
                                initialValue={props.hotelData?.hotelPaxConfiguration.minAgeInfant}
                            >
                                <Input className="app-input" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item
                                name="maxageinfant"
                                label={t("components.tab-mapping.max-age-infant")}
                                initialValue={props.hotelData?.hotelPaxConfiguration.maxAgeInfant}
                            >

                                <Input className="app-input" />
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={12}>
                            <Form.Item
                                name="minagechild"
                                label={t("components.tab-mapping.min-age-child")}
                                initialValue={props.hotelData?.hotelPaxConfiguration.minAgeChild}
                            >
                                <Input className="app-input" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item
                                name="maxagechild"
                                label={t("components.tab-mapping.max-age-child")}
                                initialValue={props.hotelData?.hotelPaxConfiguration.maxAgeChild}
                            >

                                <Input className="app-input" />
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={12}>
                            <Form.Item
                                name="minageteenager"
                                label={t("components.tab-mapping.min-age-teenager")}
                                initialValue={props.hotelData?.hotelPaxConfiguration.minAgeTeenager}
                            >
                                <Input className="app-input" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item
                                name="maxageteenager"
                                label={t("components.tab-mapping.max-age-teenager")}
                                initialValue={props.hotelData?.hotelPaxConfiguration.maxAgeTeenager}
                            >

                                <Input className="app-input" />
                            </Form.Item>
                        </Col>

                        
                        <Col xs={24}>
                            <small>
                                {t("components.tab-mapping.pax-configuration-explanation")}
                            </small>
                        </Col>
                    </Row>
        }
    ];

    return (
        <div className='hotels-form-component'>
            {loading ? (
                <div style={{ textAlign: 'center', padding: '20px' }}>
                    <LoadingData />
                </div>
            ) : (
                <Form form={form} onFinish={onFinish} layout="vertical">
                    <Row gutter={16}>
                        
                        <Col xs={24} md={24}>
                            <Form.Item
                                name="active"
                                label={t("components.tab-mapping.hotels-config.drawer.active")}
                                initialValue={props.hotelData?.active}
                                className="switch-container"
                            >
                                <Switch />
                            </Form.Item>
                        </Col>
                        <Divider>
                            {t("components.tab-mapping.hotels-config.drawer.title-basic-info")}
                        </Divider>
                        <Col xs={24} md={24}>
                            <Form.Item
                                name="name"
                                label={t("components.tab-mapping.hotels-config.drawer.name")}
                                rules={[{ required: true, message: t("components.tab-mapping.hotels-config.drawer.name-required") ?? "Name is required" }]}
                                initialValue={props.hotelData?.name}
                            >
                                <Input className="app-input" />
                            </Form.Item>
                        </Col>

                        
                        <Col xs={24} md={24}>
                            <Form.Item
                                name="countryName"
                                label={t("components.tab-mapping.hotels-config.drawer.country-name")}
                                initialValue={props.hotelData?.countryName}
                            >
                                <Input className="app-input" />
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={24}>
                            <Form.Item
                                name="countryCode"
                                label={t("components.tab-mapping.hotels-config.drawer.country-code")}
                                initialValue={props.hotelData?.countryCode}
                                rules={[{ required: true, message: t("forms.validations.required-field") ?? "Destination is required" }]}
                            >
                                <Input className="app-input" />
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={24}>
                            <Form.Item
                                name="destination"
                                label={t("components.tab-mapping.hotels-config.drawer.destination")}
                                rules={[{ required: true, message: t("components.tab-mapping.hotels-config.drawer.destination-required") ?? "Destination is required" }]}
                                initialValue={props.hotelData?.destination}
                            >
                                <Input className="app-input" />
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={12}>
                            <Form.Item
                                name="longitude"
                                label={t("components.tab-mapping.hotels-config.drawer.longitude")}
                                rules={[

                                    {
                                        pattern: new RegExp(/^-?\d*(\.\d+)?$/),
                                        message: String(t("components.tab-mapping.hotels-config.drawer.number-required")), // Mensaje si el valor no es un número
                                    }
                                ]}
                                initialValue={props.hotelData?.longitude}
                            >
                                <Input className="app-input" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item
                                name="latitude"
                                label={t("components.tab-mapping.hotels-config.drawer.latitude")}
                                rules={[

                                    {
                                        pattern: new RegExp(/^-?\d*(\.\d+)?$/),
                                        message: String(t("components.tab-mapping.hotels-config.drawer.number-required")), // Mensaje si el valor no es un número
                                    }
                                ]}
                                initialValue={props.hotelData?.latitude}
                            >

                                <Input className="app-input" step={0.01} />
                            </Form.Item>
                        </Col>



                        <Col xs={24} md={24}>
                            <Form.Item
                                name="numRooms"
                                label={t("components.tab-mapping.hotels-config.drawer.room-number")}
                                rules={[{ required: true, message: t("components.tab-mapping.hotels-config.drawer.room-number-required") ?? "Room number is required" }]}
                                initialValue={props.hotelData?.numberRooms}
                            >
                                <InputNumber className="app-input" />
                            </Form.Item>
                        </Col>

                        <Col xs={12} md={12}>
                            <Form.Item
                                name="numberCategory"
                                label={t("components.tab-mapping.hotels-config.drawer.category")}
                                initialValue={props.hotelData?.category.number}
                                className="select-container"
                                rules={[{ required: true, message: "" }]}

                            >
                                <Select>
                                    {numberCategory.map((value) => (
                                        <Select.Option key={value} value={value}>
                                            {value}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col xs={12} md={12}>
                            <Form.Item
                                name="typeCategory"
                                label=" "
                                initialValue={props.hotelData?.category.type || 'star'}
                                className="select-container"
                            >
                                <Select>
                                    {typeCategory.map((category) => (
                                        <Select.Option key={category.key} value={category.key}>
                                            {category.icon}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>


                        <Divider>
                            {t("components.tab-mapping.title-mapping-code")}
                        </Divider>
                        <Col xs={24} md={24}>
                            <Tabs defaultActiveKey="1" items={mappingItems} />
                        </Col>

                        <Divider>
                            {t("components.tab-mapping.hotels-config.drawer.title-configuration")}
                        </Divider>

                        <Col xs={12} md={12}>
                            <Form.Item
                                label={t("components.tab-mapping.hotels-config.drawer.rooms")}
                            >
                                <div className="multiple-checks-container scrollable">
                                    {rooms.map(room => (
                                        <Checkbox
                                            key={room.id}
                                            checked={room.active}
                                            className="notBold"
                                            onChange={(event) => handleRoomsCheckboxChange(room.id, event.target.checked)}
                                        >
                                            {room.name}
                                        </Checkbox>
                                    ))}
                                </div>
                            </Form.Item>
                        </Col>


                        <Col xs={12} md={12}>
                            <Form.Item
                                label={t("components.tab-mapping.hotels-config.drawer.regimes")}
                            >
                                <div className="multiple-checks-container scrollable">
                                    {regimens.map(regimen => (
                                        <Checkbox
                                            key={regimen.id}
                                            checked={regimen.active}
                                            className="notBold"
                                            onChange={(event) => handleRegimensCheckboxChange(regimen.id, event.target.checked)}
                                        >
                                            {regimen.name}
                                        </Checkbox>
                                    ))}
                                </div>
                            </Form.Item>
                        </Col>




                        <Col xs={24} md={24} >
                            <Form.Item
                                name="sync"
                                valuePropName="checked"
                                initialValue={props.hotelData?.syncReward ?? false}
                                className="checkbox-sync-container"
                            >
                                <Checkbox className="app-input" >{t("components.tab-mapping.hotels-config.drawer.checkbox")}</Checkbox>
                            </Form.Item>
                        </Col>


                        <Col xs={24} md={12}>
                            <Form.Item style={{ textAlign: 'center' }}>
                                <Button className="app-button" style={{ width: "70%" }} onClick={handleBtnCancel}>
                                    {t("components.button.cancel")}
                                </Button>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item style={{ textAlign: 'center' }}>
                                <Button className="app-button" style={{ width: "70%" }} disabled={loadingSaveButton} htmlType="submit">
                                    {loadingSaveButton ? <LoadingOutlined /> : t("components.button.save")}
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            )}
        </div>
    );
};


export default HotelForm;