import { notification } from 'antd';
import ApiResponse from '../models/api/ApiResponse';
import EndPointConfig from '../models/api/EndPointConfig';
import ParamsApi from '../models/api/ParamsApi';
import { RegisterEndPoints } from './endpoints';
import { GetLanguageInUrl, IsLaguagePresentInUrl } from '../utils/urls';

export default class RestApiClient{

    private redirectOnError : boolean = true;

 

    public fetch = (alias: string, params : ParamsApi, redirectOnError : boolean = true) : Promise<ApiResponse | null> => {
        this.redirectOnError = redirectOnError;
        let result = null;

        console.warn("RestAPIClient: ", params);

        const configEP: EndPointConfig = this.getEndPointInfo(alias);

        if (!configEP){
            notification.error({
                message: 'Error - Configuración EP no encontrada',
                description: 'Nada encontrado aquí'
            })

            return Promise.reject(null);
        }

        const actionURL = this.getUrlEndPoint(configEP, params);
        const init = this.generateInitFetch(configEP, params);


        try{
            result =  fetch(actionURL, init)
                .then(response => this.checkStatus(response))
                .then(response => response.json())
                .then(response => this.resolveResponse(response))
                .catch(error => {                 
                    if (error.status === 418){
                        //Logout, la sesión ha expirado
                        this.closeSession();
                        window.sessionStorage.setItem("session-expired", "true");
                        window.location.href = `${(IsLaguagePresentInUrl() ? GetLanguageInUrl() : '')}`;
                    }else if (error.status === 401){
                        //Logout, no está autorizado a realizar la operación que ha intentado realizar
                        this.closeSession();
                        window.sessionStorage.setItem("no-authorized", "true");
                        window.location.href = "/forbiden";
                    }else{                
                        //Se ha producido un error, mostramos ventana de error
                        if (this.redirectOnError){
                            window.sessionStorage.setItem("last-error", JSON.stringify({ message: encodeURIComponent(error.message), method: alias, parameters: encodeURIComponent(JSON.stringify(params)) }));
                            window.location.href = `${(IsLaguagePresentInUrl() ? GetLanguageInUrl() : '')}/error/api-call`;
                        }
                    }
                    return Promise.reject();
                })

        }catch(error : any){

            notification.error({
                message: 'Error - genérico',
                description: error.message
            })
        }

        return Promise.resolve(result)
    }

    public closeSession = () : void => {
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('user-data');
        window.location.href = "/";
    }

    private getAdditionalMessages = (messages : string[]) :JSX.Element => {
        let additionalMessages : any[] = [];
        if(messages === undefined || messages.length === 0){
            return <></>;
        }
        messages.map((msg: string) =>{
            additionalMessages.push(<li>{msg}</li>)
        })

        let finalMessage = additionalMessages ? <ul>
                                {additionalMessages}
                            </ul> : <></>;
        
        return finalMessage;
    } 

    private resolveResponse = (response: ApiResponse): Promise<ApiResponse> => {

        if (response.code === 401 || response.code === 418){
            this.closeSession();
            
        }

        const description =  <div className="">
                                    <span>{response.message}</span>
                                    {this.getAdditionalMessages(response.additionalMessages)}
                            </div>

        switch (response.code) {
            case 418:
            case 401:
                this.closeSession();
                
              break
            case 404:
                let finalMessage = response.message;
                
                response.additionalMessages.map((msg: string) =>{
                    finalMessage += msg;
                })
                notification.warning({
                    message: 'Atención',
                    description: description
                })
              break
            case 500:
              notification.error({
                message: 'Error',
                description: description,
              }) 
              break
            default:
              if (response.message !== undefined && response.message.length > 0) {
                notification.success({
                  message: 'Operación realizada',
                  description: description,
                })
              }
              break
          }

        if (
            response.data !== undefined && 
            response.data !== null && 
            response.data.sessionId !== undefined &&
            response.data.sessionId !== null
            ){
            // Workaround for test controller functions
            sessionStorage.setItem('token', response.data.sessionId);
            window.sessionStorage.removeItem("session-expired");
            window.sessionStorage.removeItem("no-authorized");

        }

        return Promise.resolve(response)
    }

    private checkStatus = (response: Response): Promise<Response> => {

        if (response.status === 401 || response.status === 418) {
            // No autorizado: Cerramos sesión
            this.closeSession();
          /*  notification.error({
                message: "Session closed",
                description: "Session closed",
            })*/
            return Promise.reject(response);
        }else if(response.status === 500){
           /* notification.error({
                message: 'Atención',
                description: 'Actualmente esta página no está disponible. Por favor, contacte con su club'
            })*/
        } 

        return Promise.resolve(response);
    }

    private getUrlEndPoint = (configEP: EndPointConfig, params: ParamsApi) => {
        let url = `${ process.env.REACT_APP_BASE_API }/${process.env.REACT_APP_API_VERSION}/${configEP.controller}`;
        
        if (configEP.usingActionName) {
            url += `/${configEP.actionName}`;
        }

        if (params && params.path) {
            // Si tengo params.pattern los seteo
            url = this.setPatternParams(params.path, configEP.pattern, url);
        }

        if (params && params.query) {
            // Si tengo params.url los seteo
            url = this.setUrlParams(params.query, url);
        }

        return url;
    }

    private setUrlParams =  (urlParams: any, url: string, useSeparator: boolean = true, urlBase: string = '') => {
        Object.keys(urlParams).map((v, i) => {
            const separator = useSeparator ? (i === 0 ? '?' : '&') : '';
    
            if (urlParams[v] instanceof Object) {
                const base = `${urlBase}${separator}${v}.`;
    
                url += this.setUrlParams(urlParams[v], '', false, base);
            } else {
                url += `${urlBase}${separator}${v}=${urlParams[v]}`;
            }

            return true;
        });
    
        return url;
    }

    private setPatternParams = (patternParams: any, pattern: string, url: string) => {

        let patternVal = pattern;
    
        Object.keys(patternParams).map((v, i) => {
            // v está definido como key en pattern
            if (patternVal.indexOf(v) !== -1) {
                // reemplazamos {v} por {patternParams[v]}
                patternVal = patternVal.replace(`{${v}}`, patternParams[v]);
                // pattern.replace(`{${v}}`, patternParams[v]);
            } else {
                // v NO está definido como key en pattern => PROBLEMA
                console.error("Pattern doesn't match with model");
            }

            return true;
        });
    
        return `${url}/${patternVal}`;
    }
    
    private generateInitFetch = (configEP: EndPointConfig, params: ParamsApi) => {
        return {
            method: configEP.method,
            headers: this.getHeaders(),
            body: params && JSON.stringify(params.body)
        };
    }

    private getHeaders = (): Headers => {
        const headers = new Headers();
    
        if (sessionStorage.getItem('token')){
            headers.append('X-Authorization', `bearer ${sessionStorage.getItem('token')!}`);
        }

        var actualLanguage = IsLaguagePresentInUrl() ? GetLanguageInUrl().substring(1) : "es";

        headers.append('X-user-app-language', actualLanguage);
        headers.append('Content-Type', 'application/json');
        headers.append('Access-Control-Allow-Origin', '*');
        headers.append('Authority', window.location.host);

    
        return headers;
    }

    private getEndPointInfo = (alias : string) : EndPointConfig => {
        let result : EndPointConfig = new EndPointConfig();

        const coincidences: EndPointConfig[] = RegisterEndPoints.filter((i: EndPointConfig) => {
            return (i.alias !== null && i.alias.toLowerCase() === alias.toLowerCase()) || (i.actionName !== null && i.actionName.toLowerCase() === alias.toLowerCase());
        })

        if (coincidences.length > 1){
            console.error(`Found multiple EndPoints with ${alias} alias/actionName.`)
        }else if (!coincidences || coincidences.length === 0){
            console.error(`Not found any EndPoints with ${alias} alias/actionName.`)
        }else if (coincidences && coincidences.length === 1){
            result = coincidences.pop()!;
        }

        return result;
    }
}