import { Alert, Col, Row, Tag, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import BookingData, { BookingPax, BookingRoom, Extra, PaxTypeEnum } from '../../../models/BookingData';
import { useEffect, useState } from 'react';
import moment from 'moment';
import HTMLReactParser from 'html-react-parser';


import './BookingDetailPage.scss'
import { CancellationPolicies, Occupation } from '../../../components';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useAppConfig } from '../../../Contexts/AppConfigContext';
import { LoadingData } from '../../../components/common';

interface BookingDetailPageProps {
    bookingData?: BookingData;
    isGettingBookingData: boolean;
}
const BookingDetailPage = (props: BookingDetailPageProps): JSX.Element => {


    const appConfig = useAppConfig();

    const { bookingData, isGettingBookingData } = props;

    const { t } = useTranslation();


    /**Añadimos un manejador para que cuando se pinche sobre un elemento con la clase resume-link se haga scrollo hasta el elemento con el atributo tag que contenga el nombre del id que tiene resume-link */
    useEffect(() => {
        const resumeLinks = document.querySelectorAll(".resume-link");

        resumeLinks.forEach((resumeLink: any) => {
            const handleClick = (event: MouseEvent) => {
                event.preventDefault();
                const tag = (event.currentTarget as HTMLAnchorElement)?.getAttribute(
                    "id"
                );
                const element = document.querySelector(`[data-tag='${tag}']`);
                if (element) {
                    element.scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                        inline: "nearest"
                    });
                }
            };

            resumeLink.addEventListener("click", handleClick);

            return () => {
                resumeLink.removeEventListener("click", handleClick);
            };
        });
    }, []);



    const getTotals = (bookingData: BookingData | undefined) => {
        if (!bookingData) return { pvp: 0, net: 0, commission: 0 };

        const totals = bookingData.bookingLines.reduce(
            (acc, room) => {
                acc.pvp += room.pvp;
                acc.net += room.net;
                acc.commission += room.commission;
                return acc;
            },
            { pvp: 0, net: 0, commission: 0 }
        );

        return totals;
    };

    const calculateBookingValues = (bookingData: BookingData | undefined) => {
        const isNetPrice = bookingData?.isNetPrice ?? false;

        const { pvp, net, commission } = getTotals(bookingData);

        return { net, pvp, commission, isNetPrice };
    };

    const { net, pvp, commission, isNetPrice } = calculateBookingValues(bookingData);

    const mapPaxType = (value: any): PaxTypeEnum => {
        switch (value) {
            case 0: return PaxTypeEnum.adult;
            case 1: return PaxTypeEnum.teenager;
            case 2: return PaxTypeEnum.child;
            case 3: return PaxTypeEnum.infant;
            default: return PaxTypeEnum.adult;
        }
    };

    return (
        <div className="booking-detail-page ">
            {
                isGettingBookingData ? <LoadingData /> : <></>
            }
            {
                !isGettingBookingData  && <div className="print-page">
                <div className="resume print-block">
                    <Row gutter={0} >
                        <Col xs={24}>
                            <span className="booking-status">
                                <Tag color={bookingData?.isCancelled ? 'red' : 'green'}>
                                    {
                                        bookingData?.isCancelled ? t('pages.booking-detail.booking-status.cancelled') : t('pages.booking-detail.booking-status.active')
                                    }
                                    {
                                        bookingData?.isCancelled ?
                                            <Tooltip title={t('pages.booking-detail.booking-status.cancelled-at', [bookingData.lastUpdateDate ? moment(bookingData.lastUpdateDate).format(appConfig.config.dateFormat) : 'N/D'])}>
                                                <InfoCircleOutlined className="icon-cancelled-at" />
                                            </Tooltip>
                                            :
                                            ''
                                    }

                                </Tag> 
                            </span>
                        </Col>
                        <Col xs={24} className="content">
                            <h3 className="hotel-name">
                                {bookingData?.hotelInfo.name}
                            </h3>
                            <p className="hotel-address">
                                {t('pages.booking-detail.hotel-address')}: {bookingData?.hotelInfo.address}&nbsp;&nbsp;&nbsp;&nbsp;{t('pages.booking-detail.hotel-phone')}: {bookingData?.hotelInfo.phone}
                            </p>
                            <p className="hotel-address">
                                {t('pages.booking-detail.hotel-website')}: <a target='_blank' className="app-link" href={`${bookingData?.hotelInfo.website}`}>{bookingData?.hotelInfo.website}</a>
                            </p>
                            <p className="hotel-address">
                                {t('pages.booking-detail.hotel-director')}: {bookingData?.hotelInfo.director}
                            </p>
                            <p className="booking-header-resume">
                                
                                <span className="pms-locator">
                                    {t('pages.booking-detail.locator')}: {bookingData?.pmsLocator}
                                </span>
                                <small>
                                    {t('pages.booking-detail.pro-locator')}: {bookingData?.proLocator}
                                </small>
                                <span>
                                    {t('pages.booking-detail.check-in')}:&nbsp;
                                    <>{ moment(bookingData?.bookingLines[0].checkIn).format(appConfig.config.dateFormat)}</>
                                </span>
                                <span>{t('pages.booking-detail.check-out')}:&nbsp;{
                                    <>{ moment(bookingData?.bookingLines[0].checkOut).format(appConfig.config.dateFormat)}</>
                                }</span>
                                <span>
                                    {
                                        HTMLReactParser(
                                            t('pages.booking-detail.room-occupancy', [
                                                //Total de habitaciones
                                                bookingData?.bookingLines.length,
                                                //Total adultos (aquellos que paxType == adult|teneerger) de todas las habitaciones
                                                bookingData?.bookingLines.reduce((acc, bookingLine) => acc + bookingLine.paxes.filter((pax) => mapPaxType(pax.paxType) === PaxTypeEnum.adult).length, 0),
                                                //Total niños (aquellos que paxType == child|infant) de todas las habitaciones
                                                bookingData?.bookingLines.reduce((acc, bookingLine) => acc + bookingLine.paxes.filter((pax) => mapPaxType(pax.paxType) === PaxTypeEnum.child || mapPaxType(pax.paxType) === PaxTypeEnum.infant || mapPaxType(pax.paxType) === PaxTypeEnum.teenager).length, 0),
                                                bookingData?.extras.length
                                            ])
                                        )
                                    }

                                    {
                                        //Pintamos un tag especial en función del estado de la reserva

                                    }
                                </span>
                            </p>
                        </Col>
                        <Col xs={24} className="content">
                            <div className="owner-data">
                                <h3 className="main-title">
                                    {
                                        t('pages.booking-detail.owner-data.title')
                                    }
                                </h3>
                                <p className="owner-name">
                                    {t('pages.booking-detail.owner-data.name')}: {bookingData?.ownerData.name} {bookingData?.ownerData.surname} {bookingData?.ownerData.secondSurname}
                                </p>
                                <p className="owner-phone">
                                    {t('pages.booking-detail.owner-data.phone')}: {bookingData?.ownerData.phone ?? '-'}
                                </p>
                                <p className="owner-phone">
                                    {t('pages.booking-detail.owner-data.email')}: {bookingData?.ownerData.email ? <a className='app-link' href={`mailto:${bookingData?.ownerData.email}`}>{bookingData?.ownerData.email}</a> : '-'}
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24} className="content">
                            <h3 className="main-title">
                                {
                                    t('pages.booking-detail.total-resume.title')
                                }
                            </h3>
                            <div className="total-resume">
                                <Row gutter={0}>
                                    <Col xs={24} className="total-pvp-price">
                                        {t('pages.booking-detail.total-resume.total-pvp')}: {pvp?.toFixed(2)} {bookingData?.currencyCode}
                                    </Col>
                                    <Col xs={24} className="total-commission-price">
                                        {t('pages.booking-detail.total-resume.total-commission')}: {commission?.toFixed(2)} {bookingData?.currencyCode}
                                    </Col>
                                    <Col xs={24} className="total-net-price">
                                        {t('pages.booking-detail.total-resume.total-net')}: {net?.toFixed(2)} {bookingData?.currencyCode}
                                    </Col>
                                    <Col xs={24} className="explain-if-net-or-pvp">
                                        <small>
                                            <Alert message={
                                                HTMLReactParser(
                                                    t('pages.booking-detail.total-resume.explain-if-net-or-pvp', [isNetPrice ? t('pages.booking-detail.total-resume.total-net') : t('pages.booking-detail.total-resume.total-pvp')])
                                                )
                                            } type="warning" />
                                            
                                        </small>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="rooms print-block" data-tag="rooms">
                    <Row>
                        <Col xs={24} className="content">
                            <h3 className="main-title">
                                {
                                    t('pages.booking-detail.rooms-title')
                                }
                            </h3>

                            {
                                bookingData?.bookingLines.map((bookingLine: BookingRoom, index: number) => {
                                    return (
                                        <div className="room">
                                            <Row gutter={0}>
                                                <Tooltip title={bookingLine.name}>
                                                    <Col xs={24} lg={4} className="room-image" style={{ backgroundImage: `url(${bookingLine.mainImage})` }}>
                                                    </Col>
                                                </Tooltip>
                                                <Col xs={24} lg={15} className="room-details">
                                                    <h4>{HTMLReactParser(t("pages.booking-detail.room-for", [
                                                        index + 1,
                                                        bookingLine.name,
                                                        bookingLine.paxes.filter((pax: BookingPax) => mapPaxType(pax.paxType) === PaxTypeEnum.adult).length,
                                                        bookingLine.paxes.filter((pax: BookingPax) => mapPaxType(pax.paxType) !== PaxTypeEnum.adult).length]))}</h4>
                                                    <p className="regime-name">{bookingLine.regimeName}</p>


                                                    <CancellationPolicies cancellationPolicies={bookingLine.cancellationPolicies} currencyCode={bookingData.currencyCode} />

                                                    <Occupation occupation={bookingLine.paxes} checkOut={bookingLine.checkOut} />

                                                </Col>
                                                <Col xs={24} lg={5} className="room-price">
                                                    <span>
                                                        {isNetPrice ?  bookingLine.net?.toFixed(2) : bookingLine.pvp?.toFixed(2)} {bookingData.currencyCode}
                                                    </span>
                                                    <small className="room-commission-detail">
                                                        {
                                                            HTMLReactParser(
                                                                t('pages.booking-detail.room-total-commission', [bookingLine.commission?.toFixed(2), bookingData.currencyCode])
                                                            )
                                                        }
                                                    </small>
                                                </Col>
                                            </Row>


                                        </div>
                                    )
                                })
                            }
                        </Col>
                    </Row>
                </div>
                {
                    bookingData?.extras && bookingData?.extras.length > 0 && <div className="services print-block" data-tag="extras">
                                                <Row>
                                                    <Col xs={24} className="content">
                                                        <h3 className='main-title'>
                                                            {t('pages.booking-detail.extra-services.title')}
                                                        </h3>

                                                        <div>
                                                            {
                                                                bookingData?.extras.map((extra: Extra, index: number) => {
                                                                    return (
                                                                        <div className="service">
                                                                            <Row gutter={0}>
                                                                                <Tooltip title={extra.description}>
                                                                                    <Col xs={24} lg={4} className="image" style={{ backgroundImage: `url(${extra.image})` }}>
                                                                                    </Col>
                                                                                </Tooltip>
                                                                                <Col xs={24} lg={15} className="description">
                                                                                    <h4>{index + 1}. {extra.description}</h4>
                                                                                    <p>{t('pages.booking-detail.extra-services.quantity')}: {extra.quantity} </p>
                                                                                    <p>{t('pages.booking-detail.extra-services.date')}: {moment(extra.applicationDateFrom).format(appConfig.config.dateFormat)} - {moment(extra.applicationDateTo).format(appConfig.config.dateFormat)}</p>
                                                                                </Col>
                                                                                <Col xs={24} lg={5} className="service-price">
                                                                                    <span>{extra.price > 0 ? `${extra.price.toFixed(2)} ${bookingData.currencyCode}` : t('pages.booking-detail.extra-services.free')} </span>
                                                                                </Col>
                                                                            </Row>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                }
            </div>
            }
        </div>
    )
}

export default BookingDetailPage;