import { useTranslation } from "react-i18next";
import { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Row, Select } from "antd";
import "./EditUserForm.scss";
import { editUser, isValidUsername } from "../../api/repositoryEP";
import { Dispatch, SetStateAction } from 'react';
import EditUserData from "../../models/EditUserData";
import { UserData } from "../../models/UserData";


interface EditUserFormProps {
    setDrawerVisible: Dispatch<SetStateAction<boolean>>;
    userId: string | undefined
    user : UserData  
    languagesAvailable : string[];

}

const EditUserForm = (props: EditUserFormProps): JSX.Element => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [usernameValue, setUsernameValue] = useState<string>("");
    const [isValidUsernameInput, setIsValidUsernameInput] = useState<boolean>(true);
    const [passwordValue, setPasswordValue] = useState<string>("");
    const [isEmptyUsername, setIsEmptyUsername] = useState<boolean>(false);
    const [loading,setLoading] = useState<boolean>(false);
    const [hasInvalidCharacters, setHasInvalidCharacters] = useState(false);

    useEffect(() => {
        if (props.user) {
            form.setFieldsValue({
                name: props.user.name,
                surname: props.user.surname,
                "second-surname": props.user.secondSurname,
                email: props.user.email,
                phone: props.user.phone,
                username: props.user.username,
                role: props.user.role,
                language: props.user.languaje.slice(-2).toLowerCase(),
            });
        }
    }, [props.user, form]);
    
    const handleInputBlur = async () => {
        setIsValidUsernameInput(true);
        setHasInvalidCharacters(false);

        const regex = /^[a-zA-Z0-9]+$/; // Solo letras y números, sin espacios ni caracteres especiales

        if (usernameValue === "") {
            setIsEmptyUsername(true);
        } else if (!regex.test(usernameValue)) {
            setHasInvalidCharacters(true);
        } else {
            setIsEmptyUsername(false);
            setHasInvalidCharacters(false);

            const response = await isValidUsername(usernameValue); // Tu función asíncrona
            if (response?.type === 1) {
                setIsValidUsernameInput(response.data);
            } else {
                setIsValidUsernameInput(false);
            }
        }
    };

    const closeDrawer = () => {
        form.resetFields();
        props.setDrawerVisible(false)
    }


    // CAMBIAR EL ONFINISHI
    const onFinish = (values: any) => {
        if (isValidUsernameInput) {
            // creamos el objeto con los datos del formulario y llamamos a la api
            setLoading(true)
            const userData: EditUserData = {
                id : props.user.id,
                name: values.name,
                surname: values.surname,
                lastSurname: values["second-surname"],
                email: values.email,
                phone: values.phone,
                username: values.username,
                password: values.password,
                rol: values.role,
                language: values.language,
            }

            editUser(userData)
                .then((response) => {
                    if (response?.type === 1) {
                        closeDrawer();
                    } 
                    setLoading(false)
                })              
        }
    };




    const handleChangeUsername = (e: any) => {
        setUsernameValue(e.target.value);
    };

    const handleBtnCancel = () => {
        closeDrawer();
    }

    return (
        <div className="account-users-filter">
            <Form form={form} onFinish={onFinish} layout="vertical">
                <Row gutter={16}>
                  
                        <Col xs={24} md={24}>
                            <h3>
                                {t("components.edit-users.general")}
                            </h3>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item
                                name="name"
                                initialValue={props.user.name}
                                rules={[{ required: true, message: t("components.edit-users.validations.name-required") ?? "Name is required" }]}
                            >
                                <Input className="app-input"
                                    placeholder={'' + t("components.edit-users.name")} />
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={24}>
                            <Form.Item
                                name="surname"
                                initialValue={props.user.surname}
                                rules={[{ required: true, message: t("components.edit-users.validations.surname-required") ?? "Surname is required" }]}
                            >
                                <Input className="app-input"
                                    placeholder={t("components.edit-users.surname") + ''} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item
                                name="second-surname"
                                initialValue={props.user?.secondSurname}
                            >
                                <Input className="app-input"
                                    placeholder={t("components.edit-users.second-surname") + ''} />
                            </Form.Item>
                        </Col>


                        <Col xs={24} md={24}>
                            <h3>
                                {t("components.edit-users.contact")}
                            </h3>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item
                                name="email"
                                initialValue={props.user.email}
                                rules={[{ required: true, message: t("components.edit-users.validations.email-required") + "" }]}

                            >
                                <Input className="app-input"
                                    placeholder={t("components.edit-users.email") + ""} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item
                                name="phone"
                                initialValue={props.user.phone}
                                rules={[
                                    {
                                        pattern: /^[0-9]*$/,
                                        message: t("components.edit-users.validations.phone-numeric") + "",
                                    },
                                ]}
                            >
                                <Input className="app-input"
                                    placeholder={t("components.edit-users.phone") + ""} />
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={24}>
                            <h3>
                                {t("components.edit-users.access")}
                            </h3>
                        </Col>


                        <Col xs={24} md={24}>
                            <Form.Item
                                name="password"
                            >
                                <Input.Password className="app-input"
                                    placeholder={t("components.edit-users.password") + ""}
                                    value={passwordValue} />
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={24}>
                            <Form.Item
                                name="repeat-password"
                                dependencies={['password']}

                                rules={[
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            const passwordFieldValue = getFieldValue('password');
                                            if (!passwordFieldValue && !value) {
                                                // Ambos campos están vacíos, no hay error
                                                return Promise.resolve();
                                            }
                                            if (!value && passwordFieldValue) {
                                                return Promise.reject(t("components.edit-users.validations.password-required"))
                                            }
                                            if (!value || passwordFieldValue === value) {
                                                // El campo de repetir contraseña está vacío o coincide con el campo de contraseña
                                                return Promise.resolve();
                                            }
                                            // Los campos no coinciden
                                            return Promise.reject(t("components.edit-users.validations.password-not-match"));
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password
                                    className="app-input"
                                    placeholder={t("components.edit-users.repeat-password") + ""}
                                />
                            </Form.Item>
                        </Col>

                       
                        <Col xs={24} md={24}>

                        <Form.Item
                            name="username"
                            initialValue={props.user.username}
                            rules={[
                                {
                                    required: true,
                                    message: t("components.new-users.validations.username-required") + "" // Traducción con t()
                                },
                            ]}
                            validateStatus={(!isValidUsernameInput || isEmptyUsername || hasInvalidCharacters) ? 'error' : 'success'}
                            help={isEmptyUsername
                                ? t("components.new-users.validations.username-required")
                                : hasInvalidCharacters
                                    ? t("components.new-users.validations.username-caracteres-not-valid") // Mensaje específico para caracteres no válidos
                                    : !isValidUsernameInput && t("components.new-users.validations.username-not-valid")} // Mensaje de formato no válido
                        >
                            <Input className="app-input"
                                placeholder={t("components.new-users.username") + ""} // Traducción con t()
                                value={usernameValue}
                                onChange={(e) => setUsernameValue(e.target.value)} // Actualiza el valor del estado
                                onBlur={handleInputBlur} // Valida el input al perder el foco
                            />
                        </Form.Item>
                        </Col>




                        <Col xs={24} md={24}>
                            <h3>
                                {t("components.edit-users.role")}
                            </h3>
                        </Col>


                        <Col xs={24} md={24}>
                            <Form.Item name="role" initialValue={props.user.role} rules={[{ required: true, message: t("components.edit-users.validations.rol-required") + "" }]} >
                                <Select className="app-input" placeholder={t("components.edit-users.select")}>
                                    <Select.Option value="super_admin">{t("roles.super_admin")}</Select.Option>
                                    <Select.Option value="bo_manager">{t("roles.bo_manager")}</Select.Option>
                                    <Select.Option value="bo_basic">{t("roles.bo_basic")}</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={24}>
                            <h3>
                                {t("components.edit-users.language")}
                            </h3>
                        </Col>


                        <Col xs={24} md={24}>
                        <Form.Item
                            name="language"
                            rules={[{ required: true, message: t("components.new-users.validations.language-required") + "" }]}
                        >
                            <Select className="app-input" placeholder={t("components.new-users.select")}>
                                {props.languagesAvailable.map((language) => (

                                    <Select.Option  value={language}>
                                        {t(`languages.${language}`)}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>


                        <Col xs={24} md={12}>
                            <Form.Item style={{ textAlign: 'center' }}>
                                <Button className="app-button" style={{ width: "95%" }} htmlType="submit" loading={loading} disabled={loading}>
                                    {t("components.edit-users.btn-save")}
                                </Button>
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={12}>
                            <Form.Item style={{ textAlign: 'center' }}>
                                <Button className="app-button" style={{ width: "95%" }} onClick={handleBtnCancel}>
                                    {t("components.edit-users.btn-cancel")}
                                </Button>
                            </Form.Item>
                        </Col>               
                </Row>

            </Form>
        </div>
    );
};

export default EditUserForm;
