
import { Button, Checkbox, Col, Dropdown, Form, Input, InputNumber, Row, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { CreateFieldRegisterFormDto, EditFieldRegisterFormDto, RegisterFormFieldsData } from '../../../../../models/RegisterFormData';

import { editFieldRegisterForm, createFieldRegisterForm } from '../../../../../api/repositoryEP';
import { notification } from 'antd';
import './FieldForm.scss'
import { LoadingOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';


interface FieldFormProps {
    drawerVisible: boolean;
    setDrawerVisible: (visible: boolean) => void;
    fieldData: RegisterFormFieldsData | null | undefined;
    languages: string[];
}

const FieldForm = (props: FieldFormProps): JSX.Element => {
    const { t } = useTranslation();
    const [fieldType, setFieldType] = useState<string>();
    const [selectOptions, setSelectOptions] = useState<string[]>([]);
    const [form] = Form.useForm();
    const drawerVisible = props.drawerVisible;
    const [searching, setSearching] = useState<boolean>(false);

    useEffect(() => {
        if (!drawerVisible) {
            form.resetFields();
        }
    }, [drawerVisible]);

    useEffect(() => {
        if (props.fieldData) {

            form.setFieldsValue({
                required: props.fieldData.required,
                searchable: props.fieldData.searchable,
                alone: props.fieldData.alone,
                isActive: props.fieldData.isActive ? "active" : "inactive",
            });

            // Set field type and log field data
            setFieldType(props.fieldData.fieldType);
        }
    }, [props.fieldData, form]);


    useEffect(() => {
        if (props.fieldData?.options != null && props.fieldData?.options != undefined && props.fieldData?.options != "") {
            const options = JSON.parse(props.fieldData.options ?? '[]');
            setSelectOptions(options);
        };

    }, [props.fieldData?.options]);


    const addOption = () => {
        setSelectOptions([...selectOptions, '']);
    };

    const removeOption = (index: number) => {
        setSelectOptions(selectOptions.filter((_, i) => i !== index));
    };

    const handleOptionChange = (index: number, value: string) => {
        const newOptions = [...selectOptions];
        newOptions[index] = value;
        setSelectOptions(newOptions);
    };




    const showErrorMessage = () => {
        notification.error({
            message: t('actions.error-message'),
            description: t('actions.error-description')
        });
    }
    const showSuccessNotification = () => {
        notification.success({
            message: t('actions.success-message'),
            description: t('actions.success-description')
        });
    }

    const onAddField = async (values: any) => {
        let ops = "";
        if (values.fieldType == 'select' || values.fieldType == 'radio') { // para los options
            ops = JSON.stringify(selectOptions);
        }
        const field: CreateFieldRegisterFormDto = {
            fieldType: values.fieldType,
            language: values.language,
            label: values.label,
            isActive: values.isActive == "active" ? true : false, // Asegúrate de que sea booleano
            order: values.order,
            searchable: values.searchable == undefined ? false : values.searchable,
            required: values.required,
            alone: values.alone, // Asegúrate de que sea booleano
            name: values.name,
            defaultValue: values.defaultValue == undefined ? "" : values.defaultValue,
            options: ops, // Convierte a JSON y evita null o undefined
            placeholder: values.placeholder == undefined ? "" : values.placeholder
        };

        setSearching(true);
        createFieldRegisterForm(field).then((response) => {
            if (response?.type === 1) {
                form.resetFields();
                setSelectOptions([]);
                props.setDrawerVisible(false)

            } else {
                form.resetFields();
                setSelectOptions([]);
            }
            setSearching(false)
        })

    }

    const onEditField = async (values: any) => {

        if (props.fieldData?.id == null || props.fieldData?.id == "") {
            showErrorMessage()
        } else {
            setSearching(true);
            let ops = "";
            if (values.fieldType == 'select' || values.fieldType == 'radio') { // para los options
                ops = JSON.stringify(selectOptions);
            }
            const field: EditFieldRegisterFormDto = {
                id: props.fieldData.id,
                fieldType: values.fieldType,
                language: values.language,
                label: values.label,
                isActive: values.isActive == "active" ? true : false,
                order: values.order,
                searchable: values.searchable == undefined ? false : values.searchable,
                required: values.required,
                alone: values.alone, // Asegúrate de que sea booleano
                name: values.name,
                defaultValue: values.defaultValue == undefined ? "" : values.defaultValue,
                options: ops,
                placeholder: values.placeholder == undefined ? "" : values.placeholder
            }
            editFieldRegisterForm(field).then((response) => {

                if (response?.type === 1) {
                    form.resetFields();
                    setSelectOptions([]);
                    props.setDrawerVisible(false)

                } else {
                    form.resetFields();
                    setSelectOptions([]);
                }
                setSearching(false)
            })
                ;
        }
    }

    const onFinish = (values: any) => {

        if (props.fieldData == null) {
            onAddField(values)
        } else {
            onEditField(values)
        }
    }


    return (
        <div className="field-form">
            <Form onFinish={onFinish} layout='vertical' form={form}>
                <Row>
                    <Col xs={24} md={24}>
                        <Form.Item
                            name="language"
                            label={t("components.tab-configuration.register-form.filter.language")}
                            initialValue={props.fieldData?.language ?? undefined}
                            rules={[{ required: true, message: t("components.tab-configuration.register-form.filter.language-required") ?? "Language is required" }]}
                        >
                            <Select className="app-input"
                                placeholder={t("components.tab-configuration.register-form.filter.select")}
                            >
                                {props.languages.map(language => (
                                    <Select.Option key={language} value={language}>
                                        {t(`languages.${language}`)}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={24}>
                        <Form.Item
                            name="fieldType"
                            label={t("components.tab-configuration.register-form.filter.field-type")}
                            initialValue={props.fieldData?.fieldType ?? undefined}  // Se usa undefined si no hay valor
                            rules={[{ required: true, message: t("components.tab-configuration.register-form.filter.field-type-required") ?? "Field type is required" }]}
                        >
                            <Select className="app-input"
                                value={fieldType}
                                onChange={(value) => setFieldType(value)}
                                disabled={props.fieldData?.fieldType != null && props.fieldData?.fieldType != undefined}
                                placeholder={t("components.tab-configuration.register-form.filter.select")}  // si estamos editando, no se puede modificar esto
                            >
                                <Select.Option value="input">{t("components.tab-configuration.register-form.filter.field-input")}</Select.Option>
                                <Select.Option value="text_area">{t("components.tab-configuration.register-form.filter.field-textarea")}</Select.Option>
                                <Select.Option value="select">{t("components.tab-configuration.register-form.filter.field-select")}</Select.Option>
                                <Select.Option value="checkbox">{t("components.tab-configuration.register-form.filter.field-checkbox")}</Select.Option>
                                <Select.Option value="radio">{t("components.tab-configuration.register-form.filter.field-radio")}</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    {(fieldType == 'input' || fieldType == 'text_area' || fieldType == 'select') && (

                        <Col xs={24} md={24}>
                            <Form.Item
                                name="placeholder"
                                label={t("components.tab-configuration.register-form.filter.placeholder")}
                                initialValue={props.fieldData?.placeholder ?? undefined}
                            >
                                <Input className="app-input"

                                />
                            </Form.Item>
                        </Col>

                    )}


                    <Col xs={24} md={24}>
                        <Form.Item
                            name="label"
                            label={t("components.tab-configuration.register-form.filter.label")}
                            initialValue={props.fieldData?.label ?? undefined}
                            rules={[{ required: true, message: t("components.tab-configuration.register-form.filter.label-required") ?? "Tag field is required" }]}
                        >
                            <Input className="app-input" />
                        </Form.Item>
                    </Col>



                    <Col xs={24} md={24}>
                        <Form.Item
                            name="name"
                            label={t("components.tab-configuration.register-form.filter.name-field")}
                            initialValue={props.fieldData?.nameField ?? undefined}
                            rules={[
                                { required: true, message: t("components.tab-configuration.register-form.filter.name-field-required") ?? "Name field is required" },
                                {
                                    pattern: /^[a-zA-Z0-9]*$/,
                                    message: t("components.tab-configuration.register-form.filter.invalid-character") ?? "Name field must not contain special characters",
                                },
                            ]}
                        >
                            <Input className="app-input" />
                        </Form.Item>

                    </Col>

                    {(fieldType == 'input' || fieldType == 'text_area' || fieldType == 'select') && (
                        <Col xs={24} md={24}>
                            <Form.Item
                                name="defaultValue"
                                label={t("components.tab-configuration.register-form.filter.default-value")}
                                initialValue={props.fieldData?.defaultValue ?? undefined}
                            >
                                <Input className="app-input"
                                />
                            </Form.Item>
                        </Col>
                    )}


                    {(fieldType === 'select' || fieldType === 'radio') && (

                        <Form.Item
                            name="options"
                            label={t("components.tab-configuration.register-form.filter.options") + fieldType}
                            rules={[
                                {
                                    validator: (_, value) =>
                                        selectOptions.length === 0 || selectOptions.every(option => option === '')
                                            ? Promise.reject((t("components.tab-configuration.register-form.filter.options-required") ?? "Options are required"))
                                            : Promise.resolve(),
                                },
                            ]}
                        >

                            <Col xs={24} md={24}>
                                <Button
                                    onClick={addOption}
                                    icon={<PlusOutlined />}
                                    style={{ marginBottom: 8 }}
                                >
                                    {t("components.tab-configuration.register-form.filter.button-add-option")}
                                </Button>
                            </Col>


                            {selectOptions.map((option, optIndex) => (
                                <div key={optIndex} style={{ display: 'flex', marginBottom: 8 }}>
                                    <Input
                                        value={option}
                                        onChange={(e) => handleOptionChange(optIndex, e.target.value)}
                                    />
                                    <Button
                                        onClick={() => removeOption(optIndex)}
                                        icon={<MinusCircleOutlined />}
                                        style={{ marginLeft: 8 }}
                                    />
                                </div>
                            ))}


                        </Form.Item>

                    )}



                    <Col xs={24} md={24}>
                        <Form.Item
                            name="isActive"
                            label={t("components.tab-configuration.register-form.filter.state")}
                            rules={[{ required: true, message: t("components.tab-configuration.register-form.filter.state-required") ?? "Select the state" }]}
                            initialValue={props.fieldData?.isActive ? "active" : "inactive"} // Utilizar initialValue
                        >
                            <Select
                                className="app-input"
                                placeholder={t("components.tab-configuration.register-form.filter.select")}
                            >
                                <Select.Option value="active">{t("components.tab-configuration.register-form.filter.state-active")}</Select.Option>
                                <Select.Option value="inactive">{t("components.tab-configuration.register-form.filter.state-inactive")}</Select.Option>
                            </Select>
                        </Form.Item>

                    </Col>

                    <Col xs={12} md={12}>
                        <Form.Item
                            name="order"
                            label={t("components.tab-configuration.register-form.filter.order")}
                            initialValue={props.fieldData?.order ?? undefined}
                            rules={[{ required: true, message: t("components.tab-configuration.register-form.filter.order-required") ?? "Select the order" }]}
                        >
                            <InputNumber
                                className="app-input"
                                min={1}
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={24}>
                        <Form.Item
                            name="required"
                            valuePropName="checked"  // Propiedad correcta para checkboxes
                        >
                            <Checkbox>
                                {t("components.tab-configuration.register-form.filter.required")}
                            </Checkbox>
                        </Form.Item>
                    </Col>

                    {fieldType == 'select' && (
                        <Col xs={24} md={24}>
                            <Form.Item
                                name="searchable"
                                valuePropName="checked"  // Propiedad correcta para checkboxes
                            >
                                <Checkbox>
                                    {t("components.tab-configuration.register-form.filter.searchable")}
                                </Checkbox>
                            </Form.Item>
                        </Col>

                    )}

                    <Col xs={24} md={24}>
                        <Form.Item
                            name="alone"
                            valuePropName="checked"  // Propiedad correcta para checkboxes
                        >
                            <Checkbox>
                                {t("components.tab-configuration.register-form.filter.alone")}
                            </Checkbox>
                        </Form.Item>
                    </Col>


                    <Col xs={24} md={24}>
                        <Button className="app-button full-width margin-button" htmlType='submit' disabled={searching}>
                            {searching ? <LoadingOutlined /> : ''}
                            {props.fieldData == null ? t("components.tab-configuration.register-form.filter.button-add") : t("components.tab-configuration.register-form.filter.button-edit")}
                        </Button>
                    </Col>
                </Row>
            </Form>



        </div>
    );
}

export default FieldForm;