

import { Card, Col, Drawer, Row, Tooltip, Typography } from 'antd';
import { GetAccountsIcon,  GetBookingsIcon, GetBusinessIcon, GetGroupIcon, GetUserIcon } from '../../../utils/icons';
import { useAppConfig } from '../../../Contexts/AppConfigContext';
import { useTranslation } from 'react-i18next';

import dingusImage from '../../../assets/images/channels/dingus.png';
import directImage from '../../../assets/images/channels/beds2b.png';

import moment from 'moment';

import './BookingListComponent.scss'
import BookingInfo from '../../../models/BookingInfo';
import { EyeOutlined } from '@ant-design/icons';
import BookingDetailPage from '../../../pages/Private/BookingDetailPage/BookingDetailPage';
import {  useState } from 'react';
import BookingData from '../../../models/BookingData';
import { LoadingData } from '../../common';
import HTMLReactParser from 'html-react-parser';

interface BookingsListComponentProps {
    list: BookingInfo[];
    loading: boolean;
    onGetBookingData: (bookingId: string) => void;
    bookingData?: BookingData,
    isGettingBookingData: boolean
}

const BookingsListComponent = (props: BookingsListComponentProps): JSX.Element => {
    const appConfig = useAppConfig();

    const { t } = useTranslation();

    const { list, loading, bookingData, onGetBookingData, isGettingBookingData } = props;

    const getAccountTypeTag = (type: any) => {
        switch (type) {
            case  0: // agency
                return t("components.accounts.filter.agency");
            case 1: // business
                return t("components.accounts.filter.business");
            case 2 : // independent
                return t("components.accounts.filter.independent");
            case  3 : // events
                return t("components.accounts.filter.events");
            default:
                return t("components.accounts.filter.agency");
        }
    }

    const GetStatusName = (statusName: string): string => {
        let response: string = '';
        switch (statusName) {
            case '0': // pending
                response = t("components.bookings.list.booking-status.pending");
                break;
            case '1': // pre
                response = t("components.bookings.list.booking-status.pre");
                break;
            case '2': // active
                response = t("components.bookings.list.booking-status.active");
                break;
            case '3' : // cancelled
                response = t("components.bookings.list.booking-status.cancelled");
                break;
            case '4': // error
                response = t("components.bookings.list.booking-status.error");
            
                break;
        }

        return response;
    }

    const GetIntegrationIcon = (integrationCode: string): JSX.Element => {

        switch (integrationCode) {
            case 'Dingus':
                //Get image from asset folder
                return  <Tooltip title={integrationCode} placement='right'>
                            <img src={dingusImage} alt="Dingus" className="app-image" title={integrationCode}/>
                        </Tooltip>
            case 'Direct':
                return  <Tooltip title={integrationCode} placement='right'>
                            <img src={directImage} alt="Beds2B PRO - Direct" className="app-image" title={integrationCode}/>
                        </Tooltip>
        }

        return <></>

    }

    const [selected, setSelected] = useState<BookingInfo>();
    const showBookingDetail = (item: BookingInfo) => {
        setSelected(item)
        onGetBookingData(item.bookingId)
    }



    return (
        <div className="bookings-list">
            <Drawer width={"60%"} open={selected !== undefined && bookingData !== undefined} onClose={() => { setSelected(undefined) }} title={t('pages.booking-detail.main-title')}>
                <BookingDetailPage bookingData={bookingData} isGettingBookingData={isGettingBookingData}/>
            </Drawer>

            {
                loading && <LoadingData />
            }

            {
                !loading && list.map((item: BookingInfo, index: number) => {
                    var cardHeader: JSX.Element = <div key={index} className="header-container">
                                                    <div className="booking-resume-header">
                                                        <span>
                                                            {item.hotelName}
                                                        </span>
                                                        <Typography.Text copyable>
                                                            {item.locator}
                                                        </Typography.Text>

                                                        <Typography.Text copyable>
                                                            {item.ownLocator}
                                                        </Typography.Text>
                                                    </div>
                                                    <div className="actions-container">
                                                        <div className="status-container">
                                                            <Tooltip title={item.statusName}>
                                                                <div className={`status status-${item.statusId}`}>
                                                                    {GetStatusName(item.statusId)}
                                                                </div>
                                                            </Tooltip>
                                                        </div>
                                                        <div className="show-booking-detail" onClick={() => { showBookingDetail(item) }}>
                                                            <EyeOutlined className="width-24px" />
                                                        </div>
                                                    </div>
                                                </div>

                    return (
                        <Card className={`app-card card-header-${item.statusId}`} title={cardHeader} bordered={false}>
                            <Row>
                                <Col className="booking-info" xs={24} md={8}>
                                    <div>
                                        <span>
                                            {GetBookingsIcon('app-icon width-14px')} <strong>{t("components.bookings.list.dates")}:</strong> {moment(item.checkIn).format(appConfig.config.dateFormat)} / {moment(item.checkOut).format(appConfig.config.dateFormat)}
                                            <small>({t("components.bookings.list.created-at", [moment(item.creationDate).format(appConfig.config.dateFormat)])})</small>
                                            {
                                                item.statusId === '3' && (
                                                    <small>({HTMLReactParser(t("components.bookings.list.cancel-at", [moment(item.cancelledDate).format(appConfig.config.dateFormat)]))})</small>
                                                )
                                            }
                                        </span>
                                    </div>
                                    <div>
                                        <span>
                                            {GetUserIcon('app-icon width-14px')} <strong>{t("components.bookings.list.owner")}:</strong> {item.ownerName}
                                            <small>
                                                <a href={`mailto:${item.ownerName}`}>{item.ownerEmail}</a> / {item.ownerPhone}
                                            </small>
                                        </span>
                                        <span>{GetBusinessIcon('app-icon width-14px')} <strong>{t("components.bookings.list.rooms")}:</strong> {item.roomsNumber}</span>
                                        <span>{GetGroupIcon('app-icon width-14px')} <strong>{t("components.bookings.list.pax")}:</strong> {item.adultsNumber} {t("components.bookings.list.adults")}, {item.childrenNumber} {t("components.bookings.list.children")}, {item.babiesNumber} {t("components.bookings.list.babies")}. </span>
                                    </div>
                                </Col>
                                <Col className="other-info" xs={24} md={8}>
                                    <div className="agency-info">
                                        <span>
                                            <strong>{GetAccountsIcon('app-icon width-14px')} {t("components.bookings.list.account")}:</strong> {item.accountInfo.bussinessName}
                                            
                                            <small>
                                                <strong>{t("components.bookings.list.account-type")}:</strong>  {getAccountTypeTag(item.accountInfo.accountType)}
                                            </small>
                                        </span>
                                    </div>

                                </Col>
                                <Col className="other-info" xs={24} md={8}>
                                    <div className="agency-info">
                                        <span>
                                            {GetAccountsIcon('app-icon width-14px')} <strong>{t("components.bookings.list.account-admin")}</strong> {item.superAdminName}
                                            <small>
                                                <a href={`mailto:${item.superAdminEmail}`}>
                                                    {item.superAdminEmail}
                                                </a> / {item.superAdminPhone} 
                                            </small>
                                        </span>
                                        <span>
                                            <strong>{GetUserIcon('app-icon width-14px')} {t("components.bookings.list.user")}:</strong> {item.creationUser}
                                            <small>
                                                <a href={`mailto:${item.creationUserEmail}`}>
                                                    {item.creationUserEmail}
                                                </a>
                                            </small>
                                        </span>
                                    </div>

                                </Col>
                                <Col className="booking-status-and-pricing" xs={24} md={24}>
                                    <div className="pricing-info">
                                        <span className="integration-icon">
                                            <label>{GetIntegrationIcon(item.integrationCode)}</label>
                                            <small>{t("components.bookings.list.channel")}</small>
                                        </span>
                                        <span className="price">
                                            <label>{item.pvp} {item.currencySymbol}</label>
                                            <small>{t("components.bookings.list.price")}</small>
                                        </span>
                                        <span className="price">
                                            <label>{item.commission} {item.currencySymbol}</label>
                                            <small>{t("components.bookings.list.commission")}</small>
                                        </span>
                                        <span className="price">
                                            <label>{item.reward} {item.currencySymbol}</label>
                                            <small>{t("components.bookings.list.rewards")}</small>
                                        </span>
                                    </div>
                                </Col>
                            </Row>
                        </Card>
                    )
                })
            }
        </div>
    )
}

export default BookingsListComponent;