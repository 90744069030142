import { createRegimesConfig, editRegimesConfig, fetchLanguagesAvailables } from "../../../../../../../api/repositoryEP";
import { RegimesConfigCreateData, RegimesConfigData, TranslationData } from "../../../../../../../models/MappingData";
import { useEffect, useState } from "react";
import { Form, Row, Col, Button, Input, Checkbox, TabsProps, Divider, Tabs } from "antd";
import { useTranslation } from "react-i18next";
import { LoadingOutlined } from "@ant-design/icons";
import './RegimesForm.scss';
import { LoadingData } from "../../../../../../common";

interface RegimesFormProps {
    drawerVisible: boolean;
    setDrawerVisible: (visible: boolean) => void;
    regimesData: RegimesConfigData | null;
    setRegimesData: (regimesData: RegimesConfigData | null) => void;
}

const RegimesForm = (props: RegimesFormProps): JSX.Element => {
    const [regimesName, setRegimesName] = useState<Array<TranslationData>>([]);
    const [loading, setLoading] = useState<boolean>(false); 
    const [loadingLanguages, setLoadingLanguages] = useState<boolean>(false); 
    const { t } = useTranslation();
    const [form] = Form.useForm();

    useEffect(() => {
        if (props.regimesData) {
            form.setFieldsValue({
                code: props.regimesData.code,
                integrationCode: props.regimesData.integrationCode,
                internalNote: props.regimesData.internNote,
                sync: props.regimesData.syncReward,
            });
        }
    }, [props.regimesData]);


    const closeDrawer = () => {
        props.setRegimesData(null);
        form.resetFields();
        props.setDrawerVisible(false);
    };

    const modifyRegimesName = (index: number, value: string) => {
        const newRegimesName = [...regimesName];
        newRegimesName[index].value = value;
        setRegimesName(newRegimesName);
    };

    const handleBtnCancel = () => {
        closeDrawer();
    };

    useEffect(() => {
        if (props.drawerVisible) {
            if (props.regimesData) {
                setRegimesName(props.regimesData.name);
            } else {
                setLoading(true); // Inicia la carga
                setLoadingLanguages(true)               
                fetchLanguagesAvailables("wa").then((r) => {
                    if (r?.type === 1) {
                        const languages = r.data.map((lang: string) => ({
                            language: lang,
                            value: ''
                        }));
                        setRegimesName(languages);
                      
                    } else {
                        props.setDrawerVisible(false);
                    }

                    setLoading(false); // Finaliza la carga
                    setLoadingLanguages(false);
                });
            }
        }
    }, [props.drawerVisible]);

    const onFinish = (values: any) => {
        setLoading(true); // Inicia la carga
        if (props.regimesData === null) { // CREATE
            const regimesData: RegimesConfigCreateData = {
                name: regimesName,
                code: values.code,
                integrationCode: values.integrationCode,
                internNote: values.internalNote,
                syncReward: values.sync
            };

            createRegimesConfig(regimesData).then((response) => {
                if (response?.type === 1) {
                    closeDrawer();
                } 
                setLoading(false); // Finaliza la carga
            });

        } else { // EDIT
            const regimesData: RegimesConfigData = {
                id: props.regimesData?.id,
                name: regimesName,
                code: values.code,
                integrationCode: values.integrationCode,
                internNote: values.internalNote,
                syncReward: values.sync
            };

            editRegimesConfig(regimesData).then((response) => {
                if (response?.type === 1) {
                    closeDrawer();
                } 
                setLoading(false); // Finaliza la carga
            });
        }
    };

    const mappingItems: TabsProps['items'] = [
        {
            key: '1',
            label: `${t("components.tab-mapping.title-pms")}`,
            children: <Row gutter={16}>
                        <Col xs={24}>
                            <Form.Item
                                name="code"
                                label={t("components.tab-mapping.regimes-config.drawer.code-in-pms")}
                                rules={[{ required: true, message: t("components.tab-mapping.hotels-config.drawer.code-required") ?? "Code is required" }]}
                                initialValue={props.regimesData?.code}
                            >
                                <Input className="app-input" />
                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                            <small>
                                {t("components.tab-mapping.pms-codes-explanation")}
                            </small>
                        </Col>
                    </Row>,
        },
        {
            key: '2',
            label:  `${t("components.tab-mapping.title-integrations")}`,
            children: <Row gutter={16}>
                        <Col xs={24}>
                            <Form.Item
                                name="integrationCode"
                                label={t("components.tab-mapping.regimes-config.drawer.code-in-integrations")}
                                rules={[{ required: true, message: t("components.tab-mapping.hotels-config.drawer.code-required") ?? "Code is required" }]}
                                initialValue={props.regimesData?.integrationCode}
                            >
                                <Input className="app-input" />
                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                            <small>
                                {t("components.tab-mapping.integration-codes-explanation")}
                            </small>
                        </Col>
                    </Row>,
        }
    ];

    return (
        <div>
              {loadingLanguages ? (
                <div style={{ textAlign: 'center', padding: '20px' }}>
                    <LoadingData />
                </div>
            ) : (
            <Form
                form={form}
                onFinish={onFinish}
                className='regimes-form-component'
                layout="vertical"
            >
                <Row gutter={16}>
                    <Col xs={24} md={24}>
                        <Form.Item
                            label={t("components.tab-mapping.regimes-config.drawer.name")}
                            required
                            rules={[
                                {
                                    validator: (_, value) =>
                                        regimesName.length === 0 || regimesName.some(option => option.value === '')
                                            ? Promise.reject(t("components.tab-mapping.regimes-config.drawer.name-required") ?? "Names are required")
                                            : Promise.resolve(),
                                },
                            ]}
                        >
                            {regimesName.map((item, optIndex) => (
                                <div key={optIndex} style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}>
                                    <span>{item.language.toUpperCase().substring(item.language.length - 2)}</span>
                                    <Input
                                        value={item.value}
                                        onChange={(e) => modifyRegimesName(optIndex, e.target.value)}
                                        style={{ marginLeft: 8 }}
                                    />
                                </div>
                            ))}
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={24}>
                        <Form.Item
                            name="internalNote"
                            label={t("components.tab-mapping.regimes-config.drawer.internal-note")}
                            initialValue={props.regimesData?.internNote}
                        >
                            <Input className="app-input" />
                        </Form.Item>
                    </Col>

                    <Divider>
                        {t("components.tab-mapping.title-mapping-code")}
                    </Divider>
                    <Col xs={24} md={24}>
                        <Tabs defaultActiveKey="1" items={mappingItems} />
                    </Col>
                    
                    
                    <Col xs={24} md={24} style={{ marginTop: 40 }}>
                        
                    </Col>

                    <Col xs={24} md={12}>
                        <Form.Item style={{ textAlign: 'center' }}>
                            <Button className="app-button" style={{ width: "70%" }} onClick={handleBtnCancel}>
                                {t("components.button.cancel")}
                            </Button>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item style={{ textAlign: 'center' }}>
                            <Button className="app-button" style={{ width: "70%" }} htmlType="submit" disabled={loading}>
                                {loading ? <LoadingOutlined /> : t("components.button.save")}
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>)}
        </div>
    );
};

export default RegimesForm;
