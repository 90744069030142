import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './en-US.json';
import es from './es-ES.json';
import fr from './fr-FR.json';

export const DEFAULT_LANGUAGE = 'es';
export const AVAILABLE_LANGUAGES: string[] = ['es','en', 'fr'];
i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: en },
      es: { translation: es },
      fr: { translation: fr },
    },
    lng: DEFAULT_LANGUAGE,
    fallbackLng: DEFAULT_LANGUAGE,
    debug: false,
    interpolation: {
      escapeValue: false,
    }
  });

export default i18n;