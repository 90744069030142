import { useTranslation } from "react-i18next";
import { Select } from "antd";
import { availableAppLanguages } from "../../../../Contexts/AppConfigContext";
import { GetDefaultLanguage } from "../../../../utils/urls";
import { Row, Col } from "antd";
import { useEffect, useState } from "react";
import { fetchLandingPageCode, editLandingPageCode } from "../../../../api/repositoryEP";
import { notification } from 'antd';
import { Button } from "antd";
import CodeEditor from "../../../codeEditor/CodeEditor";
import { HTMLBlock, LandingPageCodeData, LandingPageCodeResponse } from "../../../../models/ConfigurationData";
import './LandingPage.scss';
import { LoadingOutlined } from "@ant-design/icons";
import { LoadingData } from "../../../common";

interface LandingPageProps {
    languages: string[];
}

const LandingPage = (props: LandingPageProps): JSX.Element => {
    const { t } = useTranslation();
    const languages = props.languages;
    const [language, setLanguage] = useState(GetDefaultLanguage());
    const [cssCode, setCssCode] = useState("");
    const [idLandingPage, setIdLandingPage] = useState("");
    const [htmlCode, setHtmlCode] = useState<Array<HTMLBlock>>([]);
    const [jsCode, setJsCode] = useState("");
    const [loading, setLoading] = useState<boolean>(false)
    const [loadingButton, setLoadingButton] = useState<boolean>(false)



    const handleLanguage = (language: any) => {
        setLanguage(language);
    }

    useEffect(() => {
        if (language) {
            setLoading(true)
            fetchLandingPageCode(language).then((r) => {
                const code: LandingPageCodeResponse = r?.data;
                if (r?.type === 1) {
                    setIdLandingPage(code.id);
                    setCssCode(code.css);
                    setHtmlCode(code.appThemeHtmlBlock);
                    setJsCode(code.javascript);
                }
                setLoading(false)
            })

        }
    }, [language])

    const handleCode = () => {
        const data: LandingPageCodeData = {
            id: idLandingPage,
            language: language,
            cssCode: cssCode,
            htmlCode: htmlCode,
            jsCode: jsCode,
        };
        setLoadingButton(true)
        editLandingPageCode(data).then((r) => {
            if (r?.type === 1) {
                const scrollTopElement = document.getElementById("content-page")!;
                scrollTopElement.scrollTop = 0;
            } 
                setLoadingButton(false)
        })
    }

    return (
        <div className="landing-page-component">
            {loading ? (<LoadingData />) : (<>
                <Row gutter={4} className='flex-column'>
                    <Col xs={24} sm={24} md={8} lg={6} xl={4} >
                        <Select className="select-large" defaultValue={language} onChange={handleLanguage}>
                            {languages.map(language => (
                                <Select.Option key={language} value={language}>
                                    {t(`languages.${language}`)}
                                </Select.Option>
                            ))}
                        </Select>
                    </Col>

                    <Col xs={24} md={24}>
                        <CodeEditor
                            htmlCode={htmlCode}
                            cssCode={cssCode}
                            jsCode={jsCode}
                            setHtmlCode={setHtmlCode}
                            setCssCode={setCssCode}
                            setJsCode={setJsCode}
                        />
                    </Col>


                    <Col xs={24} md={24} className='button-container'>
                        <Button className="app-button button-large btn-save" onClick={handleCode} disabled = {loadingButton}>
                        {loadingButton ? <LoadingOutlined /> : t("components.button.save")}
                        </Button>
                    </Col>
                </Row>
            </>)}
        </div>

    );
};



export default LandingPage;