import EndPointConfig from '../../models/api/EndPointConfig'

const controller = 'Regimes'

export const RegimesEP : EndPointConfig[] =[
    new EndPointConfig()
    .setMethod("GET")
    .setController(controller)
    .setActionName("GetRegimenConfigMapping"),
    new EndPointConfig()
    .setMethod("GET")
    .setController(controller)
    .setActionName("GetHotelRegimesConfigMapping"),   
    new EndPointConfig()
    .setMethod("POST")
    .setController(controller)
    .setActionName("CreateRegimeConfigMapping"),  
    new EndPointConfig()
    .setMethod("POST")
    .setController(controller)
    .setActionName("EditRegimeConfigMapping"),   
    new EndPointConfig()
    .setMethod("DELETE")
    .setController(controller)
    .setActionName("DeleteRegimenConfigMapping"),  

]