import React, { useEffect, useState } from 'react';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/mode-css';
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/theme-github';
import { Row, Col, Card, Button } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import './CodeEditor.scss';
import { useTranslation } from 'react-i18next';
import { HTMLBlock } from '../../models/ConfigurationData';
import { html as beautifyHtml, css as beautifyCss, js as beautifyJs } from 'js-beautify';

interface CodeEditorProps {
    htmlCode: Array<HTMLBlock>;
    cssCode: string;
    jsCode: string;
    setHtmlCode: (htmlCode: Array<HTMLBlock>) => void;
    setCssCode: (cssCode: string) => void;
    setJsCode: (jsCode: string) => void;
}

const CodeEditor = (props: CodeEditorProps) => {
    const { t } = useTranslation();
    const [renderedContent, setRenderedContent] = useState<Array<string>>([]);
    const [isHtmlExpanded, setIsHtmlExpanded] = useState(false);
    const [isCssExpanded, setIsCssExpanded] = useState(false);
    const [isJsExpanded, setIsJsExpanded] = useState(false);

    const renderCode = () => {
        const scriptBlob = new Blob([props.jsCode], { type: 'application/javascript' });
        const scriptUrl = URL.createObjectURL(scriptBlob);

        // Combinar todos los bloques HTML en un solo documento
        const htmlContent = props.htmlCode
            .sort((a, b) => a.order - b.order)
            .map(block => block.html) // Solo agregamos el contenido de los bloques
            .join(''); // Unimos sin espacio adicional

        const finalHtml = `
            <html>
                <head>
                    <style>${props.cssCode}</style>
                </head>
                <body>
                    ${htmlContent}
                    <script src="${scriptUrl}"></script>
                </body>
            </html>
        `;

        setRenderedContent([finalHtml]); // Solo un contenido combinado
    };


    useEffect(() => {
        // Render code automatically if there is already content in the htmlCode, cssCode or jsCode
        if (props.htmlCode.length > 0 || props.cssCode || props.jsCode) {
            renderCode();
        }
    }, [props.htmlCode, props.cssCode, props.jsCode]); // Dependencias: solo se ejecuta si hay cambios en estos valores

    const toggleHtmlExpand = () => {
        setIsHtmlExpanded(!isHtmlExpanded);
    };

    const toggleCssExpand = () => {
        setIsCssExpanded(!isCssExpanded);
    };

    const toggleJsExpand = () => {
        setIsJsExpanded(!isJsExpanded);
    };



    const addHtmlBlock = () => {
        const newHtmlCode = [
            ...props.htmlCode,
            { id: "", order: props.htmlCode.length, html: '<!-- New HTML Block -->' }
        ];
        props.setHtmlCode(newHtmlCode);
    };

    const removeHtmlBlock = (index: number) => {
        const newHtmlCode = props.htmlCode.filter((_, i) => i !== index);
        props.setHtmlCode(newHtmlCode);
    };

    return (
        <Row gutter={16} className='code-editor'>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <div className='container'>
                    <h2 className="app-title no-bordered medium">HTML</h2>
                    {isHtmlExpanded ? <UpOutlined onClick={toggleHtmlExpand} /> :
                        <DownOutlined onClick={toggleHtmlExpand} />}
                </div>
                <div className='editor-wrapper'>
                    {props.htmlCode
                        .sort((a, b) => a.order - b.order)
                        .map((block, index) => (
                            <div key={index} className='html-block'>
                                <AceEditor
                                    mode="html"
                                    theme="github"
                                    value={block.html} // No aplicar beautify en cada render
                                    setOptions={{ useWorker: false, behavioursEnabled: false }} // Deshabilitar auto-pairing
                                    onChange={(value) => {
                                        try {
                                            const newHtmlCode = [...props.htmlCode];
                                            newHtmlCode[index].html = value;
                                            props.setHtmlCode(newHtmlCode);
                                        } catch (error) {
                                            console.error("Error al actualizar el bloque HTML:", error);

                                        }
                                    }}
                                    onBlur={() => {
                                        // Formatear el código cuando el usuario termine de escribir
                                        const beautifiedHtml = beautifyHtml(block.html);
                                        const newHtmlCode = [...props.htmlCode];
                                        newHtmlCode[index].html = beautifiedHtml;
                                        props.setHtmlCode(newHtmlCode);
                                    }}
                                    name={`html_editor_${index}`}
                                    editorProps={{ $blockScrolling: true }}
                                    width="100%"
                                    height={isHtmlExpanded ? '300px' : '100px'}
                                />

                                <div className='button-group'>
                                    {props.htmlCode.length > 1 && (
                                        <Button className="app-button delete-button" onClick={() => removeHtmlBlock(index)}>
                                            {t("components.button.delete")}
                                        </Button>
                                    )}
                                    {(index === props.htmlCode.length - 1 || props.htmlCode.length === 1) && (
                                        <Button className=" app-button add-button" onClick={addHtmlBlock}>
                                            {t("components.button.add")}
                                        </Button>
                                    )}
                                </div>
                            </div>
                        ))}
                </div>
                <div className='code-editor'>
                    <div className='container'>
                        <h2 className="app-title no-bordered medium">CSS</h2>
                        {isCssExpanded ? <UpOutlined onClick={toggleCssExpand} /> :
                            <DownOutlined onClick={toggleCssExpand} />}
                    </div>
                    <div className='editor-wrapper'>
                        <AceEditor
                            mode="css"
                            theme="github"
                            value={props.cssCode}
                            setOptions={{ useWorker: false, behavioursEnabled: false }}
                            onChange={(value) => {
                                props.setCssCode(value); // Asegúrate de que esto actualiza correctamente el estado
                            }}
                            name="css_editor"
                            editorProps={{ $blockScrolling: true }}
                            width="100%"
                            height={isCssExpanded ? '300px' : '100px'}
                        />

                    </div>
                </div>
                <div className='code-editor'>
                    <div className='container'>
                        <h2 className="app-title no-bordered medium">JavaScript</h2>
                        {isJsExpanded ? <UpOutlined onClick={toggleJsExpand} /> :
                            <DownOutlined onClick={toggleJsExpand} />}
                    </div>
                    <div className='editor-wrapper'>
                        <AceEditor
                            mode="javascript"
                            theme="github"
                            value={props.jsCode}
                            setOptions={{ useWorker: false, behavioursEnabled: false }}
                            onChange={(value) => {
                                props.setJsCode(value); // Asegúrate de que esto actualiza correctamente el estado
                            }}
                            name="js_editor"
                            editorProps={{ $blockScrolling: true }}
                            width="100%"
                            height={isJsExpanded ? '300px' : '100px'}
                        />

                    </div>
                </div>
                <div className='button-container'>
                    <Button className="app-button render-button" onClick={renderCode}>
                        {t("components.button.render")}
                    </Button>
                </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <Card title="Preview" className='card-style'>
                    {renderedContent.map((content, index) => (
                        <iframe
                            key={index}
                            className='preview-iframe'
                            title={`Preview ${index}`}
                            srcDoc={content}
                        />
                    ))}
                </Card>
            </Col>
        </Row>
    );
};

export default CodeEditor;
