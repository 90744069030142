import EndPointConfig from '../../models/api/EndPointConfig'

const controller = 'Hotels'

export const HotelsEP : EndPointConfig[] =[
    new EndPointConfig()
    .setMethod("GET")
    .setController(controller)
    .setActionName("GetHotels"),      
    new EndPointConfig()
    .setMethod("GET")
    .setController(controller)
    .setActionName("GetHotelConfigMapping"), 
    new EndPointConfig()
    .setMethod("POST")
    .setController(controller)
    .setActionName("CreateHotelConfigMapping"),   
    new EndPointConfig()
    .setMethod("POST")
    .setController(controller)
    .setActionName("EditHotelConfigMapping"),   
    new EndPointConfig()
    .setMethod("POST")
    .setController(controller)
    .setActionName("UpdateStateHotelConfigMapping"),
    new EndPointConfig()
    .setMethod("DELETE")
    .setController(controller)
    .setActionName("DeleteHotelConfigMapping"),
]